import React from 'react'

export const ViewImageModal = ({viewImageModal,setViewImageModal, image=""}) => {
    return (
        <div className={viewImageModal ? "modal fade show" : "modal fade"} id="modal-xl" style={viewImageModal ? {display:'block', textAlign:'center'} : {display:'none'}}>
            <div className="modal-dialog modal-xl modal-dialog-scrollable " role="document">
            <div className="modal-content">
                <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setViewImageModal(false)}}>
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="modal-body">
                    <div>
                        <img alt="matang" className="img-responsive" style={{ width:'100%', height: 'auto', maxWidth:'400px' }} src={image}/>
                    </div>
                </div>
                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" onClick={()=>{setViewImageModal(false)}}>Close</button>
                </div>
            </div>
            </div>
        </div>
    )
}
