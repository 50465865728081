import React, {useEffect,useState} from 'react'
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";

var data = [
	{
	  country: "Lithuania",
	  litres: 501.9,
	  bottles: 1500
	},
	{
	  country: "Czech Republic",
	  litres: 301.9,
	  bottles: 990
	},
];
const colorsArray= [
    "#F53EE8",
    "#573FD4",
    "#52BDEB",
    "#3FD47C",
	'#B6F74A',
	'#22F533',
	'#EB9531',
	'#8828F7'
];
export const MonthlyPurchase = ({purchaseData}) => {
    
	useEffect(() => {

        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        let root = am5.Root.new("chartdiv-purhcase");
        
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
          am5themes_Animated.new(root)
        ]);
        
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        var chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                startAngle: 160, endAngle: 380
            })
        );
        
        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        // var series0 = chart.series.push(
        //     am5percent.PieSeries.new(root, {
        //         valueField: "total_quantity",
        //         categoryField: "item__category__name",
        //         startAngle: 160,
        //         endAngle: 380,
        //         radius: am5.percent(70),
        //         innerRadius: am5.percent(65),
        //     })
        // );
        
        var colorSet = am5.ColorSet.new(root, {
            colors: colorsArray,
        });
        
        // series0.set("colors", colorSet);
        
        // series0.ticks.template.set("forceHidden", true)
        // series0.labels.template.set("forceHidden", true)
        // series0.slices.template.setAll({
        //     tooltipText: "{item__category__name}: {valuePercentTotal.formatNumber('0.00')}% ({total_quantity} {item__category__unit_measure__unit})"
        // })
        
        var series1 = chart.series.push(
            am5percent.PieSeries.new(root, {
                startAngle: 160,
                endAngle: 380,
                valueField: "total_purchase",
                innerRadius: am5.percent(80),
                categoryField: "item__category__name"
            })
        );
        
        series1.ticks.template.set("forceHidden", true)
        series1.labels.template.set("forceHidden", true)
        series1.slices.template.setAll({
            tooltipText: "{item__category__name}: {valuePercentTotal.formatNumber('0.00')}% ({total_purchase} {item__category__unit_measure__unit})"
        })
        series1.set("colors", colorSet);
        
        
        let total_quantity=0
        let total_purchase=0
        for (let i in purchaseData) {
            total_quantity += purchaseData[i]['total_quantity']
            total_purchase += purchaseData[i]['total_purchase']
        }
        var label = chart.seriesContainer.children.push(
            am5.Label.new(root, {
                textAlign: "center",
                text: `Total Purchase: [bold]RM ${total_purchase}[/]`,
                fontSize: 12,
                centerY: am5.percent(50),
                centerX: am5.percent(50),
                maxWidth: 20
            })
        );
        
        // series0.data.setAll(purchaseData)
        series1.data.setAll(purchaseData)
        
    return () => {
        root.dispose()
    }
}, [purchaseData])

    return (
        <div id="chartdiv-purhcase" style={{ width: "100%", minHeight: "400px" }}></div>
    )
}
