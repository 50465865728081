import React, {useEffect, useState} from 'react'

export default function TopNav() {
    let userData = localStorage.getItem('user:data')
    const [user, setUser] = useState({
        username: '',
        company: ''
    })

    useEffect(() => {
        console.log(JSON.parse(userData))
        if (JSON.parse(userData)) {
            setUser({
                username: JSON.parse(userData).username,
                company: JSON.parse(userData).company
            })
        }
    }, [userData])

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <a href="#" className="nav-link">{user.company}</a>
                </li>
            </ul>

        </nav>
    )
}
