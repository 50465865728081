import React, {useState, useEffect} from 'react'
import  axiosInstance  from '../../../utils/api'
import { successToast, errorToast } from '../../../utils/toast';

export default function AddImageModal({data , setLoading}) {
    const [images, setImages] = useState([]) 
    const [submitting, setSubmitting] = useState(false) 


    function displayImage(e) {
        if (e.target.files.length < 1) {
            return null
        }

        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                setImages(prev => [...prev, {
                    image_blob: URL.createObjectURL(e.target.files[i]),
                    image_file: e.target.files[i]
                }]);
            }
        }
    }

    const submitImage = () => {

        let bodyFormData = new FormData();
        bodyFormData.append('farm', JSON.stringify({
            "farmid": data.farm.farmid
        }));

        let counter = 1;
        for (let i in images){
            let file = new File([ images[i].image_file ],  new Date().toISOString() + "_" + counter, {type: "image/jpg", lastModified: Date.now()});

            if (counter < 10) {
                bodyFormData.append('0'+counter, file);
            } else {
                bodyFormData.append(counter, file);
            }
            counter ++
        }
        setLoading(true)
        setSubmitting(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/core/uploadImage/`, bodyFormData)
        .then(res=>{
            setLoading(false)
            setSubmitting(false)
            successToast(res.data.message)

            return window.location.reload()
        }).catch(res=>{
            setLoading(false)
            setSubmitting(false)
            errorToast('Server Error')

            return window.location.reload()
        })

    }

    return (
        <div className="modal fade" id="add-image-modal">
            <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Image Preview</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                
                <div className="modal-body">
                    <div className="row">
                        {images.length > 0 ? (
                            images.map((image) => (
                                <div className="col-md-5 col-sm-1 text-center bg-black m-2" >
                                    <div style={{position:'absolute'}}>
                                        <button onClick={()=>{
                                            setImages( prev => 
                                                prev.filter( img => {
                                                    return img.image_blob != image.image_blob
                                                })
                                            )
                                        }}>x</button>
                                    </div>

                                    <a href={image.image_blob} data-toggle="lightbox" data-title="Image" data-farm="farm" >
                                        <img src={image.image_blob} className="img-fluid" style={{height:'200px'}} alt="Farmsens-Image"/>
                                    </a>
                                </div>
                            ))
                        ) : (
                            <div>
                                <p>No Image Available</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="modal-footer justify-content-between">
                        <button className="btn btn-default m-2">
                            <label htmlFor="img" style={{margin:0}}>
                                Add Image
                                <input
                                    accept="image/*"
                                    id="img"
                                    multiple
                                    name="img"
                                    type="file"
                                    capture
                                    onChange={displayImage}
                                    hidden
                                />
                            </label>
                        </button>
                    <button type="button" className="btn btn-primary" onClick={submitImage} disabled={ images.length == 0 ? true : (submitting ? true : false)}>Upload</button>
                </div>
            </div>
            </div>
        </div>
    )
}
