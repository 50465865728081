import React from 'react'
import { Link } from 'react-router-dom';
import { DashboardCharts } from './DashboardCharts';

export const InventoryDashboard = () => {
    
    return (
        <div className="content-wrapper">
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Inventory Dashboard</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active">Inventory Dashboard</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">
                <div className="container-fluid">
                    <DashboardCharts />
                </div>
            </section>
        </div>
    )
}
