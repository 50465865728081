import React, {useState} from 'react'
import { useParams } from 'react-router-dom'

export default function Inspection() {
    const [loading, setLoading] = useState(false)
    let { treeId } = useParams();
    const [data, setData] = useState(JSON.parse(localStorage.getItem('tree:data') ?? {}))
    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Inspection</h1>
                    </div>
                    <div className="col-sm-6">
                        {/* <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href={`/operations/scan-qr`}>Scan QR</a></li>
                        <li className="breadcrumb-item"><a href={`/operations/tree/${treeId}/activity`}>Activity</a></li>
                        <li className="breadcrumb-item active">Inspection</li>
                        </ol> */}
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">

                <div className="container-fluid">

                    

                    <div className="row">
                        <div className="col-12">
                            <div className="text-center p-3">
                                <h4>Inspection</h4>
                                <h4>(Pemeriksaan)</h4><br/>
                                <h5>{data.breed} @ {data.farm}</h5>
                                <h5>Serial : {data.serial} </h5>
                                <h5>Ref : {data.ref} </h5>

                                
                                
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-success w-100 col-12 col-md-4" href={`/operations/tree/${treeId}/inspection/growth`}>
                                    Growth
                                    <br/>(Pertumbuhan)
                                    </a>
                                </div>
                                
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-info w-100 col-12 col-md-4" href={`/operations/tree/${treeId}/inspection/deficiency`}>
                                    Deficiency
                                    <br/>(Nutrisis)
                                    </a>
                                </div>
                                
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-primary w-100 col-12 col-md-4" href={`/operations/tree/${treeId}/inspection/pest`}>
                                    Pest
                                    <br/>(Perosak)
                                    </a>
                                </div>
                                
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-secondary w-100 col-12 col-md-4" href={`/operations/tree/${treeId}/inspection/disease`}>
                                    Disease
                                    <br/>(Penyakit)
                                    </a>
                                </div>

                                
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-warning w-100 col-12 col-md-4" href={`/operations/tree/${treeId}/activity`}>
                                    Back
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                
                
                
                </div>
            </section>


            
        </div>
    )
}
