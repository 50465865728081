import React, {useEffect,useState} from 'react'

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";

export const StockChart = ({stockData}) => {
    
    useEffect(() => {
      
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        let root = am5.Root.new("chartdiv-stock");

        const myTheme = am5.Theme.new(root);

        myTheme.rule("RoundedRectangle", ["hierarchy", "node", "shape", "depth1"]).setAll({
        strokeWidth: 2
        });

        myTheme.rule("RoundedRectangle", ["hierarchy", "node", "shape", "depth2"]).setAll({
            fillOpacity: 0,
            strokeWidth: 1,
            strokeOpacity: 0.2
        });

        myTheme.rule("Label", ["node", "depth1"]).setAll({
        // forceHidden: true
            dx:am5.p100,
            fontSize:10,
        });

        myTheme.rule("Label", ["node", "depth2"]).setAll({
            fontSize: 10
        });

        root.setThemes([
            am5themes_Animated.new(root),
            myTheme
        ]);

        // Create wrapper container
        var container = root.container.children.push(
        am5.Container.new(root, {
            width: am5.percent(100),
            height: am5.percent(100),
            layout: root.verticalLayout
        })
        );

        // Create series
        // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
        var series = container.children.push(
            am5hierarchy.Treemap.new(root, {
                sort: "descending",
                singleBranchOnly: false,
                downDepth: 2,
                // upDepth: -1,
                initialDepth: 2,
                valueField: "value",
                categoryField: "name",
                childDataField: "children",
                // nodePaddingOuter: 0,
                // nodePaddingInner: 0
            })
        );

        // series.get("colors").set("step", 5);


        // Generate and set data
        // https://www.amcharts.com/docs/v5/charts/hierarchy/#Setting_data
        // var data = {
        // Toyota: {
        //     "4Runner": 128296,
        //     "86/Scion FR-S": 6846,
        //     Avalon: 32583,
        //     Camry: 387081,
        //     "C-HR": 25755,
        //     Corolla: 329196,
        //     "FJ Cruiser": 4,
        //     Highlander: 215775,
        //     "Land Cruiser": 3100,
        //     Mirai: 1838,
        //     Prius: 108662,
        //     RAV4: 407594,
        //     Sequoia: 12156,
        //     Sienna: 111489,
        //     Tacoma: 198124,
        //     Tundra: 116285,
        //     Venza: 14,
        //     Yaris: 44380
        // },
        // };

        let data ={}
        for (let i of stockData) {
            data[i.category__name + '\n'+ i.location__name ] = {}
        }
        for (let i in data) {
            let temp = {}
            for (let x of stockData) {
                if (x.category__name+ '\n'+ x.location__name  == i) {
                    temp[x.name] = {
                        current_stock: x.current_stock,
                        reorder_level: x.reorder_level
                    }
                }
            }
            data[i] = temp
        }

        function processData(data) {
            var treeData = [];

            // var smallBrands = { name: "Other", children: [] };

            am5.object.eachOrdered(data, (brand) => {
                var brandData = { name: brand, children: [] };

                for (var model in data[brand]) {
                    // console.log(data[brand][model]['current_stock'])
                    // skip -ve or 0 data
                    if (data[brand][model]['current_stock'] > 0) {
                        // console.log(data[brand][model]['current_stock'] < data[brand][model]['reorder_level'])
                        if (data[brand][model]['current_stock'] < data[brand][model]['reorder_level']) {
                            brandData.children.push({ 
                                name: model + '\nCurrent Stock: ' + data[brand][model]['current_stock'], 
                                value: data[brand][model]['current_stock'],
                                reorder_level: data[brand][model]['reorder_level'],
                                // nodeSettings: {
                                //     fill: am5.color(0xFF621F)
                                // }
                            });
                        } else {
                            brandData.children.push({ 
                                name: model + '\nCurrent Stock:' + data[brand][model]['current_stock'], 
                                value: data[brand][model]['current_stock'],
                                reorder_level: data[brand][model]['reorder_level'],
                            });
                        }

                    }
                }
                treeData.push(brandData);
            });

            return [{
                name: "Root",
                children: treeData
            }];
        }
        series.rectangles.template.adapters.add("fill", function(fill, target) {
            // console.log(target.dataItem.dataContext['value'] , target.dataItem.dataContext['reorder_level'])
            // console.log(target.dataItem.dataContext.children)
            if (target.dataItem.dataContext.children && target.dataItem.dataContext.children.length > 0) {
                for (let i in target.dataItem.dataContext.children) {
                    if (target.dataItem.dataContext.children[i]['value'] < target.dataItem.dataContext.children[i]['reorder_level']) {
                        
                        return am5.color(0xb30000)
                    } else {
                        return fill
                    }
                }
               
            }
        })

        series.nodes.template.setAll({
            tooltipText: null,
            cursorOverStyle: "pointer"
        })

        series.data.setAll(processData(data));
        series.set("selectedDataItem", series.dataItems[0]);


    
      return () => {
        root.dispose()
      }
    }, [stockData])
    return (
    <div id="chartdiv-stock" style={{ width: "100%", minHeight: "400px" }}></div>
    )
}
