import React, {useEffect,useState} from 'react'
import axios from 'axios';
import { errorToast, successToast } from '../../utils/toast';
import  {config}  from '../../utils/config';
import  axiosInstance  from '../../utils/api'

export default function Gallery() {
    const [galleries, setGalleries] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getList()
    }, [])

    function getList() {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/core/getGalleryList/`)
        .then( res => {
            setLoading(false)
            console.log("🚀 ~ file: Gallery.js ~ line 7 ~ getList ~ getList", res.data.gallery)
            setGalleries(res.data.gallery)
        }).catch(res=> {
            setLoading(false)
            console.log("🚀 ~ file: Gallery.js ~ line 9 ~ getList ~ getList", res)
        })
    }

    function deleteFolder(val) {
        setLoading(true)
        axiosInstance.delete(`${process.env.REACT_APP_API}/core/deleteGalleryWithFolder/`, {
            headers: config.headers,
            data: {
                "gallery": {
                    "id":val.id, 
                    "folder": val.folder, 
                    "coid": val.coid, 
                }
            }
        }).then(res=>{
            setLoading(false)
            successToast(res.data.message)
            getList()
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
            return window.location.reload()
        })

            
    }


    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Media Gallery</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Media Gallery</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Folders</h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-block btn-default" onClick={()=>{ return window.location = "/gallery/create" }}>Create Folder</button>
                                    </div>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                            <th>Folder Name</th>
                                            <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {galleries.map(gal=> (
                                                <tr>
                                                    <td><a href={`/gallery/${gal.id}/edit`}>{gal.folder}</a></td>
                                                    <td>
                                                        {/* <a href={`/gallery/${gal.id}/edit`} className="btn btn-tool">
                                                            <i className="fas fa-pen"></i>
                                                        </a> */}
                                                        <a href="#" className="btn btn-tool" onClick={()=>{
                                                            if (window.confirm('Are you sure you want to delete folder?')) {
                                                                deleteFolder(gal)
                                                            }
                                                        }}>
                                                            <i className="fas fa-trash"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                {/* <div className="card-footer clearfix">
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                
                
                
                
                </div>
                </section>


            
        </div>
    )
}
