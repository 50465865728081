
import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {useParams} from "react-router-dom";
import { errorToast, successToast } from '../../../utils/toast';
import { config } from '../../../utils/config';
import  axiosInstance  from '../../../utils/api'
import AddImageModal from '../components/AddImageModal';
  
export default function GalleryAddEdit() {
    const [loading, setLoading] = useState(false)
    const [invalid, setInvalid] = useState(false)

    const [type, setType] = useState('add')
    let { folderId } = useParams();
    const [galleryData, setGalleryData] = useState({
        gallery: {},
        url:[]
    })
    const [galleryName, setGalleryName] = useState('')
    const [checkboxes, setCheckboxes] = useState([]) // [ {image_name: '', checked: false}]
    const [addImageModalStatus, setAddImageModalStatus] = useState(false)

    useEffect(() => {
        if (folderId) {
            setType('edit')
            getFolderData(folderId)
        }
    }, [])

    function getFolderData(id) {
        setCheckboxes([])
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/core/getGalleryImage/${id}`)
        .then(res=>{
            setLoading(false)
            setGalleryName(res.data.gallery.folder);
            setGalleryData(res.data)

            for (let i in res.data.url) {
                setCheckboxes(prev=>([
                    ...prev,
                    {
                        image_name: res.data.url[i].split("/")[8].split('?')[0],
                        checked:false
                    }
                ]))
            }

        }).catch(res=>{
            setLoading(false)
            console.log(res)
        })
    }

    function handleSubmit(e) {
        e.preventDefault()

        // VALIDATE INPUT
        if (galleryData.gallery.folder=='') {
            return setInvalid(true)
        } else {
            setInvalid(false)
        }

        if (type=='add') {
            createFolder()
        } else if (type=='edit') {
            updateFolder()
        }

    }

    function createFolder() {
        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/core/createGalleryWithFolder/`, 
        {
            "gallery": {
                "folder": galleryData.gallery.folder,
                "coid": "dc418f5c-ad09-432e-afa6-7b9f18f8ac5d", 
                "username": "aquametrics"
            }
        }).then(res=>{
            setLoading(false)
            // console.log("🚀 ~ file: GalleryAddEdit.js ~ line 28 ~ GalleryAddEdit ~ GalleryAddEdit", res)
            successToast(res.data.message)
            return window.location = '/gallery'
        }).catch( res => {
            setLoading(false)
            // console.log("🚀 ~ file: GalleryAddEdit.js ~ line 30 ~ GalleryAddEdit ~ GalleryAddEdit", res)
            errorToast('Server Error')
        })
    }

    function updateFolder() {
        setLoading(true)
        axiosInstance.put(`${process.env.REACT_APP_API}/core/updateGalleryWithFolder/`, 
        {
            "gallery": {
                "id": galleryData.gallery.id, 
                "newfolder": galleryData.gallery.folder,
                "coid": galleryData.gallery.coid,
                "username": galleryData.gallery.username,
            }
        }).then(res=>{
            setLoading(false)
            // console.log("🚀 ~ file: GalleryAddEdit.js ~ line 28 ~ GalleryAddEdit ~ GalleryAddEdit", res)
            successToast(res.data.message)
            return window.location = '/gallery'
        }).catch( res => {
            setLoading(false)
            // console.log("🚀 ~ file: GalleryAddEdit.js ~ line 30 ~ GalleryAddEdit ~ GalleryAddEdit", res)
            errorToast('Server Error')
        })
    }


    function handleInputChange(e) {
        // setInput(e.target['folder-name'].value)
        setGalleryData( prev => ({
            ...prev,
            gallery: {
                ...prev.gallery,
                folder:e.target.value
            }
        }))
    }

    function uploadImage(e) {
        if (e.target.files.length < 1) {
            return null
        }
        // console.log(e.target.files)

        let bodyFormData = new FormData();
        bodyFormData.append('gallery', JSON.stringify({"id": galleryData.gallery.id}));
        let counter = 1;
        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                let file = new File([e.target.files[i]], new Date().toISOString() + "_" + counter, {type: "image/jpg", lastModified: Date.now()});
                if (counter < 10) {
                    bodyFormData.append('0'+counter, file);
                } else {
                    bodyFormData.append(counter, file);
                }

                // setGalleryData( prev => ({
                //     ...prev,
                //     url: [
                //         ...prev.url,
                //         URL.createObjectURL(e.target.files[i])
                //     ]
                // }))
            }
            counter ++
        }

        setLoading(true)
        axiosInstance.post('https://farmsensapi.cloudatik.com/core/uploadImage/', bodyFormData)
        .then(res=>{
            setLoading(false)
            // console.log(res)
            successToast(res.data.message)
            getFolderData(folderId)
        }).catch(res=>{
            setLoading(false)
            // console.log(res)
            errorToast('Server Error')


        })

        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                URL.revokeObjectURL(e.target.files[i])
            }
        }


    }

    function deleteImage() {
        let image_to_delete_arr = [];
        for (let i in checkboxes) {
            if (checkboxes[i].checked) {
                image_to_delete_arr.push(decodeURIComponent(checkboxes[i].image_name))
            }
        }

        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/core/deleteImage/`, {
            "gallery": {
                "id": galleryData.gallery.id, 
                "delete": image_to_delete_arr,
            }
        }).then(res=>{
            setLoading(false)
            // console.log(res)
            successToast(res.data.message)

            getFolderData(folderId)
        }).catch(res=>{
            setLoading(false)
            // console.log(res)
            errorToast('Server Error')
        })
    }


    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">{galleryName == '' ? 'Create Folder' : galleryName}</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="/gallery">Gallery</a></li>
                        <li className="breadcrumb-item active">{galleryName} {type=='add'?('Create'):('Update')}</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">

                    {type=='edit' && (
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4 className="card-title">Update Image</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            {galleryData.url.length > 0 ? (
                                                galleryData.url.map((image,index) => (
                                                    <div className="col-md-5 col-sm-1 text-center bg-black m-2 p-0"  key={index}>
                                                        <div style={{position:'absolute'}}>
                                                            <input type="checkbox" id={image} style={{position:'absolute'}} checked={checkboxes[index] ? checkboxes[index].checked : false}
                                                            onChange={(e)=>{
                                                                // console.log(e.target.id)
                                                                // console.log(e.target.checked)
                                                                // console.log(e.target.id.split("/")[8].split('?')[0])

                                                                let updatedList = checkboxes.map(i => {
                                                                    if (i.image_name == e.target.id.split("/")[8].split('?')[0]) {
                                                                        return {...i, checked: e.target.checked};
                                                                    }
                                                                    return i;
                                                                });
                                                                setCheckboxes(updatedList)

                                                            }}/>
                                                            <label htmlFor="checkboxPrimary3" style={{position:'absolute'}}></label>
                                                        </div>
                                                        

                                                        <a href={image} data-toggle="lightbox" data-title="Image" data-farm="gallery" >
                                                            <img src={image}  className="img-fluid" style={{height:'200px'}}alt="Farmsens-Image"/>
                                                        </a>
                                                    </div>

                                                ))
                                            ) : (
                                                <div>
                                                    <p>No Image Available</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="card-footer">
                                        <button type="button" className="btn btn-primary m-2" onClick={()=>{
                                            setAddImageModalStatus(true)
                                        }}>
                                            Add Image
                                        </button>
{/*                                         
                                        <button className="btn btn-primary m-2">
                                            <label htmlFor="img" style={{margin:0}}>
                                                Add Image
                                                <input
                                                    accept="image/*"
                                                    id="img"
                                                    multiple
                                                    name="img"
                                                    type="file"
                                                    capture
                                                    onChange={uploadImage}
                                                    hidden
                                                />
                                            </label>
                                        </button> */}
                
                                        {/* <button type="button" className="btn btn-success toastrDefaultSuccess" onClick={()=>{
                                            console.log(this) 
                                            console.log(document) 
                                            console.log(window) 
                                            console.log(window.$(document))
                                            window.$(document).toastr.success('Lorem ipsum dolor sit amet, consetetur sadipscing elitr.')
                                            // successToast('hello')
                                            // errorToast('hello')
                                        }}>
                                        Launch Success Toast
                                        </button> */}


                                        <button type="submit" className="float-right btn btn-default m-2" onClick={deleteImage}>Delete Image</button>
                                        {/* <button type="submit" className="btn btn-default float-right m-2">Deselect All</button>
                                        <button type="submit" className="btn btn-default float-right m-2">Select All</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}



                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">{type=='add'?('Create New Folder'):('Update Folder')}</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="folder-name">Folder Name</label>
                                        <input type="text" className={invalid ? "form-control is-invalid" : "form-control"} id="folder-name" placeholder="Enter Folder Name" value={galleryData.gallery.folder||''} onChange={handleInputChange}/>
                                    </div>
                                    { invalid && (
                                        <span className="invalid-feedback" id="folder-name-error" style={{display: 'inline'}}>Please enter a folder name</span>
                                    )}
                                </div>

                                <div className="card-footer">
                                    <button type="submit" className="btn btn-primary" >{type=='add'?('Submit'):('Update')}</button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>


                    <AddImageModal  data={galleryData} setLoading={setLoading} show={addImageModalStatus} setShow={setAddImageModalStatus}/>
                </div>
            </section>
        </div>

    )
}
