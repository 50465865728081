import React, {useEffect,useState} from 'react'
import { useParams } from 'react-router-dom'
import axiosInstance from '../../../utils/api'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import { formatDate } from '../../../utils/helpers';
import { successToast,errorToast } from '../../../utils/toast';
import { ViewImageModal } from '../../../components/ViewImageModal';
import TreeSummary from '../components/TreeSummary';
import TreeNodeTable from '../components/TreeNodeTable';
import NodeViewModal from '../components/NodeViewModal';
import NodeAddEditModal from '../components/NodeAddEditModal';


const defaultData = {
    images: [],
    main_image:''
}

export const TreesView = () => {
    const [loading, setLoading] = useState(false)
    let { treeId } = useParams();
    const [tree, setTree] = useState({})
    const [chartData, setChartData] = useState([])
    const [tableData, setTableData] = useState([])
    const [nodeTableData, setNodeTableData] = useState([])
    const [inspectionApplication, setInspectionApplication] = useState('summary')

    const [tableModal, setTableModal] = useState(false)
    const [viewModal, setViewModal] = useState(false)
    const [addEditModal, setAddEditModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(defaultData)
    const [modalStatus, setModalStatus] = useState(false)
    const [viewImageModal, setViewImageModal] = useState(false)
    const [imageToView, setImageToView] = useState("")

    const [dashboardData, setDashboardData] = useState({
        application: [],
        breed: [],
        current_soil: [],
        current_weather: {},
        event: {},
        year_soil: {},
        year_weather: {},
    })

    useEffect(() => {
        getTreeData()
    }, [inspectionApplication])

    function getTreeData() {
        setLoading(true)
        let url
        if (treeId) {
            url = `${process.env.REACT_APP_API}/tree/show/${treeId}/`
        } 
        axiosInstance.get(url).then(res=>{
            console.log(1,{
                ...res.data,
                main_image:''
            })
            setLoading(false)
            setTree({
                ...res.data,
                main_image: res.data.image.length > 0 ? res.data.image[0] : '/img/placeholder-image-portrait.jpg'
            })
        }).catch(res=>{
            setLoading(false)
            console.log(res)
        })

        if (inspectionApplication == 'summary') {
            setLoading(true)
            axiosInstance.get(`${process.env.REACT_APP_API}/operations/summary/tree/${treeId}`).then(res=>{
                setLoading(false)
                setDashboardData(res.data)
            }).catch(res=> {
                setLoading(false)
                console.log(res)
            })
        } else if (inspectionApplication=='inspection'||inspectionApplication=='application') {
            setLoading(true)
            let temp_data = [];
            axiosInstance.get(`${process.env.REACT_APP_API}/operations/tree/${treeId}/group/${inspectionApplication}/`)
            .then(res=>{
                setLoading(false)
                if (res.data.length > 0) {
                    let dates_exist = [];
                    res.data.filter(item => {
                        if (dates_exist.includes(formatDate(item.datetime)) ) {
                            for (let i of temp_data) {
                                if (i.date == formatDate(item.datetime)) {
                                    i.count = i.count + 1;
                                    i.events = [
                                        ...i.events,
                                        item
                                    ]
                                }
                            }
                        } else {
                            temp_data.push({
                                'date': formatDate(item.datetime),
                                'count': 1,
                                'events': [item],
                                'category': inspectionApplication
                            })
                            dates_exist.push(formatDate(item.datetime))
                        }  
                    })
                    setChartData(temp_data)
                    console.log(res.data)
                    setTableData(res.data)
                } else {
                    setChartData([])
                    setTableData([])
                }
            }).catch(res=>{
                setLoading(false)
                console.log(res)
            })
        }
    }
    

    useEffect(() => {
        createChart()
        return () => {
            am4core.options.autoDispose = true;
        };
    }, [chartData]);

    
    function handleSelect(item) {
        console.log("🚀 ~ file: Timeline.js ~ line 254 ~ handleSelect ~ handleSelect", item)
        // setSelectedItem(item.events)
        // searchImage(item.category ,item.datetime_format);
        setNodeTableData(item.events)
        setModalStatus(true)
        setTableModal(true)
    }

    function handleAddEdit(value) {
        console.log("🚀 ~ file: Timeline.js ~ line 264 ~ handleAddEdit ~ handleAddEdit", value)
        setSelectedItem( prev => ({
            ...prev,
            ...value
        }))
        handleModal('openAddEdit')
        
    }

    function handleView(value) {
        console.log("🚀 ~ file: Timeline.js ~ line 264 ~ handleView ~ handleView", value)
        // let format_datetime = formatDateTime(new Date(value.datetime).toString())
        // searchImage(value.category, format_datetime)
        setSelectedItem( prev => ({
            ...prev,
            ...value
        }))
        handleModal('openView')

    }
    const createChart =()=> {
        let chart
        if (inspectionApplication == 'inspection') {
            chart = am4core.create("chartdiv-1", am4plugins_timeline.SerpentineChart);
        } else {
            chart = am4core.create("chartdiv-2", am4plugins_timeline.SerpentineChart);
        }
        chart.curveContainer.padding(50, 20, 50, 20);
        chart.levelCount = 4;
        chart.yAxisRadius = am4core.percent(25);
        chart.yAxisInnerRadius = am4core.percent(-25);
        chart.maskBullets = false;

        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";
        chart.dateFormatter.dateFormat = "yyyy-MM-dd HH:mm";
        chart.data = chartData;

        let colorSet = new am4core.ColorSet();
        colorSet.step = 3;
        chart.data.map( val => {
            val['color'] = colorSet.next()
            val['datetimeStart'] = val.date + 'T00:00:00Z'
            val['datetimeEnd'] = val.date + 'T00:00:00Z'
        })

        chart.fontSize = 11;

        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category" ;
        // categoryAxis.fontSize = '12px'
        // categoryAxis.renderer.grid.template.disabled = true;
        // categoryAxis.renderer.labels.template.paddingRight = 25;
        // categoryAxis.renderer.minGridDistance = -10;
        // categoryAxis.renderer.innerRadius = -60;
        // categoryAxis.renderer.radius = 60;
        // categoryAxis.renderer.labels.template.propertyFields.fill = 'color'

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 70;
        dateAxis.baseInterval = { count: 24, timeUnit: "hour" };
        dateAxis.renderer.tooltipLocation = 0;
        dateAxis.startLocation = -0.5;
        dateAxis.renderer.line.strokeDasharray = "1,4";
        dateAxis.renderer.line.strokeOpacity = 0.6;
        dateAxis.tooltip.background.fillOpacity = 0.2;
        dateAxis.tooltip.background.cornerRadius = 5;
        dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
        dateAxis.tooltip.label.paddingTop = 7;

        // Time Range
        let start_time = formatDate(new Date().setDate(new Date().getDate() + 1))
        let endTime = formatDate(new Date(new Date().setMonth(new Date().getMonth()-3)));
        dateAxis.min = new Date(endTime).getTime();
        dateAxis.max = new Date(start_time).getTime();

        let labelTemplate = dateAxis.renderer.labels.template;
        labelTemplate.verticalCenter = "middle";
        labelTemplate.fillOpacity = 0.8;
        labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor("background");
        labelTemplate.background.fillOpacity = 1;
        labelTemplate.padding(7, 7, 7, 7);

        let series = chart.series.push(new am4plugins_timeline.CurveColumnSeries());
        series.columns.template.height = am4core.percent(20);
        series.columns.template.tooltipText = "{task}: [bold]{openDateX}[/] - [bold]{dateX}[/]";

        series.dataFields.openDateX = "datetimeStart";
        series.dataFields.dateX = "datetimeEnd";
        series.dataFields.categoryY = "category";
        series.columns.template.propertyFields.fill = "color"; // get color from data
        series.columns.template.propertyFields.stroke = "color";
        series.columns.template.strokeOpacity = 0;

        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.radius = 10;
        bullet.circle.strokeOpacity = 0;
        bullet.propertyFields.fill = "color";
        bullet.locationX = 1;
        // bullet.horizontalCenter = "middle";
        bullet.tooltipText = `[bold]{date}[/]`;
        bullet.states.create("hover")
        bullet.events.on("hit", function(ev) {
            handleSelect(ev.target.dataItem.dataContext)
        });

        let label = bullet.createChild(am4core.Label);
        label.text = "{count}";
        label.fill = new am4core.InterfaceColorSet().getFor("background");
        label.propertyFields.text = "label";
        label.strokeOpacity = 0;
        label.zIndex = 10;
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = 14;
        label.fontWeight = "600";
        label.dy = 1;


        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.align = "center"
        chart.scrollbarX.width = am4core.percent(85);

        let cursor = new am4plugins_timeline.CurveCursor();
        chart.cursor = cursor;
        cursor.xAxis = dateAxis;
        cursor.yAxis = categoryAxis;
        cursor.lineY.disabled = true;
        cursor.lineX.strokeDasharray = "1,4";
        cursor.lineX.strokeOpacity = 1;

        dateAxis.renderer.tooltipLocation2 = 0;
        categoryAxis.cursorTooltipEnabled = false;
    }

    function handleModal(option) {
        switch (option) {
            case 'openAddEdit':
                setModalStatus(true)
                setTableModal(false)
                setAddEditModal(true)
                setViewModal(false)
                break;
            case 'openTable':
                setModalStatus(true)
                setTableModal(true)
                setAddEditModal(false)
                setViewModal(false)
                break;
            case 'openView':
                setModalStatus(true)
                setTableModal(false)
                setAddEditModal(false)
                setViewModal(true)
                break;
            case 'backToTable':
                setModalStatus(true)
                setTableModal(true)
                setAddEditModal(false)
                setViewModal(false)
                break;
            case 'closeAll':
                setModalStatus(false)
                setTableModal(false)
                setAddEditModal(false)
                setViewModal(false)
                break;
            default:
                setModalStatus(false)
                setTableModal(false)
                setAddEditModal(false)
                setViewModal(false)
                break;
        }
    }
    
    function handleDelete(value) {
        setLoading(true)
        axiosInstance.delete(`${process.env.REACT_APP_API}/operations/delete/${value.id}/`).then( (res) => {
            setLoading(false)
            successToast('Succesfully Deleted Event')
            handleModal('closeAll')
            return window.location.reload()
        })
        .catch(function (err) {
            setLoading(false)
            console.log(err)
        });
    }

    function handleUpdate(item) {
        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/operations/update/${item.id}/`,{
            remark: item.remark,
            description: item.description
        }).then( (res) => {
            setLoading(false)
            successToast('Success')
            handleModal('closeAll')
            return window.location.reload()
        })
        .catch(function (err) {
            setLoading(false)
            console.log(err)
        });
    }

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Tree View</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item active">Tree View</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <ViewImageModal setViewImageModal={setViewImageModal} viewImageModal={viewImageModal} image={imageToView}/>

            <section className="content">
                <div className="container-fluid">
                    <div className="card col-12">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-4 pr-3">
                                    <div className="card mb-2 bg-gradient-dark">
                                        <img className="card-img-top" src={tree.main_image} style={{minHeight:'500px', width:'100%', objectFit: 'contain'}}/>
                                    </div>
                                    <div className="row">
                                        {(tree.image && tree.image.length>0) && tree.image.map(img => {
                                            return (
                                                <div className="attachment-block clearfix" onClick={()=>{
                                                    setViewImageModal(true)
                                                    setImageToView(img)
                                                }}>
                                                    <img className="attachment-img" src={img} alt="farmsens-image"/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="col-6 col-md-4">
                                    <div className="row">
                                        <p><b>Serial</b>: {tree.serial}</p>
                                    </div>
                                    <div className="row">
                                        <p><b>Ref</b>: {tree.ref}</p>
                                    </div>
                                    <div className="row">
                                        <p><b>Breed</b>: {tree.breed}</p>
                                    </div>
                                    <div className="row">
                                        <p><b>Year</b>: {tree.year}</p>
                                    </div>
                                    <div className="row">
                                        <p><b>Age</b>: {tree.age}</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-4">
                                    <div className="row">
                                            <p><b>Height</b>: {tree.height ? (tree.height + ' feet') : ''} </p>
                                        </div>
                                        <div className="row">
                                            <p><b>Diameter</b>: {tree.diameter ? (tree.diameter + ' mm') : ''}</p>
                                        </div>
                                        <div className="row">
                                            <p><b>Latitude</b>: {tree.latitude}</p>
                                        </div>
                                        <div className="row">
                                            <p><b>Longitude</b>: {tree.longitude}</p>
                                        </div>
                                        <div className="row">
                                            <p><b>Farm</b>: {tree.farm}</p>
                                        </div>
                                        <div className="row">
                                            <p><b>Remark</b>: {tree.remark}</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card card-primary card-outline card-tabs">
                        <div className="card-header p-0 pt-1 border-bottom-0">
                            <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" onClick={()=>{setInspectionApplication('summary')}} id="custom-tabs-three-summary-tab" data-toggle="pill" href="#custom-tabs-three-summary" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true">Dashboard</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={()=>{setInspectionApplication('inspection')}} id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true">Inspection</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={()=>{setInspectionApplication('application')}} id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="false">Application</a>
                            </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <div className="tab-content" id="custom-tabs-three-tabContent">
                                <div className="tab-pane fade show active" id="custom-tabs-three-summary" role="tabpanel" aria-labelledby="custom-tabs-three-summary-tab">
                                    <TreeSummary data={dashboardData} />
                                </div>
                                <div className="tab-pane fade" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                                    <div id="chartdiv-1" style={{ width: "100%", height: "500px" }}></div>
                                </div>
                                <div className="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                                    <div id="chartdiv-2" style={{ width: "100%", height: "500px" }}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(inspectionApplication=='inspection'||inspectionApplication=='application') && (
                        <TreeNodeTable 
                        tableData={tableData} 
                        remove={(value)=>{handleDelete(value)}}
                        addEdit={(value)=>{handleAddEdit(value)}}
                        view={(value)=>{handleView(value)}}
                        submitting={loading}
                        />
                    )}
                </div>


                
            <div className={modalStatus ? "modal fade show" : "modal fade"} id="modal-xl" style={modalStatus ? {display:'block'} : {display:'none'}}>
                <div className="modal-dialog modal-xl modal-dialog-scrollable " role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h4 className="modal-title">Events</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{handleModal('closeAll')}}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">
                        { tableModal && (
                            <TreeNodeTable
                                tableData={nodeTableData} 
                                remove={(value)=>{handleDelete(value)}}
                                addEdit={(value)=>{handleAddEdit(value)}}
                                view={(value)=>{handleView(value)}}
                                submitting={loading}
                            />
                        )}

                        { viewModal && (
                            <NodeViewModal 
                            open={viewModal}
                            close={() => { 
                                handleModal('backToTable')
                            }}
                            data={selectedItem} />
                           
                        )}

                        
                        { addEditModal && (
                            <NodeAddEditModal
                            submit={(v)=>{handleUpdate(v)}}
                            data={selectedItem}
                            submitting={loading} />
                           
                        )}
                    </div>
                    <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>{
                        if (viewModal || addEditModal) {
                            handleModal('backToTable')
                        } else {
                            handleModal('closeAll')
                        }
                        }}>Close</button>
                    </div>
                </div>
                </div>
            </div>
            
            </section>
            
        </div>
    )
}
