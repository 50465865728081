import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {config} from '../../utils/config';
import { errorToast, successToast } from '../../utils/toast';
import  axiosInstance  from '../../utils/api'

export default function Farm() {
    const [farms, setFarms] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getList()
    }, [])

    function getList() {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/core/getFarmList/`).then(res=>{
            setLoading(false)
            console.log(res.data.farm)
            setFarms(res.data.farm)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
        
    }

    function deleteFarm(val) {
        setLoading(true)
        axiosInstance.delete(`${process.env.REACT_APP_API}/core/deleteFarmWithFolder/`, {
            headers: config.headers,
            data: {
                "farm": {
                    "farmid": val.farmid, 
                }
            }
        }).then(res=>{
            setLoading(false)
            successToast(res.data.message)
            getList()
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
            return window.location.reload()
        })
    }

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Farm Menu</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Farm Menu</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"></h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-block btn-default"  onClick={()=>{ return window.location = "/farm/create" }}>Create Farm</button>
                                    </div>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Address</th>
                                                <th>Size (Acre)</th>
                                                <th>No. of Trees</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {farms.map((farm, key)=> (
                                                <tr>
                                                    <td><a href={`/farm/${farm.farmid}/edit`}>{farm.name}</a></td>
                                                    <td>{farm.address}</td>
                                                    <td>{farm.size}</td>
                                                    <td>{farm.tree}</td>
                                                    <td>
                                                        <a href="javascript:void(0)" className="btn btn-tool" onClick={()=>{
                                                            if (window.confirm('Are you sure you want to delete farm?')) {
                                                                deleteFarm(farm)
                                                            }
                                                        }}>
                                                            <i className="fas fa-trash"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                {/* <div className="card-footer clearfix">
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                
                
                
                
                </div>
            </section>


            
        </div>
    )
}
