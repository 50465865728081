import React, {useEffect,useState} from 'react'
import axios from 'axios'
import { config } from '../../../utils/config';
import { useLocation,useParams } from 'react-router-dom'
import { successToast, errorToast } from '../../../utils/toast';
import  axiosInstance  from '../../../utils/api'
import AddImageModal from '../components/AddImageModal';

export default function FarmAddEdit() {
    const [loading, setLoading] = useState(false)

    const [type, setType] = useState('add')
    let { farmId } = useParams();
    const [farmData, setFarmData] = useState({
        farm: {},
        url:[]
    })
    const [farmName, setFarmName] = useState('')
    const [checkboxes, setCheckboxes] = useState([]) // [ {image_name: '', checked: false}]
    const [inputs, setInputs] = useState({})

    useEffect(() => {
        if (farmId) {
            setType('edit')
            getfarmData(farmId)
        }
    }, [])

    function getfarmData(id) {
        setCheckboxes([])
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/core/getFarmImage/${id}`)
        .then(res=>{
            setLoading(false)
            setFarmName(res.data.farm.name);
            setFarmData(res.data)

            for (let i in res.data.url) {
                setCheckboxes(prev=>([
                    ...prev,
                    {
                        image_name: res.data.url[i].split("/")[8].split('?')[0],
                        checked:false
                    }
                ]))
            }

        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }

    function handleSubmit(e) {
        e.preventDefault()

        if (type=='add') {
            createFolder()
        } else if (type=='edit') {
            updateFolder()
        }

    }

    function createFolder() {
        console.log(farmData.farm)
        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/core/createFarmWithFolder/`, 
        {
            "farm": farmData.farm
        }).then(res=>{
            setLoading(false)
            // console.log("🚀 ~ file: farmAddEdit.js ~ line 28 ~ farmAddEdit ~ farmAddEdit", res)
            successToast(res.data.message)
            return window.location = '/farm'
        }).catch( res => {
            setLoading(false)
            // console.log("🚀 ~ file: farmAddEdit.js ~ line 30 ~ farmAddEdit ~ farmAddEdit", res)
            errorToast('Server Error')
        })
    }

    function updateFolder() {
        console.log()
        setLoading(true)
        axiosInstance.put(`${process.env.REACT_APP_API}/core/updateFarmWithFolder/`, 
        {
            "farm": {
                "farmid": farmData.farm.farmid, 
                "newname": farmData.farm.name,
                "address": farmData.farm.address,
                "latitude": farmData.farm.latitude,
                "longitude": farmData.farm.longitude,
                "size": farmData.farm.size,
                "story": farmData.farm.story,
                "tree": farmData.farm.tree,
            }
        }).then(res=>{
            setLoading(false)
            // console.log("🚀 ~ file: GalleryAddEdit.js ~ line 28 ~ GalleryAddEdit ~ GalleryAddEdit", res)
            successToast(res.data.message)
            return window.location = '/farm'
        }).catch( res => {
            setLoading(false)
            // console.log("🚀 ~ file: GalleryAddEdit.js ~ line 30 ~ GalleryAddEdit ~ GalleryAddEdit", res)
            errorToast('Server Error')
        })
    }


    function handleInput(e, field) {
        setFarmData( prev => ({
            ...prev,
            farm: {
                ...prev.farm,
                [field]:e.target.value
            }
        }))
    }

    function uploadImage(e) {
        if (e.target.files.length < 1) {
            return null
        }
        

        let bodyFormData = new FormData();
        bodyFormData.append('farm', JSON.stringify({
            "farmid": farmData.farm.farmid
        }));
        let counter = 1;
        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                let file = new File([e.target.files[i]], new Date().toISOString() + "_" + counter, {type: "image/jpg", lastModified: Date.now()});
                if (counter < 10) {
                    bodyFormData.append('0'+counter, file);
                } else {
                    bodyFormData.append(counter, file);
                }

                // setFarmData( prev => ({
                //     ...prev,
                //     url: [
                //         ...prev.url,
                //         URL.createObjectURL(e.target.files[i])
                //     ]
                // }))
            }
            counter ++
        }

        setLoading(true)
        axiosInstance.post('https://farmsensapi.cloudatik.com/core/uploadImage/', bodyFormData)
        .then(res=>{
            setLoading(false)
            // console.log(res)
            successToast(res.data.message)
            getfarmData(farmId)
        }).catch(res=>{
            setLoading(false)
            // console.log(res)
            errorToast('Server Error')
        })

        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                URL.revokeObjectURL(e.target.files[i])
            }
        }


    }

    function deleteImage() {
        let image_to_delete_arr = [];
        for (let i in checkboxes) {
            if (checkboxes[i].checked) {
                image_to_delete_arr.push(decodeURIComponent(checkboxes[i].image_name))
            }
        }

        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/core/deleteImage/`, {
            "farm": {
                "farmid": farmData.farm.farmid,
                "delete": image_to_delete_arr,
            }
        }).then(res=>{
            setLoading(false)
            // console.log(res)
            successToast(res.data.message)

            getfarmData(farmId)
        }).catch(res=>{
            setLoading(false)
            // console.log(res)
            errorToast('Server Error')
        })
    }



    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">{farmName == '' ? 'Create Farm' : farmName}</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="/farm">Farm</a></li>
                        <li className="breadcrumb-item active">{farmName} {type=='add'?('Create'):('Update')}</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">

                    {type=='edit' && (
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4 className="card-title">Update Image</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            {farmData.url.length > 0 ? (
                                                farmData.url.map((image,index) => (

                                                    <div className="col-md-5 col-sm-1 text-center bg-black m-2 p-0"  key={index}>
                                                        <div style={{position:'absolute'}}>
                                                            <input type="checkbox" id={image} style={{position:'absolute'}} checked={checkboxes[index] ? checkboxes[index].checked : false}
                                                            onChange={(e)=>{
                                                                // console.log(e.target.id)
                                                                // console.log(e.target.checked)
                                                                // console.log(e.target.id.split("/")[8].split('?')[0])

                                                                let updatedList = checkboxes.map(i => {
                                                                    if (i.image_name == e.target.id.split("/")[8].split('?')[0]) {
                                                                        return {...i, checked: e.target.checked};
                                                                    }
                                                                    return i;
                                                                });
                                                                setCheckboxes(updatedList)

                                                            }}/>
                                                            <label htmlFor="checkboxPrimary3" style={{position:'absolute'}}></label>
                                                        </div>
                                                        

                                                        <a href={image} data-toggle="lightbox" data-title="Image" data-farm="farm" >
                                                            <img src={image}  className="img-fluid" style={{height:'200px'}}alt="Farmsens-Image"/>
                                                        </a>
                                                    </div>

                                                ))
                                            ) : (
                                                <div>
                                                    <p>No Image Available</p>
                                                </div>
                                            )}


                                        </div>
                                    </div>

                                    <div className="card-footer">
                                        <button type="button" className="btn btn-primary m-2" data-toggle="modal" data-target="#add-image-modal">
                                                Add Image
                                        </button>
                                        {/* <button className="btn btn-primary m-2">
                                            <label htmlFor="img" style={{margin:0}}>
                                                Add Image
                                                <input
                                                    accept="image/*"
                                                    id="img"
                                                    multiple
                                                    name="img"
                                                    type="file"
                                                    capture
                                                    onChange={uploadImage}
                                                    hidden
                                                />
                                            </label>
                                        </button> */}
                
                                        {/* <button type="button" className="btn btn-success toastrDefaultSuccess" onClick={()=>{
                                            console.log(this) 
                                            console.log(document) 
                                            console.log(window) 
                                            console.log(window.$(document))
                                            window.$(document).toastr.success('Lorem ipsum dolor sit amet, consetetur sadipscing elitr.')
                                            // successToast('hello')
                                            // errorToast('hello')
                                        }}>
                                        Launch Success Toast
                                        </button> */}


                                        <button type="submit" className="float-right btn btn-default m-2" onClick={deleteImage}>Delete Image</button>
                                        {/* <button type="submit" className="btn btn-default float-right m-2">Deselect All</button>
                                        <button type="submit" className="btn btn-default float-right m-2">Select All</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}



                    <div className="row">
                        <div className="col-md-12">
                            <form className="form-horizontal"  onSubmit={handleSubmit}>
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">Farm Info</h3>
                                    </div>

                                        <div className="card-body">
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Farm Name<span style={{color:'red'}}>*</span></label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.name ||''} onChange={(e)=>handleInput(e,'name')} type="text" className="form-control" id="library-name" placeholder="Required" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Address</label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.address ||''} onChange={(e)=>handleInput(e,'address')} type="text" className="form-control" id="breed" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Latitude</label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.latitude ||''} onChange={(e)=>handleInput(e,'latitude')} type="text" className="form-control" id="breed-other-name" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Longitude</label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.longitude ||''} onChange={(e)=>handleInput(e,'longitude')}  type="text" className="form-control" id="grade" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Size</label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.size ||''} onChange={(e)=>handleInput(e,'size')}  type="text" className="form-control" id="grade" placeholder="Optional (acre)" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">No of Trees</label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.tree ||''} onChange={(e)=>handleInput(e,'tree')}  type="text" className="form-control" id="grade" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Story</label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.story ||''} onChange={(e)=>handleInput(e,'story')}  type="text" className="form-control" id="grade" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                        </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary" >{type=='add'?('Submit'):('Update')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <AddImageModal  data={farmData} setLoading={setLoading} />

                </div>
            </section>
        </div>
    )
}
