import React, {useEffect,useState, useContext} from 'react'
import axios from 'axios';
import {config} from '../../../utils/config';
import { errorToast, successToast } from '../../../utils/toast';
import { useParams } from "react-router-dom";
import  axiosInstance  from '../../../utils/api';

export default function BatchInputSerial() {
    const [loading, setLoading] = useState(false)
    const {batch, serialFrom, serialTo} = useParams();
    const [tags, setTags] = useState([])


    useEffect(() => {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/durian/getSubmittedTagList/${batch}/${serialFrom}/${serialTo}`).then(res=>{
            setLoading(false)
            setTags(res.data.tag)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
        })
    }, [])

    function navigateTo(tag) {
        localStorage.setItem('serialData', JSON.stringify(tag))
        return window.location = `/trackntrace/batch-input/serials/${batch}/${serialFrom}/${serialTo}/edit/${tag.tagid}`
    }


    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Batch Input Serial</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="/trackntrace/batch-input">Batch Input</a></li>
                        <li className="breadcrumb-item active">Batch Input Serial</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Update Individual Info</h3>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>Serial</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tags.map(tag=>(
                                                <tr>
                                                    <td><a href='#' onClick={()=>navigateTo(tag)}>{tag.serial}</a></td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <a href="/trackntrace" className="btn btn-primary">Complete</a>
                                </div>

                            </div>
                        </div>
                    </div>
                
                </div>
            </section>


            
        </div>
    )
}
