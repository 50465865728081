import React, {useEffect,useState} from 'react'


export default function TrackNTrace() {
    const [loading, setLoading] = useState(false)

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Track {'&'} Trace</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Track {'&'} Trace</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">

                            <button type="button" className="btn btn-block btn-success btn-lg" onClick={()=>{return window.location="/trackntrace/batch-input"}}>Input Tag</button>
                            <button type="button" className="btn btn-block btn-info btn-lg" onClick={()=>{return window.location="/tag"}}>Tag Records</button>
                            <button type="button" className="btn btn-block btn-primary btn-lg" onClick={()=>{return window.location="/library"}}>Library Records</button>
                            <button type="button" className="btn btn-block btn-warning btn-lg">Visualization</button>
                        </div>
                    </div>
                </div>
            </section>


            
        </div>
    )
}
