import React from 'react'
import { Link } from 'react-router-dom';

export const LocationTable = ({tableData, inventory, handleDelete}) => {
    return (
        <table className="table table-hover text-nowrap">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {tableData.map((data, key) => <tr>
                        <td><Link to={`/inventory/${inventory}/${data.id}/edit`}>{data.name ? data.name : '-'}</Link></td>
                        <td>{data.address}</td>
                        <td>
                            <button className="btn btn-tool" onClick={()=> {
                                if (window.confirm('Are you sure you want to delete?')) {
                                    handleDelete(data.id)
                                }
                            }}>
                                <i className="fas fa-trash"></i>
                            </button>
                        </td>
                    </tr>)}
            </tbody>
        </table>
    )
}
