import axios from 'axios'
import React, {useState,useEffect} from 'react'
import { Redirect } from 'react-router-dom'
import { errorToast } from '../../utils/toast'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authActions } from '../../redux';


function Login() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const { isAuthenticated, isLoading } = useSelector((state)=> state.auth)
    const dispatch = useDispatch()
    const { login } = bindActionCreators(authActions, dispatch)


    function handleSubmit(e) {
        e.preventDefault()
        login(username, password)
    }
    
    useEffect(() => {
        if (isAuthenticated) {
            return window.location.href = '/'
        }
    }, [isAuthenticated])
    

    function handleChange(e, type) {
        switch(type) {
            case 'username':
                setUsername(e.target.value)
                break;
            case 'password':
                setPassword(e.target.value)
                break;
            default:
                break;
        }
    }
    return (

        <div className="hold-transition login-page">
            <div className="overlay-wrapper" style={{ display: (isLoading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            <div className="login-box">
                <div className="login-logo">
                    <b>Farmsens</b>
                </div>
                <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Sign in to start your session</p>
                    
                            <form onSubmit={e=>handleSubmit(e)} method="post">
                                <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder="Username"  value={username} onChange={(e)=>handleChange(e, 'username')}/>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-user"></span>
                                    </div>
                                </div>
                                </div>
                                <div className="input-group mb-3">
                                <input type="password" className="form-control" value={password} onChange={(e)=>handleChange(e, 'password')}/>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                                </div>
                                <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                </div>
                                </div>
                            </form>
                    
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Login