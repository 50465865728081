import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {useParams} from "react-router-dom";
import { config } from '../../../utils/config';
import  axiosInstance  from '../../../utils/api'

export default function GalleryDetail() {

    // const [images, setImages] = useState([])
    const [loading, setLoading] = useState(false)
    let { folderId } = useParams();
    const [galleryData, setGalleryData] = useState({
        url:[]
    })

    useEffect(() => {
        getList(folderId)
    }, [])

    function getList(id) {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/core/getGalleryImage/${id}`)
        .then( res => {
            setLoading(false)
            console.log("🚀 ~ file: Gallery.js ~ line 7 ~ getList ~ getList", res.data)
            setGalleryData(res.data)
        }).catch(res=> {
            setLoading(false)
            console.log("🚀 ~ file: Gallery.js ~ line 9 ~ getList ~ getList", res)
            alert('Server Error')
        })
    }


    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Gallery Detail</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item"><a href="/gallery">Media Gallery</a></li>
                            <li className="breadcrumb-item active">Media Gallery Detail</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    
                </div>
            </section>

            
        </div>
    )
}
