import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import axiosInstance from '../../../utils/api';
import { errorToast, successToast } from '../../../utils/toast';
import AddImageModal from './AddImageModal';

export default function ItemForm({handleInput, inputs, index, locationOptions, categoryOptions, unitMeasureOptions}) {
    const [type, setType] = useState('add')
    let {inventoryId} = useParams();
    const [checkboxes, setCheckboxes] = useState([]) // [ {image_name: '', checked: false}]
    const [addImageModalStatus, setAddImageModalStatus] = useState(false)

    useEffect(() => {
        if (inventoryId) {
            setType('edit')
            for (let i in inputs.image) {
                setCheckboxes(prev=>([
                    ...prev,
                    {
                        image_name: inputs.image[i].split("/")[6].split('?')[0],
                        checked:false
                    }
                ]))
            }
        } else {
            setType('add')
        }
    }, [inputs])
    
    
    function deleteImage() {
        let image_to_delete_arr = [];
        for (let i in checkboxes) {
            if (checkboxes[i].checked) {
                image_to_delete_arr.push(decodeURIComponent(checkboxes[i].image_name))
            }
        }
        axiosInstance.post(`${process.env.REACT_APP_API}/core/deleteImage/`, {
            "inventory": {
                "itemid": inputs.itemid, 
                "delete": image_to_delete_arr,
            }
        }).then(res=>{
            successToast(res.data.message)
            return window.location.reload()
        }).catch(res=>{
            errorToast('Server Error')
        })
    }

    return (
    <>
    
    {type=='edit' && (
        <div className="row">
            <div className="col-12">
                <div className="card card-default">
                    <div className="card-header">
                        <h4 className="card-title">Update Image</h4>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {(inputs.image && inputs.image.length > 0) ? (
                                inputs.image.map((image, i) => (

                                    <div className="col-md-5 col-sm-1 text-center bg-black m-2 p-0"  key={i}>
                                        <div style={{position:'absolute'}}>
                                            <input type="checkbox" id={image} style={{position:'absolute'}} checked={checkboxes[i] ? checkboxes[i].checked : false}
                                            onChange={(e)=>{
                                                let updatedList = checkboxes.map(i => {
                                                    if (i.image_name == e.target.id.split("/")[6].split('?')[0]) {
                                                        return {...i, checked: e.target.checked};
                                                    }
                                                    return i;
                                                });
                                                setCheckboxes(updatedList)

                                            }}/>
                                            <label htmlFor="checkboxPrimary3" style={{position:'absolute'}}></label>
                                        </div>
                                        

                                        <a href={image} data-toggle="lightbox" data-title="Image" data-farm="farm" >
                                            <img src={image}  className="img-fluid" style={{height:'200px'}}alt="Farmsens-Image"/>
                                        </a>
                                    </div>

                                ))
                            ) : (
                                <div>
                                    <p>No Image Available</p>
                                </div>
                            )}


                        </div>
                    </div>

                    <div className="card-footer">
                        <button type="button" className="btn btn-primary m-2" onClick={()=>{setAddImageModalStatus(true)}}>
                            Add Image
                        </button>

                        <button type="submit" className="float-right btn btn-default m-2" onClick={deleteImage}>Delete Image</button>
                    </div>
                </div>
            </div>
        </div>
    )}

    <form className="form-horizontal" style={{border: '1px dotted darkgray'}}>
        <div className="card-body">
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Name</label>
                <div className="col-sm-10">
                    <input value={inputs.name} onChange={e => handleInput(e, 'name', index)} type="text" className="form-control" />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Location</label>
                <div className="col-sm-10">
                    <select className="form-control" value={inputs.location} onChange={e => handleInput(e, 'location' , index)}>
                            {!inputs.location && (<option>-- SELECT LOCATION --</option>)}
                        {locationOptions.map(l => <option value={l.name}>{l.name}</option>)}
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Category</label>
                <div className="col-sm-10">
                    <select className="form-control" value={inputs.category} onChange={e => handleInput(e, 'category', index)}>
                        {!inputs.category && (<option>-- SELECT CATEGORY --</option>)}
                        {categoryOptions.map(c => <option value={c.name}>{c.name}</option>)}
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Unit Measure<span style={{ color: 'red' }}>*</span></label>
                <div className="col-sm-10">
                    <select className="form-control" value={inputs.unit_measure} onChange={e => handleInput(e, 'unit_measure', index)}>
                        {!inputs.unit_measure && (<option>-- SELECT UNIT MEASURE --</option>)}
                        {unitMeasureOptions.map(c => <option value={c.unit}>{c.unit}</option>)}
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Internal Part No.</label>
                <div className="col-sm-10">
                    <input value={inputs.ref} onChange={e => handleInput(e, 'ref', index)} type="text" className="form-control" />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Manufacturer Part No.</label>
                <div className="col-sm-10">
                    <input value={inputs.mpn} onChange={e => handleInput(e, 'mpn', index)} type="text" className="form-control" />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Description</label>
                <div className="col-sm-10">
                    <input value={inputs.description} onChange={e => handleInput(e, 'description', index)} type="text" className="form-control" />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Current Stock<span style={{ color: 'red' }}>*</span></label>
                <div className="col-sm-10">
                    <input value={inputs.current_stock} onChange={e => handleInput(e, 'current_stock', index)} type="number" className="form-control" />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Reorder Level<span style={{ color: 'red' }}>*</span></label>
                <div className="col-sm-10">
                    <input value={inputs.reorder_levelX} onChange={e => handleInput(e, 'reorder_level', index)} type="number" className="form-control" />
                </div>
            </div>

        </div>
    </form>
    
    <AddImageModal  data={inputs} show={addImageModalStatus} setShow={setAddImageModalStatus}/>
    </>
    );
  }
