import React, {useEffect,useState} from 'react'
import {config} from '../../../utils/config';
import { errorToast, successToast } from '../../../utils/toast';
import  axiosInstance  from '../../../utils/api'
import { useParams } from 'react-router-dom'

export default function FeedbackDetail() {
    const [loading, setLoading] = useState(false)
    const [feedbackData, setFeedbackData] = useState({
        tag: {},
        tag_url:[],

        feedback:{},
        feedback_url:[],
    })
    let { feedbackId } = useParams();

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/feedback/getFeedbackSpecific/${feedbackId}/`).then(res=>{
            setLoading(false)
            setFeedbackData({
                tag: res.data.tag,
                tag_url:res.data.image_tag,
        
                feedback: res.data.feedback[0],
                feedback_url:res.data.image_feedback,
            })
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Feedback Details</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="/feedback-records">Feedback Records</a></li>
                        <li className="breadcrumb-item active">Feedback Records</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h4 className="card-title">Image</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {feedbackData.tag_url.length > 0 ? (
                                            feedbackData.tag_url.map((image,index) => (
                                                <div className="col-3 "  key={index}>
                                                    <a href={image} data-toggle="lightbox" data-title="Image" data-farm="farm" >
                                                        <img src={image}  className="img-fluid" alt="Farmsens-Image"/>
                                                    </a>
                                                </div>
                                            ))
                                        ) : (
                                            <div>
                                                <p>No Image Available</p>
                                            </div>
                                        )}


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="card card-primary card-outline  m-1">
                                <div className="card-header">
                                    <h3 className="card-title">Basic Info</h3>
                                </div>

                                <div className="card-body">

                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Breed</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.tag.breed ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Other Name</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.tag.breed_other_name ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Grade</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.tag.grade ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Origin</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.tag.origin ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Altitude</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.tag.altitude ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="card card-primary card-outline  m-1">
                                <div className="card-header">
                                    <h3 className="card-title">Flavour Characteristic</h3>
                                </div>

                                <div className="card-body">

                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Taste</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.tag.taste ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Color</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.tag.color ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Texture</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.tag.texture ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Aroma</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.tag.aroma ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="card card-primary card-outline  m-1">
                                <div className="card-header">
                                    <h3 className="card-title">Fruit Info</h3>
                                </div>

                                <div className="card-body">

                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Seed Size</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.tag.seed_size ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Shape</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.tag.shape ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Tree Age</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.tree_age ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Date Harvest</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.harvest ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                


                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="card ">
                                <div className="card-header">
                                    <h4 className="card-title">Feedback Image</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {feedbackData.feedback_url.length > 0 ? (
                                            feedbackData.feedback_url.map((image,index) => (
                                                <div className="col-3 "  key={index}>
                                                    <a href={image} data-toggle="lightbox" data-title="Image" data-farm="farm" >
                                                        <img src={image}  className="img-fluid" alt="Farmsens-Image"/>
                                                    </a>
                                                </div>
                                            ))
                                        ) : (
                                            <div>
                                                <p>No Image Available</p>
                                            </div>
                                        )}


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card m-1">
                                <div className="card-header">
                                    <h3 className="card-title">Feedback Section</h3>
                                </div>

                                <div className="card-body">

                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Overall</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.feedback.overall ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Recommendation</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.feedback.recommendation ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Service Quality</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.feedback.service_quality ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Texture</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.feedback.texture ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Aroma</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.feedback.aroma ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Flavor</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.feedback.flavor ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Info Accuracy</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.feedback.info_accuracy ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Value Money</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.feedback.value_money ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Remark</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.feedback.remark ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Price</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.feedback.price ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Price KG</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.feedback.price_kg ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Name</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.feedback.name ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Phone</label>
                                        <div className="col-sm-8">
                                            <input value={feedbackData.feedback.phone ||''}  type="text" className="form-control" placeholder="Required" disabled={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </section>


            
        </div>
    )
}
