import React, {useEffect,useState} from 'react'
import axiosInstance from '../../utils/api'
import { errorToast, successToast } from '../../utils/toast'

export default function Trees() {
    const [loading, setLoading] = useState(false)
    const [trees, setTress] = useState([])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/tree`).then(res=> {
            setLoading(false)
            console.log(res.data)
            setTress(res.data)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }

    const handleDelete = (u) => {
        setLoading(true)
        axiosInstance.delete(`${process.env.REACT_APP_API}/tree/delete/${u.treeid}`).then(res=>{
            setLoading(false)
            successToast('Succeesfully Deleted')
            getData()
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })
    }

    
    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Trees</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Trees</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"></h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-block btn-default"  onClick={()=>{ return window.location = "/trees/create"  }}>Create Trees</button>
                                    </div>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>Tree ID</th>
                                                <th>Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {trees.length > 0 ? 
                                                trees.map( tree => (
                                                    <tr>
                                                        <td>
                                                            <a href={`/trees/${tree.treeid}/view`}>{tree.serial}</a>
                                                        </td>
                                                        <td>{tree.breed ? tree.breed : '-'}</td>
                                                        <td>{tree.year}</td>
                                                        <td>{tree.height}</td>
                                                        <td>{tree.diameter}</td>
                                                        <td>{tree.farm}</td>
                                                        <td>
                                                            <a href={`/trees/${tree.treeid}/edit`} className="btn btn-tool">
                                                                <i className="fas fa-edit"></i>
                                                            </a>
                                                            {/* <a href="javascript:void(0)" className="btn btn-tool" onClick={()=>{
                                                                if (window.confirm('Are you sure you want to delete tree?')) {
                                                                    handleDelete(tree)
                                                                }
                                                            }}>
                                                                <i className="fas fa-trash"></i>
                                                            </a> */}
                                                        </td>
                                                    </tr>
                                                ))
                                                : (
                                                <tr>
                                                    <td>No Data</td>
                                                </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                
                
                </div>
            </section>


            
        </div>
    )
}
