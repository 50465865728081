import React, {useEffect} from 'react'
import ApplicationBarGraph from '../../dashboard/components/ApplicationBarGraph'
import GrowthGraph from '../../dashboard/components/GrowthGraph'
import InspectionBarGraph from '../../dashboard/components/InspectionBarGraph'

export default function TreeSummary({data}) {
    
    useEffect(() => {
        RenderInspectionGraph()
        RenderApplicationGraph()
        RenderGrowthGraph()
    }, [data])

    function RenderInspectionGraph() {
        return (<InspectionBarGraph title="Inspection" data={data.event}/>)
    }
    function RenderApplicationGraph() {
        return(<ApplicationBarGraph title="Application"  data={data.application}/>)
    }
    function RenderGrowthGraph() {
        return(<GrowthGraph title="Tree Growth"  data={data.growth}/>)
    }
    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="card w-100">
                        <div className="card-header">
                            <h3 className="card-title">Historical Min Max</h3>
                        </div>
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Min</th>
                                        <th>Max</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Temperature(C)</td>
                                        {/* <td>{data.year_weather.t_min}</td>
                                        <td>{data.year_weather.t_max}</td> */}
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>Humidity (%)</td>
                                        {/* <td>{data.year_weather.h_min}</td>
                                        <td>{data.year_weather.h_max}</td> */}
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>Barometric Pressure (kPa)</td>
                                        {/* <td>{data.year_weather.bp_min}</td>
                                        <td>{data.year_weather.bp_max}</td> */}
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>Wind Speed(m/s)</td>
                                        {/* <td>{data.year_weather.ws_min}</td>
                                        <td>{data.year_weather.ws_max}</td> */}
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>PAR(μmol/㎡·s)</td>
                                        {/* <td>{data.year_weather.par_min}</td>
                                        <td>{data.year_weather.par_max}</td> */}
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="card w-100">
                        <div className="card-header">
                            <h3 className="card-title">Historical</h3>
                        </div>
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th>Parameter</th>
                                        <th>Average</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>EC(mS/cm)</td>
                                        {/* <td>{data.year_soil.soilec_avg}</td> */}
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>pH</td>
                                        {/* <td>{data.year_soil.soilph_avg}</td> */}
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>Moisture(%)</td>
                                        {/* <td>{data.year_soil.wet_avg}</td> */}
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>Temp(C)</td>
                                        {/* <td>{data.year_soil.soilt_avg}</td> */}
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <RenderInspectionGraph />
                </div>
                <div className="col-12 col-md-6">
                    <RenderApplicationGraph />
                </div>
                <div className="col-12 col-md-6">
                    <RenderGrowthGraph />
                </div>
            </div>
        </div>
    )
}
