import React, {useMemo, useState} from 'react'
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/helpers';
import DataTable from 'react-data-table-component';

export const PurchaseTable = ({tableData, inventory, handleDelete}) => {
    
    const columns = [
        {
            name: 'Item',
            selector: row => row.item,
            cell: (row, index, column, id) => {
                return <Link to={`/inventory/${inventory}/${row.id}/edit`}>{row.item ? row.item : '-'}</Link>
            },
            sortable: true,
        },
        {
            name: 'Supplier',
            selector: row => row.supplier,
            sortable: true,
        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            sortable: true,
        },
        {
            name: 'Price',
            selector: row => row.price,
        },
        {
            name: 'Ref',
            selector: row => row.ref,
            sortable: true,
        },
        {
            name: 'Datetime',
            selector: row => row.datetime,
            format: row => formatDate(row.datetime),
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row)=> {
                return (
                    <button className="btn btn-tool" onClick={()=> {
                        if (window.confirm('Are you sure you want to delete?')) {
                            handleDelete(row.id)
                        }
                    }}>
                        <i className="fas fa-trash"></i>
                    </button>
                )
            }
        },
    ];

	const [filterText, setFilterText] = useState('');
	const [filterDate, setFilterDate] = useState('');
    const filteredItems = tableData.filter(
		item => {
            if ((item.item && item.item.toLowerCase().includes(filterText.toLowerCase())) && (item.datetime && formatDate(item.datetime).toLowerCase().includes(filterDate.toLowerCase()))) {
                
            return item
            }
            
        }
	);
    const subHeaderComponentMemo = useMemo(() => {

		return (
            <>
            <input value={filterDate} onChange={e=>{setFilterDate(e.target.value)}} placeholder="Search Date" style={{marginRight:5}}/>
            <input value={filterText} onChange={e=>{setFilterText(e.target.value)}} placeholder="Search Item"/>
            </>
		);
	}, [filterText, filterDate]);

    return (
        <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationComponentOptions={{
                noRowsPerPage:true
            }}
            subHeader
			subHeaderComponent={subHeaderComponentMemo}
        />
    )
}
