import React, {useEffect, useState, useRef} from 'react'
import Cropper from 'cropperjs'
import "cropperjs/dist/cropper.min.css"
import "./imagecropper.css"

export const ImageCropper = ({imageToCrop, setCroppedImage, orientation='landscape', cancel}) => {
    const imageElement = useRef()

    useEffect(() => {
        let aspectRatio = 16/9
        if (orientation=='portrait') {
            aspectRatio = 2/3
        }
        const cropper = new Cropper(imageElement.current, {
            // zoomable: false,
            scalable:false,
            aspectRatio: aspectRatio,
            cropBoxResizable: false,
            crop: ()=> {
                const canvas = cropper.getCroppedCanvas();
                // setImageDestination(canvas.toDataURL("image/png"))
            },
            ready() {
                cropper.setDragMode("move")
            }
        })
    }, [])

    function getCroppedImage() {
        imageElement.current.cropper.getCroppedCanvas().toBlob((blob)=> {
            setCroppedImage(new File([blob], "image.png"))
        })
        // console.log(imageElement.current.cropper.getCroppedCanvas().toDataURL("image/png"))
        // setCroppedImage({
        //     image_blob: imageElement.current.cropper.getCroppedCanvas()
        // })
        
    }
    
    return (
        <div className='p-4'>
            <div className="img-container">
                <img ref={imageElement} src={imageToCrop.image_blob} alt="Source" className="img-fluid"/>
            </div>
            <div className="text-right py-2">
                <button className="btn btn-default btn" onClick={cancel}>Cancel</button>
                <button className="btn btn-primary btn ml-2" onClick={getCroppedImage}>Crop</button>
            </div>
            {/* <img className="img-preview" src={imageDestination} alt="Destination"/> */}

        </div>
    )
}
