import React, {useEffect,useState,useContext} from 'react'
import axios from 'axios';
import {config} from '../../../utils/config';
import { errorToast, successToast } from '../../../utils/toast';
import  axiosInstance  from '../../../utils/api';

export default function BatchInput() {
    const [tags, setTags] = useState([])
    const [loading, setLoading] = useState(false)
    const [dropdownBatch, setDropdownBatch] = useState([])
    const [dropwdownSerial, setDropdownSerial] = useState([])
    const [batchSerialList, setBatchSerialList] = useState({})
    const [serialCount, setSerialCount] = useState(0)

    const [galleryList, setGalleryList] = useState([])
    const [farmList, setFarmList] = useState([])
    const [libraryList, setLibraryList] = useState([])

    const [libraryData, setLibraryData] = useState({})

    const [batchSelect, setBatchSelect] = useState('')
    const [serialSelect, setSerialSelect] = useState({
        to: '',
        from:''
    })
    const [gallerySelect, setGallerySelect] = useState({
        id:'',})
    const [farmSelect, setFarmSelect] = useState({
        id:'',
    })
    const [librarySelect, setLibrarySelect] = useState({
        id: '',
        name:''
    })
    const [coinfo, setCoinfo] = useState(false)

    const [images, setImages] = useState([])

    useEffect(() => {
        getBatch()
        getGalleryList()
        getFarmList()
        getLibraryList()

        resetState()
    }, [])

    function resetState() {
        setDropdownBatch([])
        setDropdownSerial([])
        setSerialCount(0)
        setBatchSerialList({})
    }

    useEffect(() => {
        let to = dropwdownSerial.indexOf(serialSelect.to)
        let from = dropwdownSerial.indexOf(serialSelect.from)

        if ((to - from) < 0) {
            alert('From serial must be small than To serial')
            setDropdownSerial(batchSerialList[batchSelect])
            setSerialSelect({
                to: batchSerialList[batchSelect][0],
                from: batchSerialList[batchSelect][0]
            })
        } else {
            setSerialCount((to - from) + 1)
        }
    }, [serialSelect])

    function getBatch() {
        setLoading(true)
        setDropdownBatch([])
        setDropdownSerial([])
        axiosInstance.get(`${process.env.REACT_APP_API}/durian/getAvailableTagBatchSerial/`).then(res=>{
            setLoading(false)
            if (Object.keys(res.data.list).length > 0) {

                // let final_obj = {}
                // let temp_group = []
                // let current_key = ''
                // for (let i in res.data.list) {
                //     for (let x in res.data.list[i]) {
                //         temp_group.push(res.data.list[i][x])
                //         console.log(parseInt(res.data.list[i][x]), parseInt(res.data.list[i][x])+1, parseInt(res.data.list[i][parseInt(x)+1]))
                //         if (parseInt(res.data.list[i][x])+1 != parseInt(res.data.list[i][parseInt(x)+1])) {
                //             // console.log(res.data.list[i][parseInt(x)])
                //             final_obj[i] = temp_group
                //             temp_group = [];
                //         }
                //     }
                // }
                // console.log(final_obj)

                for (let i in res.data.list) {
                    setDropdownBatch(prev => [...prev, i]);
                }
                setDropdownSerial(res.data.list[Object.keys(res.data.list)[0]])
                setBatchSerialList(res.data.list)
    
                setBatchSelect(Object.keys(res.data.list)[0])
                setSerialSelect({
                    to: res.data.list[Object.keys(res.data.list)[0]][0],
                    from: res.data.list[Object.keys(res.data.list)[0]][0]
                })
            } else {
                setDropdownSerial([])
                setBatchSerialList({})
                setSerialSelect([])
            }
        }).catch(res=>{
            setLoading(false)
            console.log(res)
        })
    }

    
    function getFarmList() {
        axiosInstance.get(`${process.env.REACT_APP_API}/core/getFarmList/`).then(res=>{
            let temp_holder = res.data.farm
            temp_holder.splice(0, 0, {id:null, name:'-- Optional --'});

            setFarmList(temp_holder)
            setFarmSelect({
                id:res.data.farm[0].farmid
            })
        }).catch(res=>{
            console.log(res)
        })
    }


    function getGalleryList() {
        axiosInstance.get(`${process.env.REACT_APP_API}/core/getGalleryList/`).then(res=>{
            let temp_holder = res.data.gallery
            temp_holder.splice(0, 0, {id:null, folder:'-- Optional --'});

            setGalleryList(temp_holder)
            if (res.data.gallery.length > 0) {
                setGallerySelect({
                    id:res.data.gallery[0].id
                })
            }
        }).catch(res=>{
            console.log(res)
        })
    }
    function getLibraryList() {
        axiosInstance.get(`${process.env.REACT_APP_API}/durian/getLibraryList/`).then(res=>{
            let temp_holder = res.data.library
            temp_holder.splice(0, 0, {id:null, name:'-- Optional --'});

            setLibraryList(temp_holder)
            setLibrarySelect({
                id:res.data.library[0].id
            })
            if (res.data.library[0].id) {
                loadLibrary()
            }
        }).catch(res=>{
            console.log(res)
        })
    }


    function handleSelectChange(e, type) {
        switch (type){ 
            case 'batch':
                setDropdownSerial(batchSerialList[e.target.value])
                setBatchSelect(e.target.value)
                setSerialSelect({
                    to: batchSerialList[e.target.value][0],
                    from: batchSerialList[e.target.value][0],
                })
                break;
            case 'serial-from':
                setSerialSelect(prev=>({
                    ...prev,
                    from:e.target.value
                }))
                break;
            case 'serial-to':
                setSerialSelect(prev=>({
                    ...prev,
                    to:e.target.value
                }))
                break;
            case 'farm':
                if (e.target.value=='-- Optional --') {
                    setFarmSelect({
                        id: null
                    })
                } else {
                    setFarmSelect({
                        id: e.target.value
                    })
                }
                break;
            case 'gallery':
                if (e.target.value=='-- Optional --') {
                    setGallerySelect({
                        id: null
                    })
                } else {
                    setGallerySelect({
                        id: e.target.value
                    })
                }
                break;
            case 'library':
                if (e.target.value=='-- Optional --') {
                    setLibrarySelect({
                        id: null
                    })
                } else {
                    setLibrarySelect({
                        id: e.target.value
                    })
                }
                break;
            default:
                break;
        }
    }

    function loadLibrary(e) {
        if (e) {
            e.preventDefault()
        }
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/durian/getLibraryIndividual/${librarySelect.id}`).then(res=>{
            setLoading(false)
            setLibraryData(res.data.library[0])
        }).catch(res=>{
            setLoading(false)
            console.log(res)
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        if (serialSelect.to < serialSelect.from) {
            return alert('From serial must be small than To serial')
        }
        console.log(batchSelect,serialSelect, gallerySelect, farmSelect, librarySelect)
        let datetime = new Date()
        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/durian/updateTagBatchData/`, {
            "tag": {
                "libraryid": librarySelect.id, 
                "galleryid": gallerySelect.id, 
                "farmid": farmSelect.id , 
                "batch": batchSelect, 
                "from": serialSelect.from,
                "to": serialSelect.to,
                "last_update": datetime.toISOString(), 
                "used": datetime.toISOString(),
                "coinfo": coinfo
            }
        }).then(res=>{
            setLoading(false)
            console.log(res)
            successToast(res.data.msg)
            return window.location = `/trackntrace/batch-input/serials/${batchSelect}/${serialSelect.from}/${serialSelect.to}`
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }

    function loadGallery(e) {
        e.preventDefault()

        if (!gallerySelect.id) {
            return alert('Please select a Gallery')
        }
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/core/getGalleryImage/${gallerySelect.id}`).then(res=>{
            setLoading(false)
            setImages(res.data.url)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
        })
    }
    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Batch Info Input</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Batch Info Input</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12">

                            {images.length>0 && (
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4 className="card-title">Images</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            {images.map((image,index) => (
                                                    <div className="col-sm-2 icheck-primary d-inline" style={{display:'flex', flexFlow:'wrap', position:'relative'}} key={index}>
                                                        <a href={image} data-toggle="lightbox" data-title="Image" data-farm="farm" >
                                                            <img src={image} className="img-fluid mb-2" alt="Farmsens-Image"/>
                                                        </a>
                                                    </div>
                                                ))
                                            }


                                        </div>
                                    </div>

                                </div>
                            )}
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Batch Info</h3>
                                </div>
                                <form>
                                    <div className="card-body">

                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">Batch No</label>
                                                <div className="col-sm-10">
                                                    <select className="form-control" value={batchSelect} onChange={e => handleSelectChange(e,'batch')}>
                                                        {dropdownBatch.length > 0 ? dropdownBatch.map(i=>(
                                                            <option value={i}>{i}</option>
                                                        )) : (
                                                            <option value="null">No Batch Available</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-md-2 col-xs-2 col-form-label">Serial</label>
                                                <div className="col-md-3 col-xs-4">
                                                    <select className="form-control" value={serialSelect.from} onChange={e => handleSelectChange(e, 'serial-from')}>
                                                        {dropwdownSerial.length > 0 ? dropwdownSerial.map(i=>(
                                                            <option value={i}>{i}</option>
                                                        )) : (
                                                            <option value="null">No Serial Available</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="col-md-1 col-xs-1"><p>TO</p></div>
                                                <div className="col-md-3 col-xs-4">
                                                    <select className="form-control" value={serialSelect.to} onChange={e => handleSelectChange(e, 'serial-to')}>
                                                        {dropwdownSerial.length > 0 ? dropwdownSerial.map(i=>(
                                                            <option value={i}>{i}</option>
                                                        )) : (
                                                            <option value="null">No Serial Available</option>
                                                        )}
                                                    </select>
                                                </div>

                                                <label className="col-md-2 col-xs-2 col-form-label">No. of Serial</label>
                                                <div className="col-md-1 col-xs-10">
                                                    <input value={serialCount} className="form-control" disabled/>
                                                </div>
                                            </div>


                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">General Images</label>
                                                <div className="col-sm-8">
                                                    <select className="form-control" value={gallerySelect.id||''}  onChange={e => handleSelectChange(e, 'gallery')}>
                                                        {galleryList.map(i=>(
                                                            <option value={i.id}>{i.folder}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-sm-2">
                                                    <button className="btn w-100 btn-primary" onClick={e=>loadGallery(e)}>Load</button>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">Farm</label>
                                                <div className="col-sm-10">
                                                    <select className="form-control" value={farmSelect.id||''}  onChange={e => handleSelectChange(e, 'farm')}>
                                                        {farmList.map(i=>(
                                                            <option value={i.farmid}>{i.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>



                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">Include Company Info</label>
                                                <div className="col-sm-10">
                                                    <div className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox" id="include-company-info-checkbox" checked={coinfo} onChange={() => setCoinfo(!coinfo)}/>
                                                        <label htmlFor="include-company-info-checkbox" className="custom-control-label"></label>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr/>

                                            {/* BASIC INFO */}
                                            
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">Fruit Info</label>
                                                <div className="col-sm-8">
                                                    <select className="form-control" value={librarySelect.id||''} onChange={e => handleSelectChange(e,'library')}>
                                                        {libraryList.map(i=>(
                                                            <option value={i.id}>{i.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-sm-2">
                                                    <button className="btn w-100 btn-primary" onClick={(e)=>{loadLibrary(e)}}>Load</button>
                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="col-sm-6 p-3">
                                                    <h5><b>Basic Info</b></h5>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Breed</label>
                                                        <div className="col-sm-10">
                                                            <input value={libraryData.breed||''} onChange={(e)=>{}}  type="text" className="form-control" id="breed" placeholder="Optional" disabled/>
                                                        </div>
                                                    </div>


                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Other Name</label>
                                                        <div className="col-sm-10">
                                                            <input value={libraryData.breed_other_name||''} onChange={(e)=>{}}  type="text" className="form-control" id="breed_other_name" placeholder="Optional" disabled/>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Grade</label>
                                                        <div className="col-sm-10">
                                                            <input value={libraryData.grade||''} onChange={(e)=>{}}  type="text" className="form-control" id="grade" placeholder="Optional" disabled/>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Origin</label>
                                                        <div className="col-sm-10">
                                                            <input value={libraryData.origin||''} onChange={(e)=>{}}  type="text" className="form-control" id="origin" placeholder="Optional" disabled/>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Altitude</label>
                                                        <div className="col-sm-10">
                                                            <input value={libraryData.altitude||''} onChange={(e)=>{}}  type="text" className="form-control" id="altitude" placeholder="Optional" disabled/>
                                                        </div>
                                                    </div>

                                                </div>



                                                {/* FRUIT INFO */}
                                                <div className="col-sm-6 p-3">
                                                    <h5><b>Fruit Info</b></h5>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Seed Size</label>
                                                        <div className="col-sm-10">
                                                            <input value={libraryData.seed_size||''} onChange={(e)=>{}}  type="text" className="form-control" id="seed_size" placeholder="Optional" disabled/>
                                                        </div>
                                                    </div>


                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Shape</label>
                                                        <div className="col-sm-10">
                                                            <input value={libraryData.shape||''} onChange={(e)=>{}}  type="text" className="form-control" id="shape" placeholder="Optional" disabled/>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Tree Age</label>
                                                        <div className="col-sm-10">
                                                            <input value={libraryData.tree_age||''} onChange={(e)=>{}}  type="text" className="form-control" id="tree_age" placeholder="Optional" disabled/>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Date Harvest</label>
                                                        <div className="col-sm-10">
                                                            <input value={libraryData.harvest||''} onChange={(e)=>{}} className="form-control w-100 " type="date" id="harvest" name="harvest" disabled/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                            {/* FLAVOUR CHARACTERISTIC */}
                                            <div className="row">
                                                <div className="col-sm-6 p-3">
                                                    <h5><b>Flavour Characteristic</b></h5>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Taste</label>
                                                        <div className="col-sm-10">
                                                            <input value={libraryData.taste||''} onChange={(e)=>{}}  type="text" className="form-control" id="taste" placeholder="Optional" disabled/>
                                                        </div>
                                                    </div>


                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Color</label>
                                                        <div className="col-sm-10">
                                                            <input value={libraryData.color||''} onChange={(e)=>{}}  type="text" className="form-control" id="color" placeholder="Optional" disabled/>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Texture</label>
                                                        <div className="col-sm-10">
                                                            <input value={libraryData.texture||''} onChange={(e)=>{}}  type="text" className="form-control" id="texture" placeholder="Optional" disabled/>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Aroma</label>
                                                        <div className="col-sm-10">
                                                            <input value={libraryData.aroma||''} onChange={(e)=>{}}  type="text" className="form-control" id="aroma" placeholder="Optional" disabled/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                    </div>

                                    <div className="card-footer">
                                    <button className="btn btn-primary"  onClick={handleSubmit}>Submit</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                
                
                
                
                </div>
            </section>


            
        </div>
    )
}
