import React, {useState, useEffect} from 'react'
import { ImageCropper } from '../../../components/image-cropper/ImageCropper';
import  axiosInstance  from '../../../utils/api'
import { successToast, errorToast } from '../../../utils/toast';

export default function AddImageModal({data, show, setShow}) {
    const [images, setImages] = useState([]) 
    const [submitting, setSubmitting] = useState(false)
    const [imageToCrop, setImageToCrop] = useState()

    useEffect(() => {
        setImageToCrop()
    }, [show])
    

    const submitImage = () => {
        let bodyFormData = new FormData();
        bodyFormData.append('inventory', JSON.stringify({"itemid": data.itemid}));

        let counter = 1;
        for (let i in images){
            let file = new File([ images[i].image_file ],  new Date().toISOString() + "_" + counter, {type: "image/jpg", lastModified: Date.now()});
            if (counter < 10) {
                bodyFormData.append('0'+counter, file);
            } else {
                bodyFormData.append(counter, file);
            }
            counter ++
        }
        setSubmitting(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/core/uploadImage/`, bodyFormData)
        .then(res=>{
            setSubmitting(false)
            successToast(res.data.message)

            return window.location.reload()
        }).catch(res=>{
            setSubmitting(false)
            errorToast('Server Error')

            return window.location.reload()
        })

    }

    function cropImage(e) {
        if (e.target.files.length < 1) {
            return null
        }
        setImageToCrop({
            image_blob: URL.createObjectURL(e.target.files[0]),
            image_file: e.target.files[0]
        })
    }

    return (
        <div className="modal show" style={show ? {display: 'block', background: 'rgba(0, 0, 0, 0.5)', overflowY: 'auto'} : {display: 'none'}} aria-modal="true" role="dialog" data-backdrop="true">
            <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">
                        { imageToCrop ? ('Crop Image') : ('Add Image Preview') }
                    </h4>
                    <button type="button" className="close" onClick={()=>{setShow(false)}}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                
                <div className="modal-body">
                    {imageToCrop ? (
                        <div className="row justify-content-center">
                            <ImageCropper imageToCrop={imageToCrop} orientation="landscape" setCroppedImage={(e) => {
                                setImages(prev => [...prev, {
                                    image_blob: URL.createObjectURL(e),
                                    image_file: e
                                }]);
                                setImageToCrop()
                            }}
                            cancel={()=>{
                                setImageToCrop()
                            }}/>
                        </div>
                    ) : (
                        <div className="row">
                            {images.length > 0 ? (
                                images.map((image) => (
                                    <div className="col-md-5 col-sm-1 text-center bg-black m-2" >
                                        <div style={{position:'absolute'}}>
                                            <button onClick={()=>{
                                                setImages( prev => 
                                                    prev.filter( img => {
                                                        return img.image_blob != image.image_blob
                                                    })
                                                )
                                            }}>x</button>
                                        </div>

                                        <a href={image.image_blob} data-toggle="lightbox" data-title="Image" data-farm="farm" >
                                            <img src={image.image_blob} className="img-fluid" style={{height:'200px'}} alt="Farmsens-Image"/>
                                        </a>
                                    </div>
                                ))
                            ) : (
                                <div>
                                    <p>No Image Available</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>


                {!imageToCrop && (
                <div className="modal-footer justify-content-between">
                    <button className="btn btn-default m-2">
                        <label htmlFor="img" style={{margin:0}}>
                            Add Image
                            <input
                                accept="image/*"
                                id="img"
                                // multiple
                                name="img"
                                type="file"
                                capture
                                onChange={cropImage}
                                hidden
                            />
                        </label>
                    </button>
                    <button type="button" className="btn btn-primary" onClick={submitImage} disabled={ images.length == 0 ? true : (submitting ? true : false)}>Upload</button>
                </div>
                )}
            </div>
            </div>
        </div>
    )
}
