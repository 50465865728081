import React, {useEffect} from 'react'
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export const ConsumptionHistory = ({historyData, setStartDate, setEndDate, startDate, endDate, category, categoryOptions, setCategory, loadHistory}) => {
    
    useEffect(() => {
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        let root = am5.Root.new("chartdiv-history");


        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
        am5themes_Animated.new(root)
        ]);


        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout
        }))


        // Add scrollbar
        // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
        chart.set("scrollbarX", am5.Scrollbar.new(root, {
            orientation: "horizontal"
        }))
        
        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        var legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
        }))


        // var data = [
        //     {
        //         category: "YaraTera Ferticare 3-10-30+8MgO",
        //         purchase_quantity: 23.5,
        //         purchase_consumption: 18.1
        //     }, {
        //         category: "YaraTera 7-9-37",
        //         purchase_quantity: 26.2,
        //         purchase_consumption: 22.8
        //     },
        // ];
        let data = []
        let category_list = []
        for (let i of historyData.purchase) {
            data.push({
                category: i.item__name,
                purchase_quantity: i.quantity,
            })
            category_list.push(i.item__name)
        }
        for (let i of historyData.consumption) {
            if (!category_list.includes(i.item__name)) {
                data.push({
                    category: i.item__name,
                    consumption_quantity: i.quantity,
                })
            } else {
                for (let x of data) {
                    if (x.category == i.item__name) {
                        x.consumption_quantity = i.quantity
                    }
                }
            }
        }

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "category",
            renderer: am5xy.AxisRendererY.new(root, {
                inversed: true,
                cellStartLocation: 0.1,
                cellEndLocation: 0.9
            })
        }));
        // Y-Axis font size
        let yRenderer = yAxis.get("renderer");
        yRenderer.labels.template.setAll({
            fontSize: 12,
            oversizedBehavior: "wrap",
            maxWidth:60
        });

        yAxis.data.setAll(data);

        var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        min: 0
        }));
        // X-Axis font size
        let xRenderer = xAxis.get("renderer");
        xRenderer.labels.template.setAll({
            fontSize: 12
        });


        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function createSeries(field, name) {
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: field,
                categoryYField: "category",
                tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"
                })
            }));

            series.columns.template.setAll({
                height: am5.p100
            });


            series.bullets.push(function() {
                return am5.Bullet.new(root, {
                locationX: 1,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    centerY: am5.p50,
                    text: "{valueX}",
                    populateText: true,
                    fontSize:12
                })
                });
            });

            series.bullets.push(function() {
                return am5.Bullet.new(root, {
                locationX: 1,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    centerX: am5.p100,
                    centerY: am5.p50,
                    text: "{name}",
                    fill: am5.color(0xffffff),
                    populateText: true,
                    fontSize:12
                })
                });
            });

            series.data.setAll(data);
            series.appear();

            return series;
        }

        createSeries("purchase_quantity", "Purchase");
        createSeries("consumption_quantity", "Consumption");


        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        var legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
        }));

        legend.data.setAll(chart.series.values)
        legend.labels.template.setAll({
            fontSize: 12
        })
        legend.markers.template.setAll({
            width: 12,
            height: 12
        })


        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "zoomY"
        }));
        cursor.lineY.set("forceHidden", true);
        cursor.lineX.set("forceHidden", true);


        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);

    
        return () => {
            root.dispose()
        }
    }, [historyData])

    return (
        <>
        <div className="row my-2">
            <div className="col-6 col-md-3 align-self-center">
                Start Date:
            </div>
            <div className="col-6 col-md-3">
                <input value={startDate} onChange={(e)=> {
                    setStartDate(e.target.value)
                }}  className="form-control w-100 " type="date" />
            </div>
            <div className="col-6 col-md-3 align-self-center">
                End Date:
            </div>
            <div className="col-6 col-md-3">
                <input value={endDate} onChange={(e)=>setEndDate(e.target.value)}  className="form-control w-100 " type="date"/>
            </div>
        </div>
        <div className="row my-2">
            <div className="col-6 col-md-3 align-self-center mb-3">
                Category:
            </div>
            <div className="col-6 col-md-3">
                <select className="form-control" value={category} onChange={e => {setCategory(e.target.value)}}>
                    {categoryOptions.map(c=>{
                        return (
                            <option value={c.name}>{c.name}</option>
                        )
                    })}
                </select>
            </div>
            <div className="col-12 col-md-6">
                <button type="button" className="btn btn-primary w-100" onClick={loadHistory}>Load</button>
            </div>
        </div>
        <div className="row">
            <div id="chartdiv-history" style={{ width: "100%", minHeight: "400px" }}></div>
        </div>
        </>
    )
}
