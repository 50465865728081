import React, {useEffect,useState} from 'react'
import axios from 'axios'
import { config } from '../../../utils/config';
import { useLocation,useParams } from 'react-router-dom'
import { successToast, errorToast } from '../../../utils/toast';
import  axiosInstance  from '../../../utils/api'

export default function TreesAddEdit() {
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('add')
    let { treeId } = useParams();
    const [inputs, setInputs] = useState({
        breed:'',
        ref:'',
        serial:'',
        year: '',
        height: '',
        latitude: '',
        longitude: '',
        farm:''
    })
    const [images, setImages] = useState([])

    useEffect(() => {
        if (treeId) {
            setType('edit')
            getTreeData(treeId)
        } else {
            setType('add')
        }
    }, [])
    
    function getTreeData(t) {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/tree/show/${t}/`)
        .then(res=>{
            setLoading(false)
            setInputs(res.data)
            setImages(res.data.image)
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })
    }

    function handleInput(e, field) {
        setInputs({
            ...inputs,
            [field]: e.target.value
        })
    }
    function handleSubmit() {
        if (inputs.breed == '' || typeof inputs.breed == 'undefined' ) {
            return errorToast('Please fill in breed')
        }
        if (type == 'edit') {
            delete inputs['updated_by']; 
            setLoading(true)
            axiosInstance.post(`${process.env.REACT_APP_API}/tree/update/${treeId}`, inputs).then(res=>{
                setLoading(false)
                successToast('Succeesfully Updated')
                getTreeData(treeId)
            }).catch(res=>{
                setLoading(false)
                errorToast('Server Error')
            })
        } else if (type=='add') {
            let bodyFormData = new FormData();
            bodyFormData.append('', JSON.stringify({
                ...inputs,
                farm:""
            }));
            console.log(JSON.stringify({
                ...inputs,
                farm:""
            }))
            setLoading(true)
            axiosInstance.post(`${process.env.REACT_APP_API}/tree/create`, bodyFormData).then(res=>{
                setLoading(false)
                console.log(res)
                successToast('Succeesfully Created')
                return window.location = '/trees/'
            }).catch(res=>{
                setLoading(false)
                errorToast('Server Error')
            })
        }

    }
    
    function uploadImage(e) {
        if (e.target.files.length < 1) {
            return null
        }
        // console.log(e.target.files)
        let bodyFormData = new FormData();
        let counter = 1;
        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                let file = new File([e.target.files[i]], new Date().toISOString() + "_" + counter, {type: "image/jpg", lastModified: Date.now()});
                bodyFormData.append(counter, file);
            }
            counter ++
        }

        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/tree/update/image/${treeId}`, bodyFormData)
        .then(res=>{
            setLoading(false)
            console.log(res)
            successToast('Successfully uploaded image')
            getTreeData(treeId)
        }).catch(res=>{
            setLoading(false)
            // console.log(res)
            errorToast('Server Error')
        })

        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                URL.revokeObjectURL(e.target.files[i])
            }
        }


    }

    function deleteImage(imageName) {
        setLoading(true)
        axiosInstance.delete(`${process.env.REACT_APP_API}/tree/delete/image/${treeId}`,  {
            "data": {
                "delete": [imageName]
            }
        }).then(res=>{
            setLoading(false)
            successToast('Successfully delete image')
            getTreeData(treeId)
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })
    }


    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Tree Detail</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href={`/`}>Home</a></li>
                            <li className="breadcrumb-item"><a href={`/trees`}>Trees</a></li>
                            <li className="breadcrumb-item active">Tree Detail</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">

                    {type=='edit' && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">Tree Image</h3>
                                    </div>

                                    <div className="card-body"> 
                                        <div className="filter-container p-0 row">
                                            {images.length>0 && images.map(img=>{
                                                return (
                                                    <>
                                                    <div className="filtr-item col-sm-2">
                                                        <span style={{position: 'absolute', backgroundColor: 'white', padding: '5px' }} onClick={()=>{deleteImage(img)}}>x</span>
                                                        <a href={img} data-toggle="lightbox">
                                                            <img src={img} className="img-fluid mb-2" alt="farmsens-image"/>
                                                        </a>
                                                    </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button className="btn btn-primary mx-2">
                                            <label htmlFor="img" style={{margin:0}}>
                                                Add Image
                                                <input
                                                    id="img"
                                                    multiple
                                                    name="img"
                                                    type="file"
                                                    accept="image/*;capture=camera"
                                                    onChange={uploadImage}
                                                    hidden
                                                />
                                            </label>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        
                        <div className="col-md-12">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">Tree Info</h3>
                                </div>

                                <form className="form-horizontal">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Serial</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.serial ||''} onChange={(e)=>handleInput(e,'serial')} type="text" className="form-control" id="library-name" placeholder="Required"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Breed</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.breed ||''} onChange={(e)=>handleInput(e,'breed')} type="text" className="form-control" id="library-name" placeholder="Required"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Ref</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.ref ||''} onChange={(e)=>handleInput(e,'ref')} type="text" className="form-control" id="library-name" placeholder="Required"/>
                                            </div>
                                        </div>
                                        
                                    {type=='edit' && (
                                        <>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Year</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.year ||''} onChange={(e)=>handleInput(e,'year')} type="text" className="form-control" id="library-name"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Height</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.height ||''} onChange={(e)=>handleInput(e,'height')} type="text" className="form-control" id="library-name"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Diameter</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.diameter ||''} onChange={(e)=>handleInput(e,'diameter')} type="text" className="form-control" id="library-name"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Latitude</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.latitude ||''} onChange={(e)=>handleInput(e,'latitude')} type="text" className="form-control" id="library-name"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Longitude</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.longitude ||''} onChange={(e)=>handleInput(e,'longitude')} type="text" className="form-control" id="library-name"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Remark</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.remark ||''} onChange={(e)=>handleInput(e,'remark')} type="text" className="form-control" id="library-name"/>
                                            </div>
                                        </div>
                                        </>
                                    )}
                                    </div>
                                </form>
                            </div>
                        </div>

                        
                        

                    </div>

                    <div className="row p-3">
                        <button type="submit" className="btn btn-primary btn-md" onClick={handleSubmit}>{type=='add'?'Create':'Update'}</button>
                    </div>
                </div>
            </section>

            
        </div>
    )
}
