import React, {useEffect, useState} from 'react'

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import { Map, Marker } from "pigeon-maps"
import { errorToast } from '../../../../../utils/toast';

// let cities = [
// 	{
// 	  title: "Brussels",
// 	  latitude: 50.8371,
// 	  longitude: 4.3676
// 	},
// 	{
// 	  title: "Copenhagen",
// 	  latitude: 55.6763,
// 	  longitude: 12.5681
// 	},
// ];

let counter = 0
const LowStockMapChart = ({mapData}) => {
	const [bulletData, setBulletData] = useState({})

	useEffect(() => {
        if (mapData[counter]) {
            setBulletData(mapData[counter])
        }
		RenderMap()
	}, [mapData])

	function RenderMap() {
		return (
			<Map height={300} defaultCenter={mapData[counter] ? [ parseFloat(mapData[counter].location__latitude),  parseFloat(mapData[counter].location__longitude)] : []} defaultZoom={11}>
				{ "location__latitude" in bulletData && (
					<Marker width={50} anchor={[ parseFloat(bulletData.location__latitude),  parseFloat(bulletData.location__longitude)]} />
				)}
			</Map>
		)
	}
	
	function handleShowData(direction) {
		if (mapData.length == 0) return errorToast('No Data')

		if (direction == 'asc') {
			counter++
			if (counter >= mapData.length) counter = 0
			setBulletData(mapData[counter])
		} else if (direction == 'desc') {
			counter--
			if (counter < 0) counter = mapData.length - 1
			setBulletData(mapData[counter])
		}
	}
    
    return (
		<div className='row'>
			<div className="col-12">
				<RenderMap/>
			</div>
			<div className="col-12 mt-3">
				<div style={{display:'flex', justifyContent:'space-between', margin: '10px 0'}}>
					<button className="btn btn-sm btn-primary" onClick={()=> handleShowData('desc')}>Previous</button>
					<button className="btn btn-sm btn-primary" onClick={()=> handleShowData('asc')}>Next</button>
				</div>
				<table className="table table-hover table-striped">
					<tbody>
						<tr>
							<th>Name</th>
							<td>{bulletData.name}</td>
						</tr>
						<tr>
							<th>Ref</th>
							<td>{bulletData.ref}</td>
						</tr>
						<tr>
						<th>Location</th>
						<td>{bulletData.location__name}</td>
						</tr>
						<tr>
						<th>Current Stock</th>
						<td>{bulletData.current_stock}</td>
						</tr>
						<tr>
						<th>Reorder Level</th>
						<td>{bulletData.reorder_level}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
    )
}
export default LowStockMapChart