import React, { useEffect, useState } from 'react'
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated } = useSelector((state)=> state.auth)

    return (
        <Route
        {...rest}
        render={props => {
            if (isAuthenticated) {
                return <Component {...props} />;
            } else {
                return (
                    <Redirect
                    to={{
                        pathname: "/login",
                        state: {
                            from: props.location
                        }
                    }}
                    />
                );
            }
        }}
        />
    );
};
