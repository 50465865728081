import React, {useMemo, useState} from 'react'
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/helpers';
import DataTable from 'react-data-table-component';

export const AdjustmentTable = ({tableData, inventory, handleDelete}) => {
    
    const columns = [
        {
            name: 'Item',
            selector: row => row.item,
            cell: (row, index, column, id) => {
                return <Link to={`/inventory/${inventory}/${row.id}/edit`}>{row.item ? row.item : '-'}</Link>
            },
            sortable: true,
        },
        {
            name: 'New Quantity',
            selector: row => row.new_quantity,
        },
        {
            name: 'Reason',
            selector: row => row.reason,
        },
        {
            name: 'Ref',
            selector: row => row.ref,
        },
        {
            name: 'Datetime',
            selector: row => row.datetime,
            format: row => formatDate(row.datetime),
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row)=> {
                return (
                    <button className="btn btn-tool" onClick={()=> {
                        if (window.confirm('Are you sure you want to delete?')) {
                            handleDelete(row.id)
                        }
                    }}>
                        <i className="fas fa-trash"></i>
                    </button>
                )
            }
        },
    ];

	const [filterText, setFilterText] = useState('');
    const filteredItems = tableData.filter(
		item => item.item && item.item.toLowerCase().includes(filterText.toLowerCase())
	);
    const subHeaderComponentMemo = useMemo(() => {

		return (
            <>
            <input value={filterText} onChange={e=>{setFilterText(e.target.value)}} placeholder="Search Item"/>
            </>
		);
	}, [filterText]);

    return (
        <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationComponentOptions={{
                noRowsPerPage:true
            }}
            subHeader
			subHeaderComponent={subHeaderComponentMemo}
        />
    )
}
