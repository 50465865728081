
import React, {useEffect, useState} from 'react'
import './App.css';
import TopNav from './components/TopNav';
import SideNav from './components/SideNav';
import Footer from './components/Footer';
import Dashboard from './pages/Dashboard';
import Gallery from './pages/media-gallery/Gallery';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import GalleryDetail from './pages/media-gallery/pages/GalleryDetail';
import GalleryAddEdit from './pages/media-gallery/pages/GalleryAddEdit';
import Error404 from './pages/errors/Error404';
import Library from './pages/library/Library';
import LibraryAddEdit from './pages/library/pages/LibraryAddEdit';
import Farm from './pages/farm/Farm';
import FarmAddEdit from './pages/farm/pages/FarmAddEdit';
import TrackNTrace from './pages/trackntrace/TrackNTrace';
import Tag from './pages/tag/Tag';
import TagAddEdit from './pages/tag/pages/TagAddEdit';
import BatchInput from './pages/trackntrace/pages/BatchInput';
import BatchInputSerial from './pages/trackntrace/pages/BatchInputSerial';
import BatchInputSerialAddEdit from './pages/trackntrace/pages/BatchInputSerialAddEdit';
import Login from './pages/auth/Login';
import { ProtectedRoute } from './routes/ProtectedRoute';
import Feedback from './pages/feedback/Feedback';
import FeedbackRecord from './pages/feedback/pages/FeedbackRecord';
import FeedbackDetail from './pages/feedback/pages/FeedbackDetail';
import FeedbackVisual from './pages/feedback/pages/FeedbackVisual';
import Trees from './pages/trees/Trees';
import Users from './pages/users/Users';
import Operations from './pages/operations/Operations';
import ScanQr from './pages/operations/ScanQr';
import SelectFarms from './pages/operations/SelectFarms';
import SelectTrees from './pages/operations/SelectTrees';
import Activity from './pages/operations/Activity';
import Inspection from './pages/operations/Inspection';
import Application from './pages/operations/Application';
import Form from './pages/operations/Form';
import TreesAddEdit from './pages/trees/pages/TreesAddEdit';
import { TreesView } from './pages/trees/pages/TreesView';
import { Inventory } from './pages/inventory/Inventory';
import { InventoryAddEdit } from './pages/inventory/InventoryAddEdit';
import { InventoryDashboard } from './pages/inventory/pages/dashboard/InventoryDashboard';
import { InventoryVisualization } from './pages/inventory/pages/visualization/InventoryVisualization';
import { TreeDashboard } from './pages/tree-dashboard/TreeDashboard';
import InventoryMore from './pages/inventory/pages/more-page/InventoryMore';

function App() {


    const [user, setUser] = useState({
        username: '',
        company: '',
        module: []
    })
	
	let userData = localStorage.getItem('user:data')
    useEffect(() => {
        if (JSON.parse(userData)) {
            setUser({
                username: JSON.parse(userData).username,
                company: JSON.parse(userData).company,
                module: JSON.parse(userData).module
            })
        }
    }, [userData])

	return (
		<>
		<Router>
            <Switch>
                <Route exact path="/login">
                    <Login />
                </Route>

                <div className="App hold-transition sidebar-mini layout-fixed">
                    <div className="wrapper">
                        <TopNav/>
                        <SideNav/>

                        <Switch>

                            <ProtectedRoute exact path="/" component={Dashboard} />


                            {/* GALLERY  */}
                            {user.module.includes("fruit") && [
                            <ProtectedRoute exact path="/gallery" component={Gallery} />,
                            <ProtectedRoute exact path={`/gallery/create`} component={GalleryAddEdit} />,
                            <ProtectedRoute exact path={`/gallery/:folderId/edit`} component={GalleryAddEdit} />,
                            <ProtectedRoute exact path={`/gallery/:folderId`} component={GalleryDetail} />,
                            ]}

                            {/* LIBRARY  */}
                            {user.module.includes("fruit") && [
                            <ProtectedRoute exact path={`/library/`} component={Library} />,
                            <ProtectedRoute exact path={`/library/create`} component={LibraryAddEdit} />,
                            <ProtectedRoute exact path={`/library/:libraryId/edit`} component={LibraryAddEdit} />,
                            ]}

                            {/* FARM  */}
                            <ProtectedRoute exact path={`/farm/`} component={Farm} />
                            <ProtectedRoute exact path={`/farm/create`} component={FarmAddEdit} />
                            <ProtectedRoute exact path={`/farm/:farmId/edit`} component={FarmAddEdit} />

                            
                            {/* TREES  */}
                            {/* <ProtectedRoute {...props}  path={`/trees/:treeId/summary`} component={TreesSummary} /> */}
                            {user.module.includes("tree") && [
                            <ProtectedRoute exact path={`/trees/dashboard/`} component={TreeDashboard} />,
                            <ProtectedRoute exact path={`/trees/`} component={Trees} />,
                            <ProtectedRoute path={`/trees/create`} component={TreesAddEdit} />,
                            <ProtectedRoute path={`/trees/:treeId/edit`} component={TreesAddEdit} />,
                            <ProtectedRoute path={`/trees/:treeId/view`} component={TreesView} />,
                            ]}

                            
                            {/* USERS  */}
                            <ProtectedRoute exact path={`/users/`} component={Users} />

                            {/* OPERATIONS  */}
                            {user.module.includes("tree") && [
                            <ProtectedRoute exact path={`/operations/`} component={Operations} />,
                            <ProtectedRoute exact path={`/operations/scan-qr/`} component={ScanQr} />,
                            <ProtectedRoute exact path={`/operations/:treeFarm(tree|farm)/:treeId/activity/`} component={Activity} />,
                            <ProtectedRoute exact path={`/operations/:treeFarm(tree|farm)/:treeId/inspection/`} component={Inspection} />,
                            <ProtectedRoute exact path={`/operations/:treeFarm(tree|farm)/:treeId/application/`} component={Application} />,
                            <ProtectedRoute exact path={`/operations/:treeFarm(tree|farm)/:id/:inspectionApplication(inspection|application)/:type`} component={Form} />,

                            <ProtectedRoute path={`/operations/select-trees/`} component={SelectTrees} />,
                            <ProtectedRoute path={`/operations/select-farms/`} component={SelectFarms} />,
                            
                            ]}


                            {/* TRACK & TRACE  */}
                            {user.module.includes("fruit") && [
                            <ProtectedRoute exact path={`/trackntrace/`} component={TrackNTrace} />,
                            <ProtectedRoute exact path={`/trackntrace/batch-input`} component={BatchInput} />,
                            <ProtectedRoute exact path={`/trackntrace/batch-input/serials/:batch/:serialFrom/:serialTo`} component={BatchInputSerial} />,
                            <ProtectedRoute exact path={`/trackntrace/batch-input/serials/:batch/:serialFrom/:serialTo/edit/:tagId`} component={BatchInputSerialAddEdit} />,
                            ]}


                            {/* TAG  */}
                            {user.module.includes("fruit") && [
                            <ProtectedRoute exact path={`/tag/`} component={Tag} />,
                            <ProtectedRoute exact path={`/tag/:tagId/:operation(edit|view)`} component={TagAddEdit} />,
                            ]}


                            {/* FEEDBACK  */}
                            {user.module.includes("fruit") && [
                            <ProtectedRoute exact path={`/feedback/`} component={Feedback} />,
                            <ProtectedRoute exact path={`/feedback-records/`} component={FeedbackRecord} />,
                            <ProtectedRoute exact path={`/feedback-records/:feedbackId/view`} component={FeedbackDetail} />,
                            <ProtectedRoute exact path={`/feedback-visuals/`} component={FeedbackVisual} />,
                            ]}

                            {/* INVENTORY */}
                            {user.module.includes("inventory") && [
                            <ProtectedRoute exact path={`/inventory/more`} component={InventoryMore} />,
                            <ProtectedRoute exact path={`/inventory/dashboard`} component={InventoryDashboard} />,
                            <ProtectedRoute exact path={`/inventory/visualization`} component={InventoryVisualization} />,
                            <ProtectedRoute exact path={`/inventory/:inventory`} component={Inventory} />,
                            <ProtectedRoute exact path={`/inventory/:inventory/:inventoryId/edit`} component={InventoryAddEdit} />,
                            <ProtectedRoute exact path={`/inventory/:inventory/create`} component={InventoryAddEdit} />,
                            ]}


                        <Route path="*">
							<Error404/>
						</Route>
                            
                        </Switch>

                        <Footer/>
                    </div>
                </div>

            </Switch>
		</Router>

		</>
	);
}

export default App;
