import React, {useMemo, useState} from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { formatDateTimeDisplay } from '../../../utils/helpers';

export default function UsedTagTable({tags}) {
    
    const columns = [
        {
            name: 'Batch',
            selector: row => row.batch,
            sortable: true,
        },
        {
            name: 'Serial',
            selector: row => row.serial,
            cell: (row, index, column, id) => {
                return <Link to={`/tag/${row.tagid}/edit`}>{row.serial ? row.serial : '-'}</Link>
            },
            sortable: true,
        },
        {
            name: 'Breed',
            selector: row => row.breed,
            sortable: true,
        },
        {
            name: 'Grade',
            selector: row => row.grade,
            sortable: true,
        },
        {
            name: 'Origin',
            selector: row => row.origin,
            sortable: true,
            wrap:true
        },
        {
            name: 'Feedback',
            selector: row => row.feedback,
            sortable: true,
            cell: (row, index, column, id) => {
                if (row.feedback) {
                    return  <i class="fas fa-check-circle"></i>
                }
            },
        },
        {
            name: 'Datetime',
            selector: row => formatDateTimeDisplay(row.used),
            sortable: true,
            wrap:true
        },
    ];

	const [filterText, setFilterText] = useState('');
    const filteredItems = tags.filter(
		item => {
            return (item.serial && item.serial.toLowerCase().includes(filterText.toLowerCase())) 
            || (item.batch && item.batch.toLowerCase().includes(filterText.toLowerCase()))
            || (item.breed && item.breed.toLowerCase().includes(filterText.toLowerCase()))
            || (item.origin && item.origin.toLowerCase().includes(filterText.toLowerCase()))
            || (item.used && item.used.toLowerCase().includes(filterText.toLowerCase()))
        }
	)

    const subHeaderComponentMemo = useMemo(() => {
		return (
            <>
            <input value={filterText} onChange={e=>{setFilterText(e.target.value)}} placeholder="Search Tag"/>
            </>
		);
	}, [filterText]);

    return (
        <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationComponentOptions={{
                noRowsPerPage:true
            }}
            subHeader
			subHeaderComponent={subHeaderComponentMemo}
        />
    )
}
