import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {config} from '../../utils/config';
import { errorToast, successToast } from '../../utils/toast';
import  axiosInstance  from '../../utils/api'



export default function Library() {

    const [libraries, setLibraries] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getList()
    }, [])

    function getList() {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/durian/getLibraryList/`)
        .then( res => {
            setLoading(false)
            console.log("🚀 ~ file: Library.js ~ line 7 ~ getList ~ getList", res.data.library)
            setLibraries(res.data.library)
        }).catch(res=> {
            setLoading(false)
            console.log("🚀 ~ file: Library.js ~ line 9 ~ getList ~ getList", res)
            errorToast('Server Error')
        })
    }

    function deleteLibrary(lib) {
        console.log(lib)

        setLoading(true)
        axiosInstance.post('https://farmsensapi.cloudatik.com/durian/deleteLibrary/', {
            library: {
                id: lib.id
            }
        }).then(res=>{
            setLoading(false)
            successToast(res.data.message)
            getList()
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
            getList()
        })
    }

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Library</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Library</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"></h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-block btn-default"  onClick={()=>{ return window.location = "/library/create" }}>Create Library</button>
                                    </div>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                            <th>Name</th>
                                            <th>Breed</th>
                                            <th>Origin</th>
                                            <th>Grade</th>
                                            <th>By</th>
                                            <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {libraries.map(lib=> (
                                                <tr>
                                                    <td><a href={`/library/${lib.id}/edit`}>{lib.name}</a></td>
                                                    <td>{lib.breed}</td>
                                                    <td>{lib.origin}</td>
                                                    <td>{lib.grade}</td>
                                                    <td>{lib.username}</td>
                                                    <td>
                                                        {/* <a href="#" className="btn btn-tool">
                                                            <i className="fas fa-pen"></i>
                                                        </a> */}
                                                        <a href="javascript:void(0)" className="btn btn-tool" onClick={()=>{
                                                            if (window.confirm('Are you sure you want to delete library?')) {
                                                                deleteLibrary(lib)
                                                            }
                                                        }}>
                                                            <i className="fas fa-trash"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                {/* <div className="card-footer clearfix">
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                
                
                
                
                </div>
                </section>


            
        </div>
    )
}
