import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import axiosInstance from '../../utils/api';
import { errorToast } from '../../utils/toast';

export default function Application() {
    const [loading, setLoading] = useState(false)
    let { treeId, treeFarm } = useParams();
    const [data, setData] = useState({})
    
    useEffect(() => {
        if (localStorage.getItem('tree:data') && JSON.parse(localStorage.getItem('tree:data'))) {
            setData(JSON.parse(localStorage.getItem('tree:data')))
        }
        if (treeFarm=='tree') {
            setLoading(true)
            axiosInstance.get(`${process.env.REACT_APP_API}/tree/show/${treeId}`).then(res=>{
                setLoading(false)
                localStorage.setItem('tree:data', JSON.stringify({
                    serial: res.data.serial,
                    farm:  res.data.farm,
                    breed:  res.data.breed,
                    ref:  res.data.ref,
                }));
                setData({
                    serial: res.data.serial,
                    farm:  res.data.farm,
                    breed:  res.data.breed,
                    ref:  res.data.ref,
                })
            }).catch(res=> {
                setLoading(false)
                console.log(res)
                errorToast('Invalid ID')
            })
        } else if (treeFarm=='farm') {
            setLoading(true)
            axiosInstance.get(`${process.env.REACT_APP_API}/core/getFarm/${treeId}`).then(res=>{
                console.log(res.data.farm)
                setLoading(false)
                setData({
                    farm:  res.data.farm.name,
                })
            }).catch(res=> {
                setLoading(false)
                console.log(res)
                errorToast('Invalid ID')
            })
        }
    }, [])

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Application</h1>
                    </div>
                        
                    </div>
                </div>
            </div>

            <section className="content">

                <div className="container-fluid">

                    

                    <div className="row">
                        <div className="col-12">
                            <div className="text-center p-3">
                                <h4>Application</h4>
                                <h4>(Aplikasi)</h4><br/>

                                { treeFarm=='tree' ? (
                                    <>
                                    <h5>{data.breed} @ {data.farm}</h5>
                                    <h5>Serial : {data.serial} </h5>
                                    <h5>Ref : {data.ref} </h5>
                                    </>
                                ) : (
                                    <h5>{data.farm}</h5>
                                )}

                                
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-success w-100 col-12 col-md-4" href={`/operations/${treeFarm}/${treeId}/application/fertilizer`}>
                                    Fertilizer
                                    <br/>(Baja)
                                    </a>
                                </div>
                                
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-info w-100 col-12 col-md-4" href={`/operations/${treeFarm}/${treeId}/application/pesticide`}>
                                    Pesticide
                                    <br/>(Racun Perosak)
                                    </a>
                                </div>
                                
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-secondary w-100 col-12 col-md-4" href={`/operations/${treeFarm}/${treeId}/application/fungicide`}>
                                    Fungicide
                                    <br/>(Racun Kulat)
                                    </a>
                                </div>
                                
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-danger w-100 col-12 col-md-4" href={`/operations/${treeFarm}/${treeId}/application/herbicide`}>
                                    Herbicide
                                    <br/>(Racun Rumput)
                                    </a>
                                </div>

                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-primary w-100 col-12 col-md-4" href={`/operations/${treeFarm}/${treeId}/application/treatment`}>
                                    Treatment
                                    <br/>(Rawatan)
                                    </a>
                                </div>

                                
                                <div className="row my-5 px-4 justify-content-center">
                                    <a className="btn btn-warning w-100 col-12 col-md-4" href={`/operations/`}>
                                    Back
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                
                
                
                </div>
            </section>


            
        </div>
    )
}
