import React, {useEffect,useState} from 'react'
import { errorToast, successToast } from '../../utils/toast'
import axiosInstance from '../../utils/api'

export default function SelectFarms() {
    const [loading, setLoading] = useState(false)
    const [farms, setFarms] = useState([])
    
    useEffect(() => {
        getList()
    }, [])

    function getList() {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/core/getFarmList/`).then(res=>{
            setLoading(false)
            setFarms(res.data.farm)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
        
    }

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Select Farms</h1>
                    </div>
                    <div className="col-sm-6">
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">

                <div className="container-fluid">

                    

                <div className="row">
                        <div className="col-12">
                            
                        <div className="card">
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Name</th>
                                                <th>Latitude</th>
                                                <th>Logitude</th>
                                                <th>Address</th>
                                                <th>Size (Acre)</th>
                                                <th>No. of Trees</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {farms.length > 0 ? 
                                            farms.map( (farm, key) => (
                                                <tr>
                                                    <td>{key + 1}</td>
                                                    <td><a href={`/operations/farm/${farm.farmid}/application`}>{farm.name}</a></td>
                                                    <td>{farm.latitude}</td>
                                                    <td>{farm.longitude}</td>
                                                    <td>{farm.address}</td>
                                                    <td>{farm.size}</td>
                                                    <td>{farm.no_tree}</td>
                                                </tr>
                                            ))
                                            : (
                                            <tr>
                                                <td>No Data</td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className=" col-12 col-md-3">
                                    <a className="btn btn-warning w-100" href={`/operations`}>
                                    Back
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </section>


            
        </div>
    )
}
