import React, {useMemo, useState} from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

export function ItemTable({tableData, inventory, handleDelete}) {
    
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            cell: (row, index, column, id) => {
                return <Link to={`/inventory/${inventory}/${row.itemid}/edit`}>{row.name ? row.name : '-'}</Link>
            },
            sortable: true,
        },
        {
            name: 'Location',
            selector: row => row.location,
            sortable: true,
        },
        {
            name: 'Manufacturer Part No',
            selector: row => row.mpn,
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true,
        },
        {
            name: 'Ref',
            selector: row => row.ref,
        },
        {
            name: 'Actions',
            cell: (row)=> {
                return (
                    <button className="btn btn-tool" onClick={()=> {
                        if (window.confirm('Are you sure you want to delete?')) {
                            handleDelete(row.itemid)
                        }
                    }}>
                        <i className="fas fa-trash"></i>
                    </button>
                )
            }
        },
    ];

	const [filterText, setFilterText] = useState('');
    const filteredItems = tableData.filter(
		item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
	);
    const subHeaderComponentMemo = useMemo(() => {

		return (
            <>
            <input value={filterText} onChange={e=>{setFilterText(e.target.value)}} placeholder="Search Name"/>
            </>
		);
	}, [filterText]);
    return (
        <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationComponentOptions={{
                noRowsPerPage:true
            }}
            subHeader
			subHeaderComponent={subHeaderComponentMemo}
        />
        
    );
}

{/* <table className="table table-hover text-nowrap">
    <thead>
        <tr>
            <th>Name</th>
            <th>Location</th>
            <th>Mpn</th>
            <th>Category</th>
            <th>Ref</th>
            <th>Actions</th>
        </tr>
    </thead>
    <tbody>
        {tableData.map((data, key) => <tr>
                <td><Link to={`/inventory/${inventory}/${data.itemid}/edit`}>{data.name ? data.name : '-'}</Link></td>
                <td>{data.location}</td>
                <td>{data.mpn}</td>
                <td>{data.category}</td>
                <td>{data.ref}</td>
                <td>
                    <button className="btn btn-tool" onClick={()=> {
                        if (window.confirm('Are you sure you want to delete?')) {
                            handleDelete(data.itemid)
                        }
                    }}>
                        <i className="fas fa-trash"></i>
                    </button>
                </td>
            </tr>)}
    </tbody>
</table> */}