import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {config} from '../../../utils/config';
import { errorToast, successToast } from '../../../utils/toast';
import { useParams } from 'react-router-dom'
import  axiosInstance  from '../../../utils/api';
import AddImageModal from '../components/AddImageModal';

export default function TagAddEdit() {
    const [loading, setLoading] = useState(false)

    const [type, setType] = useState('view')
    let { tagId, operation } = useParams();
    const [tagData, setTagData] = useState({
        tag: {},
        url:[]
    })
    const [checkboxes, setCheckboxes] = useState([]) // [ {image_name: '', checked: false}]
    const [addImageModalStatus, setAddImageModalStatus] = useState(false)

    useEffect(() => {
        if (operation == 'edit' && tagId) {
            setType('edit')
            getTagData(tagId)
        } else if (operation == 'view' && tagId)  {
            setType('view')
            getTagData(tagId)
        }
    }, [])

    function getTagData(id) {
        setCheckboxes([])
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/durian/getIndividualTagWithImage/${id}`).then(res=>{
            setLoading(false)
            setTagData({
                tag: res.data.tag[0],
                url: res.data.url,
            })


            for (let i in res.data.url) {
                setCheckboxes(prev=>([
                    ...prev,
                    {
                        image_name: res.data.url[i].split("/")[9].split('?')[0],
                        checked:false
                    }
                ]))
            }
        }).catch(res=>{
            setLoading(false)
            console.log(res)
        })
    }

    function handleInput(e, field) {
        setTagData( prev => ({
            ...prev,
            tag: {
                ...prev.tag,
                [field]:e.target.value
            }
        }))
    }

    function handleSubmit(e) {
        e.preventDefault()

        // if (type=='add') {
        //     createFolder()
        // } else
         if (type=='edit') {
            updateTag()
        }

    }

    function updateTag() {

        let body = {}
        for (let i in tagData.tag) {
            if (tagData.tag[i] != null && tagData.tag[i] != "null") {
                body[i]=tagData.tag[i]
            }
        }

        let bodyFormData = new FormData();
        bodyFormData.append('tag', JSON.stringify(body));

        console.log(body)

        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/durian/updateTagIndividualDataWithImage/`, bodyFormData ).then(res=>{
            setLoading(false)
            successToast(res.data.message)
            getTagData(tagId)
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })
    }

    function uploadImage(e) {
        if (e.target.files.length < 1) {
            return null
        }
        

        let bodyFormData = new FormData();
        bodyFormData.append('tag', JSON.stringify({
            "tagid": tagData.tag.tagid
        }));
        let counter = 1;
        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                let file = new File([e.target.files[i]], new Date().toISOString() + "_" + counter, {type: "image/jpg", lastModified: Date.now()});
                if (counter < 10) {
                    bodyFormData.append('0'+counter, file);
                } else {
                    bodyFormData.append(counter, file);
                }
            }
            counter ++
        }

        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/durian/updateTagIndividualDataWithImage/`, bodyFormData)
        .then(res=>{
            setLoading(false)
            successToast(res.data.message)
            getTagData(tagId)
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })

        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                URL.revokeObjectURL(e.target.files[i])
            }
        }
    }

    function deleteImage() {
        let image_to_delete_arr = [];
        for (let i in checkboxes) {
            if (checkboxes[i].checked) {
                image_to_delete_arr.push(decodeURIComponent(checkboxes[i].image_name))
            }
        }

        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/core/deleteImage/`, {
            "tag": {
                "tagid": tagData.tag.tagid,
                "delete": image_to_delete_arr,
            }
        }).then(res=>{
            setLoading(false)
            successToast(res.data.message)

            getTagData(tagId)
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })
    }


    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Tag {type=='edit'?('Update'):('View')}</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="/tag">Tag</a></li>
                        <li className="breadcrumb-item active">Tag {type=='edit'?('Update'):('View')}</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h4 className="card-title">{type=='edit'?('Update'):('View')} Image</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {tagData.url.length > 0 ? (
                                            tagData.url.map((image,index) => (
                                            
                                                <div className="col-2 text-center m-2 p-0" key={index}>
                                                    <div>
                                                        <input type="checkbox" id={image} checked={checkboxes[index] ? checkboxes[index].checked : false}
                                                        onChange={(e)=>{
                                                            // console.log(e.target.id)
                                                            // console.log(e.target.checked)
                                                            // console.log(e.target.id.split("/")[8].split('?')[0])

                                                            let updatedList = checkboxes.map(i => {
                                                                if (i.image_name == e.target.id.split("/")[9].split('?')[0]) {
                                                                    return {...i, checked: e.target.checked};
                                                                }
                                                                return i;
                                                            });
                                                            setCheckboxes(updatedList)

                                                        }}/>
                                                        <label htmlFor="checkboxPrimary3" style={{position:'absolute'}}></label>
                                                    </div>

                                                    <a href={image} data-toggle="lightbox" data-title="Image" data-farm="gallery" >
                                                        <img src={image}  className="img-fluid" alt="Farmsens-Image"/>
                                                    </a>

                                                </div>

                                            ))
                                        ) : (
                                            <div>
                                                <p>No Image Available</p>
                                            </div>
                                        )}


                                    </div>
                                </div>

                                {type=='edit'&&(
                                    <div className="card-footer">
                                        <button type="button" className="btn btn-primary m-2" onClick={()=>{ setAddImageModalStatus(true) }}>
                                            Add Image
                                        </button>
                                        {/* <button className="btn btn-primary m-2">
                                            <label htmlFor="img" style={{margin:0}}>
                                                Add Image
                                                <input
                                                    accept="image/*"
                                                    id="img"
                                                    multiple
                                                    name="img"
                                                    type="file"
                                                    capture
                                                    onChange={uploadImage}
                                                    hidden
                                                />
                                            </label>
                                        </button> */}
                                        <button type="submit" className="float-right btn btn-default m-2" onClick={deleteImage}>Delete Image</button>
                                    </div>
                                )}
                                
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <form className="form-horizontal">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">Basic Info</h3>
                                    </div>

                                        <div className="card-body">
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Breed</label>
                                                <div className="col-sm-10">
                                                    <input value={tagData.tag.breed ||''} onChange={(e)=>handleInput(e,'breed')} type="text" className="form-control" id="library-name" placeholder="" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Other Name</label>
                                                <div className="col-sm-10">
                                                    <input value={tagData.tag.breed_other_name ||''} onChange={(e)=>handleInput(e,'breed_other_name')} type="text" className="form-control" id="breed" placeholder="" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Grade</label>
                                                <div className="col-sm-10">
                                                    <input value={tagData.tag.grade ||''} onChange={(e)=>handleInput(e,'grade')} type="text" className="form-control" id="breed-other-name" placeholder="" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Origin</label>
                                                <div className="col-sm-10">
                                                    <input value={tagData.tag.origin ||''} onChange={(e)=>handleInput(e,'origin')}  type="text" className="form-control" id="grade" placeholder="" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Altitude</label>
                                                <div className="col-sm-10">
                                                    <input value={tagData.tag.altitude ||''} onChange={(e)=>handleInput(e,'altitude')}  type="text" className="form-control" id="grade" placeholder="" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>

                                        </div>
                                </div>
                            </form>
                        </div>


                        <div className="col-md-6">
                            <form className="form-horizontal">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">Flavour Characteristic</h3>
                                    </div>

                                        <div className="card-body">
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Taste</label>
                                                <div className="col-sm-10">
                                                    <input value={tagData.tag.taste ||''} onChange={(e)=>handleInput(e,'taste')} type="text" className="form-control" id="library-name" placeholder="" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Color</label>
                                                <div className="col-sm-10">
                                                    <input value={tagData.tag.color ||''} onChange={(e)=>handleInput(e,'color')} type="text" className="form-control" id="breed" placeholder="" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Texture</label>
                                                <div className="col-sm-10">
                                                    <input value={tagData.tag.texture ||''} onChange={(e)=>handleInput(e,'texture')} type="text" className="form-control" id="breed-other-name" placeholder="" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Aroma</label>
                                                <div className="col-sm-10">
                                                    <input value={tagData.tag.aroma ||''} onChange={(e)=>handleInput(e,'aroma')}  type="text" className="form-control" id="grade" placeholder="" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>

                                        </div>
                                </div>
                            </form>
                        </div>


                        <div className="col-md-6">
                            <form className="form-horizontal">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">Fruit Info</h3>
                                    </div>

                                        <div className="card-body">
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Seed size</label>
                                                <div className="col-sm-10">
                                                    <input value={tagData.tag.seed_size ||''} onChange={(e)=>handleInput(e,'seed_size')} type="text" className="form-control" id="library-name" placeholder="" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Shape</label>
                                                <div className="col-sm-10">
                                                    <input value={tagData.tag.shape ||''} onChange={(e)=>handleInput(e,'shape')} type="text" className="form-control" id="breed" placeholder="" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Tree Age</label>
                                                <div className="col-sm-10">
                                                    <input value={tagData.tag.tree_age ||''} onChange={(e)=>handleInput(e,'tree_age')} type="text" className="form-control" id="breed-other-name" placeholder="" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">Date Harvest</label>
                                                <div className="col-sm-10">
                                                    <input value={tagData.tag.harvest ||''} onChange={(e)=>handleInput(e,'harvest')}  className="form-control w-100 " type="date" id="harvest" name="harvest"  disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>

                                        </div>
                                </div>
                            </form>
                        </div>
                    </div>


                
                

                    {type=='edit'&&(
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary" onClick={handleSubmit}>{type=='add'?('Submit'):('Update')}</button>
                        </div>
                    )}
                
                    <AddImageModal  data={tagData} setLoading={setLoading}  show={addImageModalStatus} setShow={setAddImageModalStatus}/>

                </div>
            </section>


            
        </div>
    )
}
