import React, {useEffect,useState} from 'react'
import { Link, useLocation,useParams } from 'react-router-dom'
import { successToast, errorToast } from '../../utils/toast';
import  axiosInstance  from '../../utils/api'
import ItemForm from './components/ItemForm';
import { LocationForm } from './components/LocationForm';
import { CategoryForm } from './components/CategoryForm';
import { SupplierForm } from './components/SupplierForm';
import { useHistory } from 'react-router-dom';
import { UnitMeasureForm } from './components/UnitMeasureForm';
import { PurchaseForm } from './components/PurchaseForm';
import { ConsumptionForm } from './components/ConsumptionForm';
import { AdjustmentForm } from './components/AdjustmentForm';
import { capitalize } from '../../utils/helpers';

export const InventoryAddEdit = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('add')
    let { inventory, inventoryId } = useParams();
    const [inputs, setInputs] = useState([{}])
    const [locationOptions, setLocationOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([])
    const [itemOptions, setItemOptions] = useState([])
    const [unitMeasureOptions, setUnitMeasureOptionss] = useState([])
    const [form, setForm] = useState([])

    useEffect(() => {
        if (inventoryId) {
            setType('edit')
            getInventoryData(inventoryId)
        } else {
            setType('add')
        }

        
        if (inventory=='item') {
            setLoading(true)
            // Get Location options
            axiosInstance.get(`${process.env.REACT_APP_API}/inventory/location/list/`).then(res => {
                setLocationOptions(res.data)
                setLoading(false)
            }).catch(err=>{
                console.log(err)
                setLoading(false)
            })
    
            
            // Get Category options
            axiosInstance.get(`${process.env.REACT_APP_API}/inventory/category/list/`).then(res => {
                setCategoryOptions(res.data)
                setLoading(false)
            }).catch(err=>{
                console.log(err)
                setLoading(false)
            })
            // Get Location options
            axiosInstance.get(`${process.env.REACT_APP_API}/inventory/unit-measure/list/`).then(res => {
                setUnitMeasureOptionss(res.data)
                setLoading(false)
            }).catch(err=>{
                console.log(err)
                setLoading(false)
            })
        } else if (inventory=='purchase') {
            getItemOptions()
            // Get Supplier options
            setLoading(true)
            axiosInstance.get(`${process.env.REACT_APP_API}/inventory/supplier/list/`).then(res => {
                setSupplierOptions(res.data)
                setLoading(false)
            }).catch(err=>{
                console.log(err)
                setLoading(false)
            })
        } else if (inventory=='consumption' || inventory=='adjustment') {
            getItemOptions()
        }

    }, [inventory])

    function getItemOptions() {
        setLoading(true)
        // Get Item options
        axiosInstance.get(`${process.env.REACT_APP_API}/inventory/item/list/`).then(res => {
            setItemOptions(res.data)
            setLoading(false)
        }).catch(err=>{
            console.log(err)
            setLoading(false)
        })
    }

    
    function getInventoryData(i) {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/inventory/${inventory}/show/${i}/`)
        .then(res=>{
            setLoading(false)
            let newInputs = [...inputs]
            newInputs[0] = res.data
            setInputs(newInputs)
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })
    }
    function handleInput(e, field, index) {
        let newInputs = [...inputs]
        newInputs[index][field] = e.target.value
        setInputs(newInputs)
    }
    
    
    function handleSubmit() {
        if (inventory=='item') {
            for (let i of inputs) {
                if (Object.keys(i).length == 0) {
                    return errorToast('Please fill in required field')
                } else if (
                typeof i.name == 'undefined' || i.name == '' 
                || typeof i.current_stock == 'undefined' || i.current_stock == '' 
                || typeof i.reorder_level == 'undefined' || i.reorder_level == '' 
                || typeof i.unit_measure == 'undefined' || i.unit_measure == '' ) {
                    return errorToast('Please fill in required field')
                }
            }
        } else if (inventory=='location') {
            for (let i of inputs) {
                if (Object.keys(i).length == 0) {
                    return errorToast('Please fill in required field')
                } else if (typeof i.name == 'undefined' || i.name == '' ) {
                    return errorToast('Please fill in required field')
                }
            }
        } else if (inventory=='category') {
            for (let i of inputs) {
                if (Object.keys(i).length == 0) {
                    return errorToast('Please fill in required field')
                } else if (typeof i.name == 'undefined' || i.name == '' ) {
                    return errorToast('Please fill in required field')
                }
            }
        } else if (inventory=='supplier') {
            for (let i of inputs) {
                if (Object.keys(i).length == 0) {
                    return errorToast('Please fill in required field')
                } else if (typeof i.name == 'undefined' || i.name == '' ) {
                    return errorToast('Please fill in required field')
                }
            }
        } else if (inventory=='unit-measure') {
            for (let i of inputs) {
                if (Object.keys(i).length == 0) {
                    return errorToast('Please fill in required field')
                } else if (typeof i.unit == 'undefined' || i.unit == '' ) {
                    return errorToast('Please fill in required field')
                }
            }
        } else if (inventory=='purchase') {
            for (let i of inputs) {
                if (Object.keys(i).length == 0) {
                    return errorToast('Please fill in required field')
                } else if (typeof i.itemid == 'undefined' || i.itemid == '' ) {
                    return errorToast('Please fill in required field')
                }
            }
        } else if (inventory=='consumption') {
            for (let i of inputs) {
                if (Object.keys(i).length == 0) {
                    return errorToast('Please fill in required field')
                } else if (typeof i.itemid == 'undefined' || i.itemid == '' ) {
                    return errorToast('Please fill in required field')
                }
            }
        } else if (inventory=='adjustment') {
            for (let i of inputs) {
                if (Object.keys(i).length == 0) {
                    return errorToast('Please fill in required field')
                } else if (typeof i.itemid == 'undefined' || i.itemid == '' 
                || typeof i.reason == 'undefined' || i.reason == '' ) {
                    return errorToast('Please fill in required field')
                }
            }
        }

        
        if (type == 'edit') {
            setLoading(true)
            axiosInstance.put(`${process.env.REACT_APP_API}/inventory/${inventory}/update/${inventoryId}/`, inputs[0]).then(res=>{
                setLoading(false)
                successToast('Succeesfully Updated')
                getInventoryData(inventoryId)
                setTimeout(()=>{
                    return history.push(`/inventory/${inventory}`)
                }, 500)
            }).catch(res=>{
                setLoading(false)
                errorToast('Server Error')
            })
        } else if (type=='add') {
            setLoading(true)
            axiosInstance.post(`${process.env.REACT_APP_API}/inventory/${inventory}/create/`, inputs).then(res=>{
                setLoading(false)
                successToast('Succeesfully Created')
                setTimeout(()=>{
                    return history.push(`/inventory/${inventory}`)
                }, 500)
            }).catch(res=>{
                setLoading(false)
                errorToast('Server Error')
            })
        }
        
    }


    
    function addForm() {
        setInputs(prev => [...prev, {}])
    }
    function removeForm(i) {
        let newInputs = [...inputs];
        newInputs.splice(i, 1);
        setInputs(newInputs)
    }

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">{capitalize(inventory)} Detail</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
                            <li className="breadcrumb-item"><Link to={`/inventory/${inventory}`}>Inventory {capitalize(inventory)}</Link></li>
                            <li className="breadcrumb-item active">{capitalize(inventory)} Detail</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">

                    <div className="row">
                        
                        <div className="col-md-12">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">{capitalize(inventory)} Info</h3>
                                </div>
                                <div className="card-body">
                                    {inputs.map( (i, index) => {
                                        if (inventory=='item') {
                                            return  (
                                                <>
                                                <div style={{padding:'10px', display:'flex'}}>
                                                    <h3>{capitalize(inventory)} #{index + 1}</h3>
                                                    {index!=0 && (<button type="button" class="btn btn-tool" onClick={()=>removeForm(index)}><i class="fas fa-times"></i></button>)}
                                                </div>
                                                <ItemForm handleInput={handleInput} inputs={inputs[index]} index={index} locationOptions={locationOptions} categoryOptions={categoryOptions} unitMeasureOptions={unitMeasureOptions}/>
                                                </>
                                            )
                                        } else if (inventory=='location') {
                                            return  (
                                                <>
                                                <div style={{padding:'10px', display:'flex'}}>
                                                    <h3>{capitalize(inventory)} #{index + 1}</h3>
                                                    {index!=0 && (<button type="button" class="btn btn-tool" onClick={()=>removeForm(index)}><i class="fas fa-times"></i></button>)}
                                                </div>
                                                <LocationForm handleInput={handleInput} inputs={inputs[index]} index={index}/>
                                                </>
                                            )
                                        } else if (inventory=='category') {
                                            return  (
                                                <>
                                                <div style={{padding:'10px', display:'flex'}}>
                                                    <h3>{capitalize(inventory)} #{index + 1}</h3>
                                                    {index!=0 && (<button type="button" class="btn btn-tool" onClick={()=>removeForm(index)}><i class="fas fa-times"></i></button>)}
                                                </div>
                                                <CategoryForm handleInput={handleInput} inputs={inputs[index]} index={index}/>
                                                </>
                                            )
                                        } else if (inventory=='supplier') {
                                            return  (
                                                <>
                                                <div style={{padding:'10px', display:'flex'}}>
                                                    <h3>{capitalize(inventory)} #{index + 1}</h3>
                                                    {index!=0 && (<button type="button" class="btn btn-tool" onClick={()=>removeForm(index)}><i class="fas fa-times"></i></button>)}
                                                </div>
                                                <SupplierForm handleInput={handleInput} inputs={inputs[index]} index={index}/>
                                                </>
                                            )
                                        } else if (inventory=='unit-measure') {
                                            return  (
                                                <>
                                                <div style={{padding:'10px', display:'flex'}}>
                                                    <h3>{capitalize(inventory)} #{index + 1}</h3>
                                                    {index!=0 && (<button type="button" class="btn btn-tool" onClick={()=>removeForm(index)}><i class="fas fa-times"></i></button>)}
                                                </div>
                                                <UnitMeasureForm handleInput={handleInput} inputs={inputs[index]} index={index}/>
                                                </>
                                            )
                                        } else if (inventory=='purchase') {
                                            return  (
                                                <>
                                                <div style={{padding:'10px', display:'flex'}}>
                                                    <h3>{capitalize(inventory)} #{index + 1}</h3>
                                                    {index!=0 && (<button type="button" class="btn btn-tool" onClick={()=>removeForm(index)}><i class="fas fa-times"></i></button>)}
                                                </div>
                                                <PurchaseForm handleInput={handleInput} inputs={inputs[index]} index={index} supplierOptions={supplierOptions} itemOptions={itemOptions}/>
                                                </>
                                            )
                                        } else if (inventory=='consumption') {
                                            return  (
                                                <>
                                                <div style={{padding:'10px', display:'flex'}}>
                                                    <h3>{capitalize(inventory)} #{index + 1}</h3>
                                                    {index!=0 && (<button type="button" class="btn btn-tool" onClick={()=>removeForm(index)}><i class="fas fa-times"></i></button>)}
                                                </div>
                                                <ConsumptionForm handleInput={handleInput} inputs={inputs[index]} index={index} itemOptions={itemOptions}/>
                                                </>
                                            )
                                        } else if (inventory=='adjustment') {
                                            return  (
                                                <>
                                                <div style={{padding:'10px', display:'flex'}}>
                                                    <h3>{capitalize(inventory)} #{index + 1}</h3>
                                                    {index!=0 && (<button type="button" class="btn btn-tool" onClick={()=>removeForm(index)}><i class="fas fa-times"></i></button>)}
                                                </div>
                                                <AdjustmentForm handleInput={handleInput} inputs={inputs[index]} index={index} itemOptions={itemOptions}/>
                                                </>
                                            )
                                        }
                                        
                                    })}
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row p-3 justify-content-between">
                        <button type="submit" className="btn btn-primary btn-md" onClick={handleSubmit}>{type=='add'?'Create':'Update'}</button>
                        {type=='add' && (
                            <button className="btn btn-secondary btn-md" onClick={()=>{addForm()}}>Add New Item</button>
                        )}
                    </div>
                </div>
            </section>

            
        </div>
    )
}

  