import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom'

const InventoryMore = () => {
    const [loading, setLoading] = useState(false)
    return (
        <div className="content-wrapper">
            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Inventory Navigation</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active">Inventory Navigation</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <Link to='/inventory/visualization'>
                                <button type="button" className="btn btn-block btn-success btn-lg mb-2">
                                    Visualization
                                </button>
                            </Link>
                            <Link to='/inventory/purchase'>
                                <button type="button" className="btn btn-block btn-info btn-lg mb-2">
                                    Purchase
                                </button>
                            </Link>
                            <Link to='/inventory/consumption'>
                                <button type="button" className="btn btn-block btn-primary btn-lg mb-2">
                                    Consumption
                                </button>
                            </Link>
                            <Link to='/inventory/adjustment'>
                                <button type="button" className="btn btn-block btn-warning btn-lg mb-2">
                                    Adjustment
                                </button>
                            </Link>
                            <Link to='/inventory/item'>
                                <button type="button" className="btn btn-block btn-success btn-lg mb-2">
                                    Item
                                </button>
                            </Link>
                            <Link to='/inventory/category'>
                                <button type="button" className="btn btn-block btn-info btn-lg mb-2">
                                    Category
                                </button>
                            </Link>
                            <Link to='/inventory/location'>
                                <button type="button" className="btn btn-block btn-info btn-lg mb-2">
                                    Location
                                </button>
                            </Link>
                            <Link to='/inventory/supplier'>
                                <button type="button" className="btn btn-block btn-primary btn-lg mb-2">
                                    Supplier
                                </button>
                            </Link>
                            <Link to='/inventory/unit-measure'>
                                <button type="button" className="btn btn-block btn-warning btn-lg mb-2">
                                    Unit Measure
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default InventoryMore