import React, {useEffect,useState, useContext} from 'react'
import axios from 'axios';
import {config} from '../../../utils/config';
import { errorToast, successToast } from '../../../utils/toast';
import { useParams } from "react-router-dom";
import  axiosInstance  from '../../../utils/api';
import AddImageModal from '../components/AddImageModal'

export default function BatchInputSerialAddEdit() {
    const [loading, setLoading] = useState(false)
    const {batch, serialFrom, serialTo, tagId} = useParams();
    const [tagData, setTagData] = useState({
        tag: JSON.parse(localStorage.getItem('serialData')),
        url:[]
    })
    const [checkboxes, setCheckboxes] = useState([]) // [ {image_name: '', checked: false}]
    const [addImageModalStatus, setAddImageModalStatus] = useState(false)


    useEffect(() => {
        getTagData()
    }, [])

    function getTagData() {
        setCheckboxes([])
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/durian/getIndividualTagWithImage/${tagId}`).then(res=> {
            setLoading(false)
            setTagData({
                tag: res.data.tag[0],
                url: res.data.url
            })

            for (let i in res.data.url) {
                setCheckboxes(prev=>([
                    ...prev,
                    {
                        image_name: res.data.url[i].split("/")[9].split('?')[0],
                        checked:false
                    }
                ]))
            }
        }).catch(res=>{
            setLoading(false)
            console.log(res)
        })
    }


    function handleInput(e, field) {
        setTagData( prev => ({
            ...prev,
            tag: {
                ...prev.tag,
                [field]:e.target.value
            }
        }))
    }

    function handleSubmit(e) {
        e.preventDefault()

        let bodyFormData = new FormData();
        bodyFormData.append('tag', JSON.stringify({
            "batch": tagData.tag.batch, 
            "serial": tagData.tag.serial, 
            "breed": tagData.tag.breed, 
            "breed_other_name": tagData.tag.breed_other_name, 
            "grade":tagData.tag.grade, 
            "origin": tagData.tag.origin, 
            "altitude": tagData.tag.altitude, 
            "taste": tagData.tag.taste,
            "color": tagData.tag.color,
            "texture": tagData.tag.texture, 
            "aroma": tagData.tag.aroma,
            "seed_size": tagData.tag.seed_size,
            "shape": tagData.tag.shape,
            "tree_age": tagData.tag.tree_age,
            "harvest": tagData.tag.harvest,
            "weight": tagData.tag.weight,
        }));

        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/durian/updateTagIndividualDataWithImage/`, bodyFormData).then(res=>{
            setLoading(false)
            console.log(res)
            successToast(res.data.message)

            return window.location = `/trackntrace/batch-input/serials/${batch}/${serialFrom}/${serialTo}`
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }


    function uploadImage(e) {
        if (e.target.files.length < 1) {
            return null
        }
        let bodyFormData = new FormData();
        bodyFormData.append('tag', JSON.stringify({
            "batch": tagData.tag.batch,
            "serial": tagData.tag.serial,
        }));
        let counter = 1;
        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                let file = new File([e.target.files[i]], new Date().toISOString() + "_" + counter, {type: "image/jpg", lastModified: Date.now()});
                if (counter < 10) {
                    bodyFormData.append('0'+counter, file);
                } else {
                    bodyFormData.append(counter, file);
                }
            }
            counter ++
        }
        console.log({
            "batch": tagData.tag.batch,
            "serial": tagData.tag.serial,
        })

        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/durian/updateTagIndividualDataWithImage/`, bodyFormData)
        .then(res=>{
            setLoading(false)
            successToast(res.data.message)
            getTagData()
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })

        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                URL.revokeObjectURL(e.target.files[i])
            }
        }


    }

    function deleteImage() {
        let image_to_delete_arr = [];
        for (let i in checkboxes) {
            if (checkboxes[i].checked) {
                image_to_delete_arr.push(decodeURIComponent(checkboxes[i].image_name))
            }
        }
     
        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/core/deleteImage/`, {
            "tag": {
                "tagid": tagData.tag.tagid,
                "delete": image_to_delete_arr,
            }
        }).then(res=>{
            setLoading(false)
            // console.log(res)
            successToast(res.data.message)
            getTagData()

            // getfarmData(farmId)
        }).catch(res=>{
            setLoading(false)
            // console.log(res)
            errorToast('Server Error')
        })
    }


    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Batch: {batch} ; Serial: {tagData.tag.serial}</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item"><a href="/trackntrace/batch-input">Batch Input</a></li>
                            <li className="breadcrumb-item"><a href={`/trackntrace/batch-input/serials/${batch}/${serialFrom}/${serialTo}`}>Batch Input Serial</a></li>
                            <li className="breadcrumb-item active">Batch Input Update</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">



                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h4 className="card-title">Update Image</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {tagData.url.length > 0 ? (
                                            tagData.url.map((image,index) => (

                                                <div className="col-sm-2 icheck-primary d-inline" style={{display:'flex', flexFlow:'wrap', position:'relative'}} key={index}>
                                                    <input type="checkbox" id={image} style={{position:'absolute'}} checked={checkboxes[index] ? checkboxes[index].checked : false}
                                                    onChange={(e)=>{
                                                        let updatedList = checkboxes.map(i => {
                                                            if (i.image_name == e.target.id.split("/")[9].split('?')[0]) {
                                                                return {...i, checked: e.target.checked};
                                                            }
                                                            return i;
                                                        });
                                                        setCheckboxes(updatedList)

                                                    }}/>
                                                    <label htmlFor="checkboxPrimary3" style={{position:'absolute'}}></label>

                                                    <a href={image} data-toggle="lightbox" data-title="Image" data-farm="farm" >
                                                        <img src={image} className="img-fluid mb-2" alt="Farmsens-Image"style={{height:'100%'}}/>
                                                    </a>
                                                </div>

                                            ))
                                        ) : (
                                            <div>
                                                <p>No Image Available</p>
                                            </div>
                                        )}

                                    </div>
                                </div>
                                <div className="card-footer">
                                        <button className="btn btn-primary m-2" onClick={()=>{
                                            setAddImageModalStatus(true)
                                        }}>
                                        Add Image
                                        </button>
                                        <button type="submit" className="float-right btn btn-default m-2" onClick={deleteImage}>Delete Image</button>
                                    </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-sm-12">

                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Batch Info</h3>
                                </div>
                                <form>
                                    <div className="card-body">

                                            {/* BASIC INFO */}
                                            <div className="row">
                                                <div className="col-sm-6 p-3">
                                                    <h5><b>Basic Info</b></h5>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Basic Info</label>
                                                        <div className="col-sm-10">
                                                            <input value={tagData.tag.breed||''} onChange={(e)=>handleInput(e,'breed')}  type="text" className="form-control" id="breed" placeholder="Optional" />
                                                        </div>
                                                    </div>


                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Other Name</label>
                                                        <div className="col-sm-10">
                                                            <input value={tagData.tag.breed_other_name||''} onChange={(e)=>handleInput(e,'breed_other_name')}  type="text" className="form-control" id="breed_other_name" placeholder="Optional" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Grade</label>
                                                        <div className="col-sm-10">
                                                            <input value={tagData.tag.grade||''} onChange={(e)=>handleInput(e,'grade')}  type="text" className="form-control" id="grade" placeholder="Optional" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Origin</label>
                                                        <div className="col-sm-10">
                                                            <input value={tagData.tag.origin||''} onChange={(e)=>handleInput(e,'origin')}  type="text" className="form-control" id="origin" placeholder="Optional" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Altitude</label>
                                                        <div className="col-sm-10">
                                                            <input value={tagData.tag.altitude||''} onChange={(e)=>handleInput(e,'altitude')}  type="text" className="form-control" id="altitude" placeholder="Optional" />
                                                        </div>
                                                    </div>

                                                </div>



                                                {/* FRUIT INFO */}
                                                <div className="col-sm-6 p-3">
                                                    <h5><b>Fruit Info</b></h5>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Seed Size</label>
                                                        <div className="col-sm-10">
                                                            <select className="form-control" value={tagData.tag.seed_size} onChange={(e)=>handleInput(e,'seed_size')}>
                                                                <option value="null"> -- Optional -- </option>
                                                                <option value="small" key="small">SMALL</option>
                                                                <option value="medium">MEDIUM</option>
                                                                <option value="big">BIG</option>
                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Shape</label>
                                                        <div className="col-sm-10">
                                                            <select className="form-control" value={tagData.tag.shape} onChange={(e)=>handleInput(e,'shape')}>
                                                                <option value="null"> -- Optional -- </option>
                                                                <option value="oblong">OBLONG</option>
                                                                <option value="round">ROUND</option>
                                                            </select>                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Tree Age</label>
                                                        <div className="col-sm-10">
                                                            <select className="form-control" value={tagData.tag.tree_age} onChange={(e)=>handleInput(e,'tree_age')}>
                                                                <option value="null"> -- Optional -- </option>
                                                                <option value="5 - 10 yrs">5 - 10 yrs</option>
                                                                <option value="11 - 15 yrs">11 - 15 yrs</option>
                                                                <option value="16 - 20 yrs">16 - 20 yrs</option>
                                                                <option value="21 - 25 yrs">21 - 25 yrs</option>
                                                                <option value="26 - 30 yrs">26 - 30 yrs</option>
                                                                <option value="31 - 35 yrs">31 - 35 yrs</option>
                                                                <option value="36 - 40 yrs">36 - 40 yrs</option>
                                                                <option value="41 - 50 yrs">41 - 50 yrs</option>
                                                                <option value="> 50 yrs"> {'>'} 50 yrs</option>
                                                            </select>                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Date Harvest</label>
                                                        <div className="col-sm-10">
                                                            <input value={tagData.tag.harvest||''} className="form-control w-100 " type="date" id="harvest" name="harvest" onChange={(e)=>handleInput(e,'harvest')}/>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Weight</label>
                                                        <div className="col-sm-10">
                                                            <input value={tagData.tag.weight||''} onChange={(e)=>handleInput(e,'weight')}  type="text" className="form-control" id="altitude" placeholder="Optional" />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                            {/* FLAVOUR CHARACTERISTIC */}
                                            <div className="row">
                                                <div className="col-sm-6 p-3">
                                                    <h5><b>Flavour Characteristic</b></h5>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Taste</label>
                                                        <div className="col-sm-10">
                                                            <input value={tagData.tag.taste||''} type="text" className="form-control" id="taste" placeholder="Optional" onChange={(e)=>handleInput(e,'taste')}/>
                                                        </div>
                                                    </div>


                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Color</label>
                                                        <div className="col-sm-10">
                                                            <input value={tagData.tag.color||''} type="text" className="form-control" id="color" placeholder="Optional" onChange={(e)=>handleInput(e,'color')}/>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Texture</label>
                                                        <div className="col-sm-10">
                                                            <input value={tagData.tag.texture||''} type="text" className="form-control" id="texture" placeholder="Optional" onChange={(e)=>handleInput(e,'texture')}/>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Aroma</label>
                                                        <div className="col-sm-10">
                                                            <input value={tagData.tag.aroma||''} type="text" className="form-control" id="aroma" placeholder="Optional" onChange={(e)=>handleInput(e,'aroma')}/>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                    </div>

                                    <div className="card-footer">
                                        <button className="btn btn-primary" onClick={e=>handleSubmit(e)}>Submit</button>
                                    </div>
                                </form>
                            </div>

                        
                        </div>
                    </div>
                
                </div>
            </section>

            <AddImageModal  data={tagData} setLoading={setLoading} show={addImageModalStatus} setShow={setAddImageModalStatus}/>
        </div>
    )
}
