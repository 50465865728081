import React, {useEffect,useState} from 'react'
import axios from 'axios'
import { config } from '../../../utils/config';
import { useLocation,useParams } from 'react-router-dom'
import { successToast, errorToast } from '../../../utils/toast';
import  axiosInstance  from '../../../utils/api'

export default function LibraryDetail() {
    const [type, setType] = useState('add') // add, edit, view
    const [loading, setLoading] = useState(false)
    const location = useLocation();
    let { libraryId } = useParams();

    const [inputs, setInputs] = useState({})

    useEffect(() => {
        if (location.pathname=='/library/create') {
            setType('add')
        } else if (libraryId && location.pathname==`/library/${libraryId}/edit`) {
            setType('edit')
            getLibraryData(libraryId)
        }

        // getList()
    }, [])

    function getLibraryData(libraryId) {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/durian/getLibraryIndividual/${libraryId}/`)
        .then(res=>{
            setLoading(false)
            setInputs(res.data.library[0])
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })
    }

    function createLibrary() {

        let body = {}
        
        if (inputs.name == '' || typeof inputs.name == 'undefined' || inputs.breed == '' || typeof inputs.breed == 'undefined' ) {
            return errorToast('Please fill in required')
        }
        
        for (let i in inputs) {
            body[i]=inputs[i]
        }


        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/durian/insertLibrary/`, {
            "library": body
        }).then(res=>{
            setLoading(false)
            successToast(res.data.message)
            return window.location = '/library'
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })
    }

    function handleInput(e, field) {
        setInputs({
            ...inputs,
            [field]: e.target.value
        })
    }

    function updateLibrary() {
        let body = {}

        if (inputs.name == '' || typeof inputs.name == 'undefined' || inputs.breed == '' || typeof inputs.breed == 'undefined' ) {
            return errorToast('Please fill in required')
        }

        for (let i in inputs) {
            if (inputs[i] != null || inputs[i] != "null") {
                body[i]=inputs[i]
            }
        }

        // temporary
        body['coid'] = "dc418f5c-ad09-432e-afa6-7b9f18f8ac5d"
        body['username'] = "sam"

        console.log(body)
        setLoading(true)
        axiosInstance.put(`${process.env.REACT_APP_API}/durian/updateLibrary/`, {
            library: body
        }).then(res=>{
            setLoading(false)
            successToast(res.data.message)
            getLibraryData(libraryId)
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })
    }

    function handleSubmit() {
        if(type=='add') {
            createLibrary()
        } else {
            updateLibrary()
        }
    }
    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Library Detail</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item"><a href="/library">Library</a></li>
                            <li className="breadcrumb-item active">Library Detail</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        
                        <div className="col-md-6">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">Basic Info</h3>
                                </div>

                                <form className="form-horizontal">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Library Name<span style={{color:'red'}}>*</span></label>
                                            <div className="col-sm-10">
                                                <input value={inputs.name ||''} onChange={(e)=>handleInput(e,'name')} type="text" className="form-control" id="library-name" placeholder="Required" disabled={type=='view'?(true):(false)}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Breed<span style={{color:'red'}}>*</span></label>
                                            <div className="col-sm-10">
                                                <input value={inputs.breed ||''} onChange={(e)=>handleInput(e,'breed')} type="text" className="form-control" id="breed" placeholder="Required" disabled={type=='view'?(true):(false)}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Other Name</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.breed_other_name ||''} onChange={(e)=>handleInput(e,'breed_other_name')} type="text" className="form-control" id="breed-other-name" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Grade</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.grade ||''} onChange={(e)=>handleInput(e,'grade')}  type="text" className="form-control" id="grade" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Date Harvest:</label>
                                            <div className="col-sm-10">
                                                <input value={inputs['harvest'] ||''} onChange={(e)=>handleInput(e,'harvest')}  className="form-control w-100 " type="date" id="harvest" name="harvest"  disabled={type=='view'?(true):(false)}/>
                                            </div>
                                            
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">Tree Related Info</h3>
                                </div>

                                <form className="form-horizontal">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Tree Age</label>
                                            <div className="col-sm-10">
                                                <select className="form-control" value={inputs.tree_age} onChange={e => handleInput(e,'tree_age')}>
                                                    <option value="null"> -- Optional -- </option>
                                                    <option value="5 - 10 yrs">5 - 10 yrs</option>
                                                    <option value="11 - 15 yrs">11 - 15 yrs</option>
                                                    <option value="16 - 20 yrs">16 - 20 yrs</option>
                                                    <option value="21 - 25 yrs">21 - 25 yrs</option>
                                                    <option value="26 - 30 yrs">26 - 30 yrs</option>
                                                    <option value="31 - 35 yrs">31 - 35 yrs</option>
                                                    <option value="36 - 40 yrs">36 - 40 yrs</option>
                                                    <option value="41 - 50 yrs">41 - 50 yrs</option>
                                                    <option value="> 50 yrs"> {'>'} 50 yrs</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">Fruit Info</h3>
                                </div>

                                <form className="form-horizontal">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Seed Size</label>
                                            <div className="col-sm-10">
                                                <select className="form-control" value={inputs.seed_size} onChange={e => handleInput(e,'seed_size')}>
                                                    <option value="null"> -- Optional -- </option>
                                                    <option value="small" key="small">SMALL</option>
                                                    <option value="medium">MEDIUM</option>
                                                    <option value="big">BIG</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Shape</label>
                                            <div className="col-sm-10">
                                                <select className="form-control" value={inputs.shape} onChange={e => handleInput(e,'shape')}>
                                                    <option value="null"> -- Optional -- </option>
                                                    <option value="oblong">OBLONG</option>
                                                    <option value="round">ROUND</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Altitude</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.altitude ||''} onChange={(e)=>handleInput(e,'altitude')} type="text" className="form-control" id="fruit-info-altitude" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">Flavour Characteristic</h3>
                                </div>

                                <form className="form-horizontal">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Taste</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.taste ||''} onChange={(e)=>handleInput(e,'taste')} type="text" className="form-control" id="taste" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Color</label>
                                            <div className="col-sm-10">
                                                <input  value={inputs.color ||''} onChange={(e)=>handleInput(e,'color')} type="text" className="form-control" id="color" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Texture</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.texture ||''} onChange={(e)=>handleInput(e,'texture')} type="text" className="form-control" id="texture" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Aroma</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.aroma ||''} onChange={(e)=>handleInput(e,'aroma')} type="text" className="form-control" id="aroma" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                        

                    </div>

                    <div className="row p-3">
                        <button type="submit" className="btn btn-primary btn-md" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </section>

            
        </div>
    )
}
