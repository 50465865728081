import React, {useState} from 'react'
import { formatDateTimeDisplay } from '../../../utils/helpers'

export const TreeNodeTable = ({ tableData, view, addEdit, remove }) => {

    return (
        <div className="table-responsive" style={{backgroundColor: '#FFF'}}>
            <table className="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>Serial</th>
                        <th>Group</th>
                        <th>Category</th>
                        <th>Description</th>
                        <th>Datetime</th>
                        <th>User</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.length > 0 ? 
                        tableData.map( data => (
                            <tr>
                                <td>{data.serial}</td>
                                <td>{data.group}</td>
                                <td>{data.category}</td>
                                <td>{data.description}</td>
                                <td>{formatDateTimeDisplay(data.datetime)}</td>
                                <td>{data.username}</td>
                                <td>
                                    <a href="javascript:void(0)" className="btn btn-tool" onClick={()=>(view(data))}>
                                        <i className="fas fa-eye"></i>
                                    </a>
                                    <a href="javascript:void(0)" className="btn btn-tool" onClick={()=>(addEdit(data))}>
                                        <i className="fas fa-edit"></i>
                                    </a>
                                    <a href="javascript:void(0)" className="btn btn-tool" onClick={()=>{
                                        if (window.confirm('Are you sure you want to delete tree?')) {
                                            remove(data)
                                        }
                                    }}>
                                        <i className="fas fa-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        ))
                        : (
                        <tr>
                            <td>No Data</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default TreeNodeTable