import axios from 'axios'

export const login =  (username, password) => {
    return async function(dispatch, getState) {
        dispatch({type: 'START_LOADING'})
        axios.post(`${process.env.REACT_APP_API}/token/`, {
            username: username,
            password: password
        }).then(res=>{
            dispatch({type: 'STOP_LOADING'})
            localStorage.setItem('token:access', res.data.access)
            localStorage.setItem('token:refresh', res.data.refresh)
            localStorage.setItem('user:data', JSON.stringify({
                "username": res.data.username,
                "company": res.data.company,
                "module": res.data.app
            }))
            return dispatch({
                type: "LOGIN_SUCCESS",
                payload: res.data
            });
        }).catch(res=>{
            dispatch({type: 'STOP_LOADING'})
            console.log(res)
            return dispatch({
                type: "LOGIN_FAIL",
            });
        })
    }
};

export const logout =  () => {
    return (dispatch) => {
        localStorage.clear()
        return dispatch({
            type: "LOGOUT",
        });
    }
};