import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { authActions } from '../redux';

export default function SideNav() {
    const [show, setShow] = useState({
        tree: false,
        farm: false,
        inventory: false
    })

    let userData = localStorage.getItem('user:data')
    const [user, setUser] = useState({
        username: '',
        company: '',
        module: []
    })
    const dispatch = useDispatch()
    const { logout } = bindActionCreators(authActions, dispatch)

    useEffect(() => {
        if (JSON.parse(userData)) {
            // log user out if module does not exist in storage.. to sync new and old version.
            if (typeof JSON.parse(userData).module == 'undefined') return logout()
            setUser({
                username: JSON.parse(userData).username,
                company: JSON.parse(userData).company,
                module: JSON.parse(userData).module
            })
        }
    }, [userData])

    function truncate(str, n){
        return str
        // return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    };
    
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="#" className="brand-link" style={{display:'flex',}}>
                <div className="col-4" style={{alignSelf:'center'}}>
                <img src="/img/farmsens.png" alt="AdminLTE Logo" className="brand-image" style={{opacity: 0.8}}/>
                </div>
                <div className="col-8" style={{whiteSpace:'normal', lineHeight: '21px', fontSize:'20px'}}>
                <span className="brand-text font-weight-light">{truncate(user.company, 24)}</span>
                </div>
            </a>

            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                    <img src="/img/user-icon.png" className="img-circle elevation-2 bg-white" alt="User Image"/>
                    </div>
                    <div className="info">
                    <a href="#" className="d-block">{user.username}</a>
                    </div>
                </div>
                
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-header">MODULES</li>

                        <li className="nav-item">
                            <a href="/" className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>Dashboard</p>
                            </a>
                        </li>

                        <li className="nav-item">
                            <a href="/farm" className="nav-link">
                                <i className="nav-icon fas fa-tractor"></i>
                                <p>Farm Menu</p>
                            </a>
                        </li>

                        {user.module.includes("tree") && (
                            <li className={show.tree ?"nav-item menu-is-opening menu-open" : "nav-item" }>
                                <a href="#" className="nav-link" onClick={()=>setShow(prev => ({
                                    ...prev,
                                    tree: !show.tree
                                }))}>
                                <i className="nav-icon fas fa-seedling"></i>
                                <p>
                                    Tree Module
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                                </a>
                                <ul className="nav" style={show.tree ? {display: 'block'} : {display: 'none'} }>
                                    <li className="nav-item">
                                        <Link to="/trees/dashboard" className="nav-link">
                                            <i className="nav-icon fas fa-chart-bar"></i>
                                            <p>Dashboard</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/trees" className="nav-link">
                                            <i className="nav-icon fas fa-tree"></i>
                                            <p>Trees</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/operations" className="nav-link">
                                            <i className="nav-icon fas fa-tools"></i>
                                            <p>Operations</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        )}
                        


                        {user.module.includes("fruit") && (
                            <li className={show.fruit ?"nav-item menu-is-opening menu-open" : "nav-item" }>
                                <a href="#" className="nav-link" onClick={()=>setShow(prev => ({
                                    ...prev,
                                    fruit: !show.fruit
                                }))}>
                                <i className="nav-icon fas fa-apple-alt"></i>
                                <p>
                                    Fruit Module
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                                </a>
                                <ul className="nav" style={show.fruit ? {display: 'block'} : {display: 'none'} }>
                                    <li className="nav-item">
                                        <a href="/trackntrace" className="nav-link">
                                            <i className="nav-icon fas fa-chart-pie"></i>
                                            <p>Track {'&'} Trace</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/feedback" className="nav-link">
                                            <i className="nav-icon far fa-comment-dots"></i>
                                            <p>Customer Feedback</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/gallery" className="nav-link">
                                            <i className="nav-icon far fa-image"></i>
                                            <p>Media Gallery</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        )}


                        {user.module.includes("inventory") && (
                            <li className={show.inventory ?"nav-item menu-is-opening menu-open" : "nav-item" }>
                                <a href="#" className="nav-link" onClick={()=>setShow(prev => ({
                                    ...prev,
                                    inventory: !show.inventory
                                }))}>
                                <i className="nav-icon fas fa-warehouse"></i>
                                <p>
                                    Inventory Module
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                                </a>
                                <ul className="nav" style={show.inventory ? {display: 'block'} : {display: 'none'} }>
                                    <li className="nav-item">
                                        <Link to="/inventory/visualization" className="nav-link">
                                            <i className="nav-icon fas fa-chart-bar"></i>
                                            <p>Visualization</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/inventory/purchase" className="nav-link">
                                            <i className="nav-icon fas fa-donate"></i>
                                            <p>Purchase</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/inventory/consumption" className="nav-link">
                                            <i className="nav-icon fas fa-people-carry"></i>
                                            <p>Consumption</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/inventory/adjustment" className="nav-link">
                                            <i className="nav-icon fas fa-project-diagram"></i>
                                            <p>Adjustment</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/inventory/item" className="nav-link">
                                            <i className="nav-icon fas fa-boxes"></i>
                                            <p>Item</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/inventory/category" className="nav-link">
                                            <i className="nav-icon fas fa-th"></i>
                                            <p>Category</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/inventory/location" className="nav-link">
                                            <i className="nav-icon fas fa-map-marker-alt"></i>
                                            <p>Location</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/inventory/supplier" className="nav-link">
                                            <i className="nav-icon fas fa-truck-loading"></i>
                                            <p>Supplier</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/inventory/unit-measure" className="nav-link">
                                            <i className="nav-icon fas fa-pencil-ruler"></i>
                                            <p>Unit Measure</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}


                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                <i className="nav-icon fas fa-cog"></i>
                                <p>Settings</p>
                            </a>
                        </li>

                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={()=>{
                                logout()
                            }}>
                                <i className="nav-icon fas fa-sign-out-alt"></i>
                                <p>Logout</p>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    )
}
