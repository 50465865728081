import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import axiosInstance from '../../utils/api';
import { errorToast } from '../../utils/toast';

export default function Activity() {
    const [loading, setLoading] = useState(false)
    let { treeId, treeFarm } = useParams();
    const [data, setData] = useState({})

    useEffect(() => {
        if (localStorage.getItem('tree:data') && JSON.parse(localStorage.getItem('tree:data'))) {
            setData(JSON.parse(localStorage.getItem('tree:data')))
        }
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/tree/show/${treeId}`).then(res=>{
            setLoading(false)
            localStorage.setItem('tree:data', JSON.stringify({
                serial: res.data.serial,
                farm:  res.data.farm,
                breed:  res.data.breed,
                ref:  res.data.ref,
            }));
            setData({
                serial: res.data.serial,
                farm:  res.data.farm,
                breed:  res.data.breed,
                ref:  res.data.ref,
            })
        }).catch(res=> {
            setLoading(false)
            console.log(res)
            errorToast('Invalid ID')
        })
    }, [])
    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Activity</h1>
                    </div>
                    <div className="col-sm-6">
                        {/* <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href={`/operations`}>Operations</a></li>
                        <li className="breadcrumb-item"><a href={`/operations/scan-qr`}>Scan QR</a></li>
                        <li className="breadcrumb-item active">Activity</li>
                        </ol>  */}
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">

                <div className="container-fluid">

                    

                    <div className="row">
                        <div className="col-12">
                            <div className="text-center p-3">
                                <h5>{data.breed} @ {data.farm}</h5>
                                <h5>Serial : {data.serial} </h5>
                                <h5>Ref : {data.ref} </h5>
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-success w-100 col-12 col-md-3" href={`/trees/${treeId}/view`}>
                                    Tree Info
                                    <br/>(Pokok Info.)
                                    </a>
                                </div>
                                
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-info w-100 col-12 col-md-3" href={`/operations/tree/${treeId}/inspection`}>
                                    Inspection
                                    <br/>(Pemerikasaan)
                                    </a>
                                </div>
                            
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-primary w-100 col-12 col-md-3" href={`/operations/${treeFarm}/${treeId}/application`}>
                                    Application
                                    <br/>(Mengaplikasi)
                                    </a>
                                </div>
                                
                                
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-warning w-100 col-12 col-md-3" href={`/operations`}>
                                    Back
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                
                
                
                </div>
            </section>


            
        </div>
    )
}
