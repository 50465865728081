import React from 'react'

export const UnitMeasureForm = ({handleInput, inputs, index}) => {
    return (
        <form className="form-horizontal" style={{border: '1px dotted darkgray'}}>
            <div className="card-body">
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Unit<span style={{ color: 'red' }}>*</span></label>
                    <div className="col-sm-10">
                        <input value={inputs.unit} onChange={e => handleInput(e, 'unit', index)} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Description</label>
                    <div className="col-sm-10">
                        <input value={inputs.description} onChange={e => handleInput(e, 'description', index)} type="text" className="form-control" required />
                    </div>
                </div>
            </div>
        </form>
    )
}
