import { createStore, applyMiddleware } from 'redux';
import rootReducer from './rootReducer';
import thunkMiddleware from 'redux-thunk'

const composedEnhancer = applyMiddleware(thunkMiddleware)

const store = createStore(rootReducer, composedEnhancer);

// const store = createStore(rootReducer);

export default store;