import React from 'react'

export const ConsumptionForm = ({handleInput, inputs, index, itemOptions}) => {
    return (
        <form className="form-horizontal" style={{border: '1px dotted darkgray'}}>
            <div className="card-body">
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Item<span style={{ color: 'red' }}>*</span></label>
                    <div className="col-sm-10">
                        <select className="form-control" value={inputs.itemid} onChange={e => handleInput(e, 'itemid')}>
                            {itemOptions.map(c => <option value={c.itemid}>{c.name} @ {c.location ? c.location : '-'} (Ref:{c.ref ? c.ref : '-'})</option>)}
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Quantity</label>
                    <div className="col-sm-10">
                        <input value={inputs.quantity} onChange={e => handleInput(e, 'quantity', index)} type="number" className="form-control" required />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Ref</label>
                    <div className="col-sm-10">
                        <input value={inputs.ref} onChange={e => handleInput(e, 'ref', index)} type="text" className="form-control" required />
                    </div>
                </div>
            </div>
        </form>
    )
}
