import React, {useEffect,useState} from 'react'

export default function Operations() {
    const [loading, setLoading] = useState(false)

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Operations</h1>
                    </div>
                    <div className="col-sm-6">
                        {/* <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Operations</li>
                        </ol> */}
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center p-3">
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-primary w-100 col-12 col-md-3" href={`/operations/scan-qr`}>Scan QR</a>
                                </div>
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-info w-100 col-12 col-md-3" href={`/operations/select-trees`}>Select Specific Tree No.</a>
                                </div>
                                <div className="row my-2 px-4 justify-content-center">
                                    <a className="btn btn-success w-100 col-12 col-md-3" href={`/operations/select-farms`}>Application by Task/Zone Select</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
