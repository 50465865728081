import React, {useMemo, useState} from 'react'
import DataTable from 'react-data-table-component';

export default function AvailableTagTable({tags}) {
    
    const columns = [
        {
            name: 'Batch',
            selector: row => row.batch,
            sortable: true,
        },
        {
            name: 'Serial',
            selector: row => row.serial,
            sortable: true,
        },
    ];

    const [filterText, setFilterText] = useState('');
    const filteredItems = tags.filter(
		item => item.serial && item.serial.toLowerCase().includes(filterText.toLowerCase())
	);
    const subHeaderComponentMemo = useMemo(() => {
		return (
            <>
            <input value={filterText} onChange={e=>{setFilterText(e.target.value)}} placeholder="Search Serial"/>
            </>
		);
	}, [filterText]);

    return (
        <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationComponentOptions={{
                noRowsPerPage:true
            }}
            subHeader
			subHeaderComponent={subHeaderComponentMemo}
        />
    )
}
