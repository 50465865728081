import React, {useEffect,useState} from 'react'
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../../utils/api';
import { errorToast, successToast } from '../../utils/toast';
import { ItemTable } from './components/ItemTable';
import { LocationTable } from './components/LocationTable';
import { CategoryTable } from './components/CategoryTable';
import { SupplierTable } from './components/SupplierTable';
import { UnitMeasureTable } from './components/UnitMeasureTable';
import { PurchaseTable } from './components/PurchaseTable';
import { ConsumptionTable } from './components/ConsumptionTable';
import { AdjustmentTable } from './components/AdjustmentTable';
import { capitalize } from '../../utils/helpers';

export const Inventory = () => {
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    let { inventory } = useParams();
    let table

    useEffect(() => {
        getData()
    }, [inventory])

    function getData() {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/inventory/${inventory}/list/`).then(res=>{
            setLoading(false)
            console.log(res.data)
            setTableData(res.data)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }
    
    if (inventory=='item') {
        table = <ItemTable tableData={tableData} inventory={inventory} handleDelete={handleDelete}/>
    } else if (inventory=='location') {
        table = <LocationTable tableData={tableData} inventory={inventory} handleDelete={handleDelete}/>
    } else if (inventory=='category') {
        table = <CategoryTable tableData={tableData} inventory={inventory} handleDelete={handleDelete}/>
    } else if (inventory=='supplier') {
        table = <SupplierTable tableData={tableData} inventory={inventory} handleDelete={handleDelete}/>
    } else if (inventory=='unit-measure') {
        table = <UnitMeasureTable tableData={tableData} inventory={inventory} handleDelete={handleDelete}/>
    } else if (inventory=='purchase') {
        table = <PurchaseTable tableData={tableData} inventory={inventory} handleDelete={handleDelete}/>
    } else if (inventory=='consumption') {
        table = <ConsumptionTable tableData={tableData} inventory={inventory} handleDelete={handleDelete}/>
    } else if (inventory=='adjustment') {
        table = <AdjustmentTable tableData={tableData} inventory={inventory} handleDelete={handleDelete}/>
    }

    function handleDelete(id) {
        setLoading(true)
        axiosInstance.delete(`${process.env.REACT_APP_API}/inventory/${inventory}/delete/${id}`).then( (res) => {
            setLoading(false)
            successToast('Succesfully Deleted Event')
            getData()
        })
        .catch(function (err) {
            setLoading(false)
            console.log(err)
        });
    }

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Inventory {capitalize(inventory)}</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active">Inventory {capitalize(inventory)}</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"></h3>

                                    <div className="card-tools">
                                        <Link type="button" className="btn btn-block btn-default"  to={"/inventory/"+ inventory +"/create"}>Create {capitalize(inventory)}</Link>
                                    </div>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    {table}
                                </div>

                            </div>
                        </div>
                    </div>
                
                </div>
            </section>
        </div>
    )
}
