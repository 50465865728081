import React from 'react'

export const LocationForm = ({handleInput, inputs, index}) => {
    return (
        <form className="form-horizontal" style={{border: '1px dotted darkgray'}}>
            <div className="card-body">
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Name<span style={{ color: 'red' }}>*</span></label>
                    <div className="col-sm-10">
                        <input value={inputs.name} onChange={e => handleInput(e, 'name', index)} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Address</label>
                    <div className="col-sm-10">
                        <input value={inputs.address} onChange={e => handleInput(e, 'address', index)} type="text" className="form-control" required />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Latitude</label>
                    <div className="col-sm-10">
                        <input value={inputs.latitude} onChange={e => handleInput(e, 'latitude', index)} type="text" className="form-control" required />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Longitude</label>
                    <div className="col-sm-10">
                        <input value={inputs.longitude} onChange={e => handleInput(e, 'longitude', index)} type="text" className="form-control" />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Contact Person</label>
                    <div className="col-sm-10">
                        <input value={inputs.contact_person} onChange={e => handleInput(e, 'contact_person', index)} type="text" className="form-control" />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Contact No</label>
                    <div className="col-sm-10">
                        <input value={inputs.contact_no} onChange={e => handleInput(e, 'contact_no', index)} type="number" className="form-control" />
                    </div>
                </div>
            </div>
        </form>
    )
}
