import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {config} from '../../utils/config';
import { errorToast, successToast } from '../../utils/toast';
import RecentTagTable from './components/RecentTagTable';
import AvailableTagTable from './components/AvailableTagTable';
import UsedTagTable from './components/UsedTagTable';
import  axiosInstance  from '../../utils/api';

export default function Tag() {
    const [tags, setTags] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        getTagsList('getRecentTagList')
    }, [])

    function handleTabChange(tab) {
        switch (tab) {
            case 'recent':
                getTagsList('getRecentTagList')
            break
            case 'available':
                getTagsList('getAvailableTagList')
                break
            case 'used':
                getTagsList('getUsedTagList')
                break
            default:
                break
        }
    }

    function getTagsList(url){
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/durian/${url}/`).then(res=> {
            setLoading(false)
            console.log(res.data.tag)
            setTags(res.data.tag)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Tag Record</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Tag Record</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card card-primary card-tabs">
                                <div className="card-header p-0 pt-1">
                                    <ul className="nav nav-tabs" id="tabs-tab" role="tablist">
                                        <li className="nav-item" onClick={()=>{handleTabChange('recent')}}>
                                            <a className="nav-link active" id="tabs-one-tab" data-toggle="pill" href="#tabs-one" role="tab" aria-controls="tabs-one" aria-selected="true">Recent</a>
                                        </li>
                                        <li className="nav-item" onClick={()=>{handleTabChange('available')}}>
                                            <a className="nav-link" id="tabs-two-tab" data-toggle="pill" href="#tabs-two" role="tab" aria-controls="tabs-two" aria-selected="false">Available</a>
                                        </li>
                                        <li className="nav-item" onClick={()=>{handleTabChange('used')}}>
                                            <a className="nav-link" id="tabs-three-tab" data-toggle="pill" href="#tabs-three" role="tab" aria-controls="tabs-three" aria-selected="false">Used</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content" id="tabs-tabContent">
                                        {/* ------------------------------ TAB 1 ------------------------------ */}
                                        <div className="tab-pane fade show active" id="tabs-one" role="tabpanel" aria-labelledby="tabs-one-tab">
                                            <RecentTagTable tags={tags}/>
                                        </div>
                                        <div className="tab-pane fade" id="tabs-two" role="tabpanel" aria-labelledby="tabs-two-tab">
                                        {/* ------------------------------ TAB 2 ------------------------------ */}
                                            <AvailableTagTable tags={tags}/>
                                        </div>
                                        <div className="tab-pane fade" id="tabs-three" role="tabpanel" aria-labelledby="tabs-three-tab">
                                        {/* ------------------------------ TAB 3 ------------------------------ */}
                                            <UsedTagTable tags={tags}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                
                
                
                </div>
            </section>


            
        </div>
    )
}
