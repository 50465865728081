import React, {useEffect,useState} from 'react'

import { Link } from 'react-router-dom';
import axiosInstance from '../../../../utils/api';

import { MonthlyCategoryConsumption } from './components/MonthlyCategoryConsumption';
import { MonthlySpending } from './components/MonthlySpending';
import { StockChart } from './components/StockChart';
import { formatDate } from '../../../../utils/helpers';
import { ConsumptionHistory } from '../dashboard/components/ConsumptionHistory';

export const InventoryVisualization = () => {
    const [loading, setLoading] = useState(false)
    const [consumptionData, setConsumptionData] = useState([])
    const [stockData, setStockData] = useState([])
    const [purchaseData, setPurchaseData] = useState([])

    useEffect(() => {
        getData()
    }, [])
    
    const [category, setCategory] = useState('Fertilizer')
    const [historyData, setHistoryData] = useState({
        purchase:[],
        consumption:[]
    })
    const [categoryOptions, setCategoryOptions] = useState([])

    // end_date should be later than start_date
    let d = new Date()
    d.setDate(d.getDate()-1)
    const [startDate, setStartDate] = useState(formatDate(d))
    const [endDate, setEndDate] = useState(formatDate(new Date()))

	function getData() {
		setLoading(true)
		axiosInstance.get(`${process.env.REACT_APP_API}/inventory/visualization/`).then(res=>{
			setLoading(false)
			setConsumptionData(res.data.consumption)
            setStockData(res.data.stock)
            setPurchaseData(res.data.purchase)
		})

		setLoading(true)
		axiosInstance.get(`${process.env.REACT_APP_API}/inventory/visualization/load/category/`).then(res=>{
			setLoading(false)
            setCategoryOptions(res.data)
            if (res.data.length > 0) {
                setCategory(res.data[0].name)
            }
		})
	}
    function getHistoryData(start_date, end_date) {
        console.log(start_date, end_date)
		setLoading(true)
		axiosInstance.get(`${process.env.REACT_APP_API}/inventory/visualization/activity/${category}/${start_date}/${end_date}`).then(res=>{
			setLoading(false)
            setHistoryData(res.data)
		})
    }

    function loadHistory() {
        getHistoryData(startDate.replaceAll('-',''), endDate.replaceAll('-',''))
    }

    

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Inventory Visualization</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active">Inventory Visualization</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="card card-default">
                                <div className="card-header">
                                    <h4 className="card-title">Category Consumption by Month</h4>
                                </div>
                                <div className="card-body">
                                    <MonthlyCategoryConsumption consumptionData={consumptionData}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card card-default">
                                <div className="card-header">
                                    <h4 className="card-title">Stocks</h4>
                                </div>
                                <div className="card-body">
                                    <StockChart stockData={stockData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div className="row">
						<div className="col-12">
							<div className="card card-default">
                                <div className="card-header">
                                    <h4 className="card-title">Monthly Spending (RM)</h4>
                                </div>
                                <div className="card-body">
                                    <MonthlySpending purchaseData={purchaseData} />
                                </div>
                            </div>
						</div>
                    </div>

                    
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-default">
                                <div className="card-header">
                                    <h4 className="card-title">Product {'&'} Consumption History</h4>
                                </div>
                                <div className="card-body">
                                    <ConsumptionHistory
                                        historyData={historyData}
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                        startDate={startDate}
                                        endDate={endDate}
                                        category={category}
                                        categoryOptions={categoryOptions}
                                        setCategory={setCategory}
                                        loadHistory={loadHistory}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
