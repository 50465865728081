import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {config} from '../../../utils/config';
import { errorToast, successToast } from '../../../utils/toast';
import  axiosInstance  from '../../../utils/api'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { formatDateTimeDisplay } from '../../../utils/helpers';

export default function FeedbackRecord() {
    const [feedbackList, setFeedbackList] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    
    const columns = [
        {
            name: 'Batch',
            selector: row => row.batch,
            cell: (row, index, column, id) => {
                return <Link to={`/feedback-records/${row.id}/view`}>{row.batch ? row.batch : '-'}</Link>
            },
            sortable: true,
        },
        {
            name: 'Serial',
            selector: row => row.serial,
            sortable: true,
        },
        {
            name: 'Breed',
            selector: row => row.breed,
            sortable: true,
        },
        {
            name: 'Grade',
            selector: row => row.grade,
            sortable: true,
        },
        {
            name: 'Overall',
            selector: row => row.overall,
            sortable: true,
        },
        {
            name: 'Recommendation',
            selector: row => row.recommendation,
            sortable: true,
        },
        {
            name: 'Last Update',
            selector: row => row.last_update,
            format: row => formatDateTimeDisplay(row.last_update),
        },
    ];


    useEffect(() => {
        getList()
    }, [])

    function getList() {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/feedback/getFeedbackList//`).then(res=>{
            setLoading(false)
            setFeedbackList(res.data.feedback)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }

    function deleteFarm(val) {
        setLoading(true)
        axiosInstance.delete(`${process.env.REACT_APP_API}/core/deleteFarmWithFolder/`, {
            headers: config.headers,
            data: {
                "farm": {
                    "farmid": val.farmid, 
                }
            }
        }).then(res=>{
            setLoading(false)
            successToast(res.data.message)
            getList()
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
            return window.location.reload()
        })
    }

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Feedback Records</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Feedback Records</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body table-responsive p-0">
                                    <DataTable
                                        columns={columns}
                                        data={feedbackList}
                                        pagination
                                        paginationComponentOptions={{
                                            noRowsPerPage:true
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                
                
                
                
                </div>
                </section>


            
        </div>
    )
}