
export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export function formatDateTime(d) {
    let date = new Date(d)
    let year = date.getFullYear(),
        month = date.getMonth() + 1, // months are zero indexed
        day = date.getDate(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        hourFormatted = hour,
        minuteFormatted = minute < 10 ? "0" + minute : minute;
        // morning = hour < 12 ? "am" : "pm"

        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        if (hourFormatted < 10) {
            hourFormatted = "0" + hourFormatted;
        }
        if (second < 10) {
            second = "0" + second;
        }
    return year + "-" + month + "-" + day + "T" + hourFormatted + ":" + minuteFormatted + ":" + second;
}

export function formatDateTimeDisplay(d) {
    let date = new Date(d)
    let year = date.getFullYear(),
        month = date.getMonth() + 1, // months are zero indexed
        day = date.getDate(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        hourFormatted = hour,
        minuteFormatted = minute < 10 ? "0" + minute : minute,
        morning = hour < 12 ? "am" : "pm";

        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }
        if (hourFormatted < 10) {
            hourFormatted = "0" + hourFormatted;
        }
        if (second < 10) {
            second = "0" + second;
        }
    return year + "-" + month + "-" + day + " " + hourFormatted + ":" + minuteFormatted
}

export function capitalize(string) {
    if (string && string.length > 0) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } 
    return string
}