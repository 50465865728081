import React, {useEffect,useState} from 'react'
import { errorToast, successToast } from '../../utils/toast'
import axiosInstance from '../../utils/api'
import { useParams } from 'react-router-dom'

export default function SelectTrees() {
    const [loading, setLoading] = useState(false)
    const [trees, setTress] = useState([])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/tree`).then(res=> {
            setLoading(false)
            console.log(res.data)
            setTress(res.data)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>

            <section className="content">
                <div className="container-fluid">
                <div className="row">
                        <div className="col-12">
                            
                        <div className="card my-4">
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>Serial</th>
                                                <th>Breed</th>
                                                <th>Age</th>
                                                <th>Height (ft)</th>
                                                <th>Diameter (mm)</th>
                                                <th>Latitude</th>
                                                <th>Longitude</th>
                                                <th>Categories</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {trees.length > 0 ? 
                                            trees.map( tree => (
                                                <tr key={tree.treeid}>
                                                    <td>
                                                        <a href={`/operations/tree/${tree.treeid}/activity`}>{tree.serial}</a>
                                                    </td>
                                                    <td>{tree.breed ? tree.breed : '-'}</td>
                                                    <td>{tree.age}</td>
                                                    <td>{tree.height}</td>
                                                    <td>{tree.diameter}</td>
                                                    <td>{tree.latitude}</td>
                                                    <td>{tree.longitude}</td>
                                                    <td>{tree.farm}</td>
                                                </tr>
                                            ))
                                            : (
                                            <tr>
                                                <td>No Data</td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className=" col-12 col-md-3">
                                    <a className="btn btn-warning w-100" href={`/operations`}>
                                    Back
                                    </a>
                                </div>
                            </div>
                                
                        </div>
                    </div>
                
                </div>
            </section>
        </div>
    )
}
