import React, {useEffect,useState} from 'react'
import axiosInstance from '../../utils/api'
import { errorToast, successToast } from '../../utils/toast'
import ApplicationBarGraph from './components/ApplicationBarGraph'
import InspectionBarGraph from './components/InspectionBarGraph'

export const TreeDashboard = () => {
    const [loading, setLoading] = useState(false)
    const [dashboardData, setDashboardData] = useState({
        application: [],
        event:[]
    })
    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        RenderInspectionGraph()
        RenderApplicationGraph()
    }, [dashboardData])

    const getData = () => {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/dashboard/tree/summary/`).then(res=> {
            setLoading(false)
            console.log(res.data)
            setDashboardData(res.data)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })

    }
    function RenderInspectionGraph() {
        return(
            <InspectionBarGraph title="Events" data={dashboardData.event}/>
        )
    }
    function RenderApplicationGraph() {
        
        return(
            <ApplicationBarGraph title="Application"  data={dashboardData.application}/>
        )
    }
    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Tree Dashboard</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Trees</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                            <RenderInspectionGraph />
                                        </div>
                                        <div className="col-12 col-md-6">
                            <RenderApplicationGraph />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                
                </div>
            </section>
            
        </div>
    )
}
