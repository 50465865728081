import React, {useEffect,useState} from 'react'

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export const MonthlyCategoryConsumption = ({consumptionData}) => {
    
    useEffect(() => {

        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        let root = am5.Root.new("chartdiv-consumption");
        
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        
        
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));
        
        
        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        var legend = chart.children.push(
            am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50
            })
        );

        // let data = consumptionData
        // var data = [{
        //     "year": "2021",
        //     "europe": 2.5,
        //     "namerica": 2.5,
        //     "asia": 2.1,
        //     "lamerica": 1,
        //     "meast": 0.8,
        //     "africa": 0.4
        // }, {
        //     "year": "2022",
        //     "europe": 2.6,
        //     "namerica": 2.7,
        //     "asia": 2.2,
        //     "lamerica": 0.5,
        //     "meast": 0.4,
        //     "africa": 0.3
        // }, {
        //     "year": "2023",
        //     "europe": 2.8,
        //     "namerica": 2.9,
        //     "asia": 2.4,
        //     "lamerica": 0.3,
        //     "meast": 0.9,
        //     "africa": 0.5
        // }]

        let arranged_data = {}
        for (let i of consumptionData) {
            arranged_data[i.month] = {
                ...arranged_data[i.month],
                [i.item__category__name] : i.total_consumption
            }
        }

        let data = []
        for (let i in arranged_data) {
            let month = ''
            switch(parseInt(i)) {
                case 1:
                    month = 'Jan'
                    break
                case 2:
                    month = 'Feb'
                    break
                case 3:
                    month = 'Mar'
                    break
                case 4:
                    month = 'Apr'
                    break
                case 5:
                    month = 'May'
                    break
                case 6:
                    month = 'Jun'
                    break
                case 7:
                    month = 'Jul'
                    break
                case 8:
                    month = 'Aug'
                    break
                case 9:
                    month = 'Sep'
                    break
                case 10:
                    month = 'Oct'
                    break
                case 11:
                    month = 'Nov'
                    break
                case 12:
                    month = 'Dec'
                    break
            }

            
            let arranged_data_2 = {
                month: month
            }
            for (let x in arranged_data[i]) {
                arranged_data_2 = {
                    ...arranged_data_2,
                    [x]: arranged_data[i][x]
                }
            }

            data.push(arranged_data_2)
        }

        console.log(data)
        
        
        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "month",
            renderer: am5xy.AxisRendererX.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));
        
        xAxis.data.setAll(data);
        
        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
        }));
        
        
        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function makeSeries(name, fieldName) {
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                categoryXField: "month"
            }));
        
            series.columns.template.setAll({
                tooltipText: "{categoryX} : {valueY} ({name})",
                width: am5.percent(90),
                tooltipY: 0
            });
        
            series.data.setAll(data);
        
            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();
        
            series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationY: 0,
                sprite: am5.Label.new(root, {
                text: "{valueY}",
                fill: root.interfaceColors.get("alternativeText"),
                centerY: 0,
                centerX: am5.p50,
                populateText: true
                })
            });
            });
        
            legend.data.push(series);
        }

        makeSeries("Herbicide", "Herbicide");
        makeSeries("Fungicide", "Fungicide");
        makeSeries("Seed", "Seed");
        makeSeries("Fertilizer", "Fertilizer");
        
        
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);
    
        return () => {
            root.dispose()
        }
}, [consumptionData])

    return (
        <div id="chartdiv-consumption" style={{ width: "100%", minHeight: "400px" }}></div>
    )
}