import React, {useEffect,useState} from 'react'

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export const MonthlyConsumption = ({consumptionData, purchaseData}) => {

    
    useEffect(() => {
      

        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        let root = am5.Root.new("chartdiv-consumption");
        
        
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
          am5themes_Animated.new(root)
        ]);
        
        
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: root.verticalLayout,
        }));
        
        
        // Add scrollbar
        // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
        chart.set("scrollbarX", am5.Scrollbar.new(root, {
            orientation: "horizontal"
        }))
        
        // // Add legend
        // // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        // var legend = chart.children.push(am5.Legend.new(root, {
        //     centerX: am5.p50,
        //     x: am5.p50
        // }))
        
        
        // // Data
        // var data = [{
        //   year: "2017",
        //   income: 23.5,
        //   expenses: 18.1
        // }, {
        //   year: "2018",
        //   income: 26.2,
        //   expenses: 22.8
        // }];
        let data = []
        for (let i of consumptionData) {
            data.push({
                'category' : i.item__category__name,
                'consumption' : i.total_quantity
            })
        }
        
        let consumptionDataCategory = []
        consumptionData.map(p => {
            consumptionDataCategory.push(p.item__category__name)
        })
        for (let i of purchaseData) {
            if (consumptionDataCategory.includes(i.item__category__name)) {
                for (let d of data ) {
                    if (d.category == i.item__category__name) {
                        d['purchase'] = i.total_purchase
                    }
                }
            } else {
                data.push({
                    'category' : i.item__category__name,
                    'purchase' : i.total_quantity,
                })
            }
        }
        
        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "category",
            renderer: am5xy.AxisRendererY.new(root, {
                inversed: true,
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
            }),
        }))

        // Y-Axis font size
        let yRenderer = yAxis.get("renderer");
        yRenderer.labels.template.setAll({
            fontSize: 12
        });

        yAxis.data.setAll(data);
        
        var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
          min: 0,
        }))
        // X-Axis font size
        let xRenderer = xAxis.get("renderer");
        xRenderer.labels.template.setAll({
            fontSize: 12
        });
        
        
        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function createSeries(field, name) {
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: field,
                categoryYField: "category",
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "[bold]{name}[/]\n{categoryY}: {valueX}",
                })
            }));
            
            series.columns.template.setAll({
                height: am5.p100
            });
        
        
            series.bullets.push(function() {
                return am5.Bullet.new(root, {
                locationX: 1,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    centerY: am5.p50,
                    text: "{valueX}",
                    populateText: true,
                    fontSize:12
                })
                });
            });
        
            series.bullets.push(function() {
                return am5.Bullet.new(root, {
                    locationX: 1,
                    locationY: 0.5,
                    sprite: am5.Label.new(root, {
                        centerX: am5.p100,
                        centerY: am5.p50,
                        text: "{name}",
                        fill: am5.color(0xffffff),
                        populateText: true,
                        fontSize: 10,
                    })
                });
            });
        
          series.data.setAll(data);
          series.appear();
        
          return series;
        }
        
        createSeries("purchase", "Purchase");
        createSeries("consumption", "Consumption");
        
        
        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        var legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
        }));
        
        legend.data.setAll(chart.series.values);
        legend.labels.template.setAll({
            fontSize: 12,
        })
        legend.markers.template.setAll({
            width: 12,
            height: 12
        })
        
        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomY",
        }));
        cursor.lineY.set("forceHidden", true);
        cursor.lineX.set("forceHidden", true);
        
        
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);
        
        return () => {
            root.dispose()
        }
        }, [consumptionData, purchaseData])
    return (
        <div id="chartdiv-consumption" style={{ width: "100%", minHeight: "400px" }}></div>
    )
}
