import React, {useEffect, useState} from 'react'
import { config } from '../utils/config'
import axios from 'axios'
import { errorToast } from '../utils/toast'
import  axiosInstance  from '../utils/api'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authActions } from '../redux'
import { DashboardCharts } from './inventory/pages/dashboard/DashboardCharts'

export default function Dashboard() {
    const [loading, setLoading] = useState(false)
    const [dashboardData, setDashboardData] = useState({
        tag:{
            total:0,
            used:0,
            available:0
        },
        feedback: {
            total:0,
            rating:0,
        }
    })
    const dispatch = useDispatch()
    const { logout } = bindActionCreators(authActions, dispatch)
    const [user, setUser] = useState({
        username: '',
        company: '',
        module: []
    })

    useEffect(() => {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/summary/getTrackNTraceDashboard/`).then(res=>{
            setLoading(false)
            console.log(res)
            setDashboardData(res.data)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })

        let userData = localStorage.getItem('user:data')
        if (JSON.parse(userData)) {
            // log user out if module does not exist in storage.. to sync new and old version.
            if (typeof JSON.parse(userData).module == 'undefined') return logout()
            setUser({
                username: JSON.parse(userData).username,
                company: JSON.parse(userData).company,
                module: JSON.parse(userData).module
            })
        }
    }, [])

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Dashboard</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">



                    {user.module.includes("inventory") && (
                        <div className="col-md-4">
                            <div className="card card-outline card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Inventory</h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i></button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <DashboardCharts />

                                    <div className="small-box bg-info">
                                        <Link to="/inventory/more" className="small-box-footer">
                                            More info <i className="fas fa-arrow-circle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}

                    {user.module.includes("fruit") && (
                        <div className="col-md-4">
                            <div className="card card-outline card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Track {'&'} Trace</h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i></button>
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <h3>{dashboardData.tag.total}</h3>

                                            <p>Number of Tag Purchased</p>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-shopping-cart"></i>
                                        </div>
                                    </div>

                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <h3>{dashboardData.tag.used}</h3>

                                            <p>Number of Tag Used</p>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-user-plus"></i>
                                        </div>
                                    </div>


                                    <div className="small-box bg-warning">
                                        <div className="inner">
                                            <h3>{dashboardData.tag.available}</h3>

                                            <p>Available Tags</p>
                                        </div>
                                        <div className="icon">
                                            <i className="far fa-chart-bar"></i>
                                        </div>
                                    </div>

                                    <div className="small-box bg-info">
                                        <a href="/trackntrace" className="small-box-footer">
                                            More info <i className="fas fa-arrow-circle-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}


                        {user.module.includes("fruit") && (
                        <div className="col-md-4">
                            <div className="card card-outline card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Customer Feedbacks</h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i></button>
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="info-box bg-gray">
                                        <span className="info-box-icon"><i className="far fa-thumbs-up"></i></span>

                                        <div className="info-box-content">
                                            <span className="info-box-text">Likes</span>
                                            <span className="info-box-number">{dashboardData.feedback.rating}</span>

                                        </div>
                                    </div>

                                    <div className="small-box bg-danger">
                                        <div className="inner">
                                            <h3>{dashboardData.feedback.total}</h3>
                                            <p>Overall Ratings</p>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-chart-pie"></i>
                                        </div>
                                    </div>


                                    <div className="small-box bg-info">
                                        <a href="/feedback" className="small-box-footer">
                                            More info <i className="fas fa-arrow-circle-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}

                        
                      

                    </div>

                </div>
                </section>


            
        </div>
    )
}
