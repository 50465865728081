import React, {useEffect,useState} from 'react'

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export const MonthlySpending = ({purchaseData}) => {

    
    useEffect(() => {
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        let root = am5.Root.new("chartdiv-spending");

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
        am5themes_Animated.new(root)
        ]);


        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panY",
        wheelY: "zoomY",
        layout: root.verticalLayout
        }));

        // Add scrollbar
        // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
        chart.set("scrollbarX", am5.Scrollbar.new(root, {
            orientation: "horizontal"
        }));

        // var data = [{
        //     "year": "2021",
        //     "europe": 2.5,
        //     "namerica": 2.5,
        //     "asia": 2.1,
        //     "lamerica": 1,
        //     "meast": 0.8,
        //     "africa": 0.4
        // }, {
        //     "year": "2022",
        //     "europe": 2.6,
        //     "namerica": 2.7,
        //     "asia": 2.2,
        //     "lamerica": 0.5,
        //     "meast": 0.4,
        //     "africa": 0.3
        // }]
        // {
        //     "month":2,
        //     "Herbicide": 6,
        //     "Pesticide": 2
        // }
        let data = []
        let exist_month = []
        let categoryList = []
        // Seperate data by months
        for (let i of purchaseData) {
            if (!categoryList.includes(i.item__category__name)) {
                categoryList.push(i.item__category__name)
            }
            if (!exist_month.includes(i.month)) {
                data.push({
                    month: i.month
                })
                exist_month.push(i.month)
            }
        }
        // From the months seperated, add in the data next
        for (let i of data) {
            for (let x of purchaseData) {
                if (x.month == i.month) {
                    i[x.item__category__name] = x.total_spending
                }
            }
        }

        // Convert Integer to Month
        for (let i of data) {
            i['month'] = getMonthByInteger(i.month)
        }

        function getMonthByInteger(int) {
            switch(int.toString()) {
                case '1':
                    return 'Jan'
                    break
                case '2':
                    return 'Feb'
                    break
                case '3':
                    return 'Mar'
                    break
                case '4':
                    return 'Apr'
                    break
                case '5':
                    return 'May'
                    break
                case '6':
                    return 'Jun'
                    break
                case '7':
                    return 'Jul'
                    break
                case '8':
                    return 'Aug'
                    break
                case '9':
                    return 'Sep'
                    break
                case '10':
                    return 'Oct'
                    break
                case '11':
                    return 'Nov'
                    break
                case '12':
                    return 'Dec'
                    break
            }
        }

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "month",
        renderer: am5xy.AxisRendererY.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
        }));
        // Y-Axis font size
        yAxis.get("renderer").labels.template.setAll({
            fontSize: 12
        });

        yAxis.data.setAll(data);

        var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            min: 0,
            renderer: am5xy.AxisRendererX.new(root, {})
        }));
        // X-Axis font size
        xAxis.get("renderer").labels.template.setAll({
            fontSize: 12
        });


        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        var legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
        }))
        legend.labels.template.setAll({
            fontSize: 12,
        })
        legend.markers.template.setAll({
            width: 12,
            height: 12
        })


        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function makeSeries(name, fieldName) {
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                stacked: true,
                xAxis: xAxis,
                yAxis: yAxis,
                baseAxis: yAxis,
                valueXField: fieldName,
                categoryYField: "month"
            }));
 
            series.columns.template.setAll({
                tooltipText: "{name}, {categoryY}: {valueX}",
                tooltipY: am5.percent(90)
            });
            series.data.setAll(data);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();
            // series.columns.template.onPrivate("width", function(width, target) {
            //     am5.array.each(target.dataItem.bullets, function(bullet) {
            //         if (width < 10) {
            //                 bullet.set("locationY", 0.1);
            //                 bullet.get("sprite").set("centerY", am5.p100)
            //         }
            //     });
            // });
            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Label.new(root, {
                        text: "{valueX}",
                        fill:  '#000',
                        centerY: am5.p50,
                        centerX: am5.p50,
                        populateText: true,
                        fontSize:12,
                    })
                });
            });

            legend.data.push(series);
        }
        
        for (let i of categoryList) {
            makeSeries(i,i);
        }


        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);

        return () => {
            root.dispose()
        }
    }, [purchaseData])

    return (
        <div id="chartdiv-spending" style={{ width: "100%", minHeight: "400px" }}></div>
    )
}
