
const INITIAL_STATE = {
    isAuthenticated: false,
	isLoading:false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case "START_LOADING":
			return {
				...state, isLoading: true,
			};
		case "STOP_LOADING":
			return {
				...state, isLoading: false,
			};
		case "NOT_AUTH":
			return {
				...state, isAuthenticated: false,
			};
		case "LOGIN_SUCCESS":
			return {
				...state, isAuthenticated: true,
			};
		case "LOGIN_FAIL":
			return {
				...state, error: true,
			};
		case "LOGOUT":
			return {
				...state, isAuthenticated: false,
			};
		default: return state;
    }
};
export default reducer;