import React, {useState, useEffect} from 'react'
import axiosInstance from '../../../../utils/api';
import LowStockMapChart from './components/LowStockMapChart';
import { MonthlyPurchase } from './components/MonthlyPurchase';
import { MonthlyConsumption } from './components/MonthlyConsumption';

export const DashboardCharts = () => {
    const [loading, setLoading] = useState(false)
	const [mapData, setMapData] = useState([])
	const [purchaseData, setPurchaseData] = useState([])
    const [consumptionData, setConsumptionData] = useState([])


    useEffect(() => {
        getData()
    }, [])

	function getData() {
		setLoading(true)
		axiosInstance.get(`${process.env.REACT_APP_API}/dashboard/inventory/summary/`).then(res=>{
			setLoading(false)
			setMapData(res.data.warning)
			setPurchaseData(res.data.purchase)
			setConsumptionData(res.data.consumption)
		})
        
	}


    return (
        <>
        <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
            <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
        </div>
        <div className="row">
            <div className="col-12">
                <div className="card card-default">
                    <div className="card-header">
                        <h4 className="card-title">Low Stock</h4>
                    </div>
                    <div className="card-body">
                        <LowStockMapChart mapData={mapData}/>
                    </div>
                </div>
            </div>
        </div>
    
        <div className="row">
            <div className="col-12">
                <div className="card card-default">
                    <div className="card-header">
                        <h4 className="card-title">Purchase this Month</h4>
                    </div>
                    <div className="card-body">
                        <MonthlyPurchase purchaseData={purchaseData}/>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-12">
                <div className="card card-default">
                    <div className="card-header">
                        <h4 className="card-title">Purchase {'&'} Consumption this Month</h4>
                    </div>
                    <div className="card-body">
                        <MonthlyConsumption purchaseData={purchaseData} consumptionData={consumptionData}/>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}
