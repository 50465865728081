import React, {useMemo, useState} from 'react'
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/helpers';
import DataTable from 'react-data-table-component';

export const ConsumptionTable = ({tableData, inventory, handleDelete}) => {

    const columns = [
        {
            name: 'Item',
            sortable: true,
            selector: row => row.item,
            cell: (row) => {
                return (
                    <Link to={`/inventory/${inventory}/${row.id}/edit`}>{row.item ? row.item : '-'}</Link>
                )
            }

        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            sortable: true,
        },
        {
            name: 'Ref',
            selector: row => row.ref,
            sortable: true,
        },
        {
            name: 'Datetime',
            selector: row => formatDate(row.datetime),
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row)=> {
                return (
                    <button className="btn btn-tool" onClick={()=> {
                        if (window.confirm('Are you sure you want to delete?')) {
                            handleDelete(row.id)
                        }
                    }}>
                        <i className="fas fa-trash"></i>
                    </button>
                )
            }
        },
    ];


	const [filterText, setFilterText] = useState('');
    const filteredItems = tableData.filter(
		item => item.item && item.item.toLowerCase().includes(filterText.toLowerCase())
	);
    const subHeaderComponentMemo = useMemo(() => {

		return (
            <>
            <input value={filterText} onChange={e=>{setFilterText(e.target.value)}} placeholder="Search Item"/>
            </>
		);
	}, [filterText]);

    return (
        <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationComponentOptions={{
                noRowsPerPage:true
            }}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
        />
    )
}
